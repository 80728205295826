import {MerchantScope} from '@local/backend/@types/scopes/MerchantScopes';
import IfHasPermissions from '@local/frontend/hocs/IfHasPermissions';
import React, {lazy} from 'react';

const HomePage = lazy(() => import('./HomePage'));

export const homeRoutes = {
  path: 'home',
  element: (
    <IfHasPermissions
      thenRender={() => <HomePage />}
      permissions={[MerchantScope.READ]}
    />
  ),
};
