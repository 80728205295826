import type {FC} from 'react';
import React from 'react';
import {Stack, TextField} from '@mui/material';
import FormSectionTitle from '@local/frontend/components/atoms/FormSectionTitle';
import CurrencyMultiSelect from '@local/frontend/components/atoms/input/CurrencyMultiSelect';
import {isEmpty} from 'lodash-es';
import type {Control} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import type {Currency} from '@handsin/money';

type FormValues = {
  name: string | undefined;
  supportedCurrencies: Currency[] | undefined;
};

const GenericFormSection: FC<
  React.PropsWithChildren<{control: Control<FormValues>}>
> = ({control}) => (
  <Stack direction="column">
    <FormSectionTitle
      title="General Settings"
      helper="Give your gateway a name to easily identify it. This will not be displayed to your users."
    />
    <Controller
      name="name"
      control={control}
      render={({field, fieldState}) => (
        <TextField
          {...field}
          label="Display name"
          type="text"
          placeholder="Display name"
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
      )}
    />
    <FormSectionTitle
      title="Supported Currencies"
      helper="Set the currencies which this gateway can support. This will restrict it from being used in certain scenarios."
    />
    <Controller
      name="supportedCurrencies"
      control={control}
      render={({field, fieldState}) => (
        <CurrencyMultiSelect
          {...field}
          label={
            isEmpty(field.value)
              ? 'Supports all currencies'
              : 'Supported currencies'
          }
          placeholder={
            isEmpty(field.value)
              ? 'Select currencies this connection will activate for'
              : undefined
          }
          type="text"
          error={!!fieldState.error}
          helperText={
            fieldState.error?.message ??
            '*Leave blank if this connection will support all currencies'
          }
        />
      )}
    />
  </Stack>
);

export default GenericFormSection;
