import type {Money} from '@handsin/money';
import {LocalMoney, MoneyUtils} from '@handsin/money';
import type {ChipProps} from '@mui/material';
import {Stack, Typography, Chip, Tooltip} from '@mui/material';
import type {FC} from 'react';
import React from 'react';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import RestoreIcon from '@mui/icons-material/Restore';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {useNavigate} from 'react-router-dom';

enum DetailedPaymentStatus {
  AUTHORIZED = 'AUTHORIZED',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  REFUNDED = 'REFUNDED',
  PARTIALLY_AUTHORIZED = 'PARTIALLY AUTHORIZED',
  PARTIALLY_REFUNDED = 'PARTIALLY REFUNDED',
  PARTIALLY_CAPTURED = 'PARTIALLY CAPTURED',
  CAPTURED = 'CAPTURED',
  UNKNOWN = 'UNKNOWN',
}

function getDetailedPaymentStatus(
  paymentRecord: BasicPaymentDetails
): DetailedPaymentStatus {
  // first check if money has been captured from the group. we don't care about status being completed here because of partially captured case
  if (paymentRecord.capturedMoney && paymentRecord.capturedMoney.amount > 0) {
    // check if the group has refunded money (refunded & partially refunded status is next in precendence)
    if (paymentRecord.refundedMoney && paymentRecord.refundedMoney.amount > 0) {
      // check if the group is fully refunded
      if (
        LocalMoney.gte(paymentRecord.refundedMoney, paymentRecord.capturedMoney)
      ) {
        return DetailedPaymentStatus.REFUNDED;
      }

      // check if the group is partially refunded
      if (
        LocalMoney.lt(paymentRecord.refundedMoney, paymentRecord.capturedMoney)
      ) {
        return DetailedPaymentStatus.PARTIALLY_REFUNDED;
      }
    }

    //  if not refunded, now check if the group payment has been captured (overwrites completed)
    if (
      LocalMoney.gte(paymentRecord.capturedMoney, paymentRecord.amountMoney)
    ) {
      return DetailedPaymentStatus.CAPTURED;
    }

    if (LocalMoney.lt(paymentRecord.capturedMoney, paymentRecord.amountMoney)) {
      return DetailedPaymentStatus.PARTIALLY_CAPTURED;
    }
  }

  // check if expired
  if (paymentRecord.status === 'EXPIRED') {
    return DetailedPaymentStatus.EXPIRED;
  }

  if (
    !!paymentRecord.expirationDate &&
    ['PENDING', 'APPROVED'].includes(paymentRecord.status) &&
    new Date() >= new Date(paymentRecord.expirationDate)
  ) {
    return DetailedPaymentStatus.EXPIRED;
  }

  // check if a group is pending and has approved money, which is less than the total amount
  if (
    paymentRecord.status === 'PENDING' &&
    paymentRecord.approvedMoney &&
    paymentRecord.approvedMoney.amount > 0 &&
    LocalMoney.lt(paymentRecord.approvedMoney, paymentRecord.amountMoney)
  ) {
    return DetailedPaymentStatus.PARTIALLY_AUTHORIZED;
  }

  // otherwise, fallback to cancelled, pending, approved, and completed status's from gpr.
  if (paymentRecord.status === 'CANCELLED') {
    return DetailedPaymentStatus.CANCELLED;
  }
  if (paymentRecord.status === 'PENDING') {
    return DetailedPaymentStatus.PENDING;
  }
  if (paymentRecord.status === 'APPROVED') {
    return DetailedPaymentStatus.AUTHORIZED;
  }
  if (paymentRecord.status === 'COMPLETED') {
    return DetailedPaymentStatus.CAPTURED;
  }
  if (paymentRecord.status === 'FAILED') {
    return DetailedPaymentStatus.FAILED;
  }

  return DetailedPaymentStatus.UNKNOWN;
}

const getPaymentStatusChipStyles = (
  paymentRecord: BasicPaymentDetails,
  linkSrc?: boolean
): {
  status: DetailedPaymentStatus;
  color: string;
  backgroundColor: string;
  displayText: string;
  icon: JSX.Element;
} => {
  const status = getDetailedPaymentStatus(paymentRecord);
  const linkSrcIcon = linkSrc ? <OpenInNewIcon color="primary" /> : undefined;

  switch (status) {
    case DetailedPaymentStatus.REFUNDED:
      return {
        status,
        color: 'purple',
        backgroundColor: '#e6e6fa',
        displayText: 'Refunded',
        icon: linkSrcIcon ?? <RestoreIcon />,
      };
    case DetailedPaymentStatus.PARTIALLY_REFUNDED:
      return {
        status,
        color: 'purple',
        backgroundColor: '#e6e6fa',
        displayText: `Partially refunded ${
          paymentRecord.refundedMoney
            ? MoneyUtils.formatMoney(paymentRecord.refundedMoney)
            : ''
        }`,
        icon: linkSrcIcon ?? <RestoreIcon />,
      };
    case DetailedPaymentStatus.CAPTURED:
      return {
        status,
        color: 'success.dark',
        backgroundColor: 'success.light',
        displayText: 'Captured',
        icon: linkSrcIcon ?? <DoneIcon />,
      };
    case DetailedPaymentStatus.PARTIALLY_CAPTURED:
      return {
        status,
        color: 'success.dark',
        backgroundColor: 'success.light',
        displayText: `Partially captured ${
          paymentRecord.capturedMoney
            ? MoneyUtils.formatMoney(paymentRecord.capturedMoney)
            : ''
        }`,
        icon: linkSrcIcon ?? <DoneIcon />,
      };
    case DetailedPaymentStatus.AUTHORIZED:
      return {
        status,
        color: 'info.dark',
        backgroundColor: 'info.light',
        displayText: 'Authorized',
        icon: linkSrcIcon ?? <CreditScoreIcon />,
      };
    case DetailedPaymentStatus.PARTIALLY_AUTHORIZED:
      return {
        status,
        color: 'info.dark',
        backgroundColor: 'info.light',
        displayText: `Partially Authorized ${
          paymentRecord.approvedMoney
            ? MoneyUtils.formatMoney(paymentRecord.approvedMoney)
            : ''
        }`,
        icon: linkSrcIcon ?? <CreditScoreIcon />,
      };
    case DetailedPaymentStatus.PENDING:
      return {
        status,
        color: 'warning.dark',
        backgroundColor: 'warning.light',
        displayText: 'Pending',
        icon: linkSrcIcon ?? <PendingOutlinedIcon />,
      };
    case DetailedPaymentStatus.CANCELLED:
    case DetailedPaymentStatus.FAILED:
    case DetailedPaymentStatus.EXPIRED:
      return {
        status,
        color: 'error.dark',
        backgroundColor: 'error.light',
        displayText: `${status.at(0)?.toUpperCase()}${status.substring(1).toLowerCase()}`,
        icon:
          status === DetailedPaymentStatus.CANCELLED
            ? linkSrcIcon ?? <CloseIcon />
            : linkSrcIcon ?? <AccessAlarmIcon />,
      };
    default:
      return {
        status,
        color: 'default.dark',
        backgroundColor: 'default.light',
        displayText: 'Unknown',
        icon: linkSrcIcon ?? <QuestionMarkIcon />,
      };
  }
};

export type BasicPaymentStatus =
  | 'APPROVED'
  | 'PENDING'
  | 'COMPLETED'
  | 'CANCELLED'
  | 'EXPIRED'
  | 'FAILED';

export interface BasicPaymentDetails {
  id: string;
  status: BasicPaymentStatus;
  amountMoney: Money;
  capturedMoney?: Money;
  refundedMoney?: Money;
  approvedMoney?: Money;
  expirationDate?: Date | string;
}

interface PaymentStatusChipProps extends ChipProps {
  paymentRecord: BasicPaymentDetails;
  linkSrc?: boolean;
}

const PaymentStatusChip: FC<
  React.PropsWithChildren<PaymentStatusChipProps>
> = ({paymentRecord, linkSrc, sx, ...props}) => {
  const navigate = useNavigate();
  const chipStyle = getPaymentStatusChipStyles(paymentRecord, linkSrc);

  return (
    <Tooltip
      arrow
      placement="top"
      title={
        <Stack>
          <Typography variant="caption">
            Total {MoneyUtils.formatMoney(paymentRecord.amountMoney)}
          </Typography>
          {paymentRecord.approvedMoney && (
            <Typography variant="body2">
              {['CANCELLED', 'EXPIRED'].includes(chipStyle.status)
                ? '🔴 Voided'
                : '🔵 Authorized'}{' '}
              {MoneyUtils.formatMoney(paymentRecord.approvedMoney)}
            </Typography>
          )}
          {paymentRecord.capturedMoney && (
            <Typography variant="body2">
              🟢 Captured {MoneyUtils.formatMoney(paymentRecord.capturedMoney)}
            </Typography>
          )}
          {paymentRecord.refundedMoney && (
            <Typography variant="body2">
              🟣 Refunded {MoneyUtils.formatMoney(paymentRecord.refundedMoney)}
            </Typography>
          )}
        </Stack>
      }
    >
      <Chip
        size="small"
        sx={{
          borderRadius: 1,
          color: props.color,
          backgroundColor: chipStyle.backgroundColor,
          '& .MuiChip-deleteIcon': {
            '&:hover': linkSrc
              ? {
                  color: 'primary.dark',
                }
              : {},
          },
          ...sx,
        }}
        label={chipStyle.displayText}
        deleteIcon={chipStyle.icon}
        onDelete={
          linkSrc
            ? () => navigate(`/dashboard/payments/${paymentRecord.id}`)
            : () => {}
        }
        {...props}
      />
    </Tooltip>
  );
};

export default PaymentStatusChip;
