import type {NavigateFunction} from 'react-router-dom';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import type {CreateGroupPaymentRequest} from '@local/backend/@types/updated-api-types/group-payments/CreateGroupPaymentRequest';
import {v4 as uuidv4} from 'uuid';
import type {TrpcError} from '@local/frontend/libs/trpc/trpc';
import {LocalMoneyCalculator} from '@handsin/money';
import {PaymentSource} from '@local/frontend/@types/payment-source';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import type {UseTRPCMutationResult} from '@trpc/react-query/shared';
import type {CreateGroupPaymentFormValues} from '../../forms/schemas';

const handleCreateGroupPayment = async (
  createGroupPaymentMutation: UseTRPCMutationResult<
    GroupPaymentRecord,
    TrpcError,
    CreateGroupPaymentRequest,
    unknown
  >,
  createGroupParams: CreateGroupPaymentRequest
): Promise<GroupPaymentRecord> =>
  createGroupPaymentMutation.mutateAsync(createGroupParams);

export const handleGroupPaymentCreationSubmission = async (
  createGroupParams: CreateGroupPaymentFormValues,
  createGroupPaymentMutation: UseTRPCMutationResult<
    GroupPaymentRecord,
    TrpcError,
    CreateGroupPaymentRequest,
    unknown
  >,
  closeModal: (modalName: ModalName) => void,
  navigate: NavigateFunction
): Promise<void> => {
  if (!createGroupParams.splitType) {
    throw new Error(
      'Something went wrong. No split type was defined. Please contact support if the problem persists'
    );
  }

  // check group size is defined when about to create a group payment
  if (!createGroupParams.expectedGroupSize) {
    throw new Error(
      'Something went wrong. No group size was set. Please contact support if the problem persists'
    );
  }

  // get owner of group payment
  const {owner} = createGroupParams;

  const groupPayment = await handleCreateGroupPayment(
    createGroupPaymentMutation,
    {
      idempotencyKey: uuidv4(),
      customer: owner,
      amountMoney: createGroupParams.lineItems
        .reduce(
          (acc, lineItem) => acc.add(lineItem.totalMoney),
          new LocalMoneyCalculator({
            amount: 0,
            currency: createGroupParams.currency,
          })
        )
        .calculate(),
      splitType: createGroupParams.splitType,
      lineItemParams: createGroupParams.lineItems.map(lineItem => ({
        item: lineItem.item.id,
        quantity: lineItem.quantity,
      })),
      invites: createGroupParams.invitedMembers, // @TODO: update if ever support BY_ITEM via dashboard creation
      splitAllocation: createGroupParams.expectedGroupSize,
      expirationDate: createGroupParams.expirationDate?.toISOString(),
      source: PaymentSource.PAYMENT_LINK,
    }
  );

  // close review modal
  closeModal(ModalName.REVIEW);
  // // lastly, navigate the user to the confirmation page
  navigate(`./group-payments/${groupPayment.id}/confirm`);
};
