import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import type {DialogTitleProps} from '@mui/material';
import {DialogTitle} from '@mui/material';
import type {FC} from 'react';
import React from 'react';

export interface CustomDialogTitleProps extends DialogTitleProps {
  onClose?: () => void;
}

const CustomDialogTitle: FC<
  React.PropsWithChildren<CustomDialogTitleProps>
> = ({children, onClose, ...props}) => (
  <DialogTitle {...props}>
    {children}
    {onClose ? (
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          height: 36,
          width: 36,
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    ) : null}
  </DialogTitle>
);

export default CustomDialogTitle;
