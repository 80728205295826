import {ListItem, ListItemIcon, Typography} from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ListItemText from '@mui/material/ListItemText';
import type {FC} from 'react';
import React from 'react';
import type {PaymentMethod} from '@stripe/stripe-js/types/api/payment-methods';

const formatExpDate = (exp_month: number, exp_year: number): string =>
  `${`${exp_month.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  })}/${exp_year}`}`;

const CardDetails: FC<React.PropsWithChildren<PaymentMethod.Card>> = ({
  last4,
  brand,
  exp_year,
  exp_month,
}) => (
  <ListItem disablePadding>
    <ListItemIcon>
      <CreditCardIcon />
    </ListItemIcon>
    <ListItemText
      primary={`**** **** **** ${last4}`}
      secondary={brand.toUpperCase()}
    />
    <ListItemText
      sx={{textAlign: 'right'}}
      primary={<Typography fontSize={14}>EXP</Typography>}
      secondary={formatExpDate(exp_month, exp_year)}
    />
  </ListItem>
);

export default CardDetails;
