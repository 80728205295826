import {Drawer} from '@mui/material';
import React, {FC} from 'react';
import {BANNER_DESKTOP, BANNER_MOBILE} from '../dashboard-config';
import HeaderContent from './HeaderContent';

export const Header: FC = () => (
  <Drawer
    open
    anchor="top"
    variant="permanent"
    PaperProps={{
      sx: {
        border: 'none',
        position: 'fixed',
        height: {xs: BANNER_MOBILE, lg: BANNER_DESKTOP},
      },
    }}
    sx={{zIndex: theme => theme.zIndex.drawer + 1, border: 'none'}}
  >
    <HeaderContent />
  </Drawer>
);
