import {Stack, Typography} from '@mui/material';
import type {FC} from 'react';
import React from 'react';
import type {MerchantDTO} from '@local/backend/@types/updated-api-types/merchants/Merchant';
import {MerchantAvatar} from './MerchantAvatar';

interface MerchantOptionProps {
  merchant: MerchantDTO;
}

const MerchantOption: FC<React.PropsWithChildren<MerchantOptionProps>> = ({
  merchant,
}) => (
  <Stack direction="row" alignItems="center" spacing={1} p={1} width="100%">
    <MerchantAvatar
      merchant={merchant}
      variant="rounded"
      sx={{width: 25, height: 25, borderRadius: '3px', fontSize: 12}}
    />
    <Typography fontSize={14} noWrap width="100%">
      {merchant?.name}
    </Typography>
  </Stack>
);

export default MerchantOption;
