import type {FC} from 'react';
import React, {useEffect} from 'react';
import {Portal} from '@mui/material';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import type {PublicConnectDTO} from '@local/backend/@types/updated-api-types/gateways/Gateway';
import {useUpdatePaymentGatewayConnection} from '@local/frontend/libs/trpc/trpc';
import LoadingButton from '@local/frontend/components/atoms/buttons/LoadingButton';
import {useNotification} from '@local/frontend/hooks/useNotification';
import {Currency} from '@handsin/money';
import {isEmpty} from 'lodash-es';
import type {GatewayConfigurationProps} from './common/GatewayConfigurationProps';
import GenericFormSection from './common/GenericFormSection';

const updateStripeConnectSchema = Yup.object({
  name: Yup.string().optional(),
  supportedCurrencies: Yup.array(
    Yup.mixed<Currency>().oneOf(Object.values(Currency)).required()
  ).optional(),
});

const StripeUpdateConfiguration: FC<
  React.PropsWithChildren<
    GatewayConfigurationProps & {connectRecord: PublicConnectDTO}
  >
> = ({connectRecord, actionAreaRef, paymentGateway, setConnectRecord}) => {
  const {open: openNotification} = useNotification();
  const updateConnectionMutation = useUpdatePaymentGatewayConnection({
    onSuccess: newConnectRecord => {
      openNotification({
        message: `Successfully updated ${paymentGateway}!`,
        severity: 'success',
      });
      setConnectRecord(newConnectRecord);
    },
    onError: err => {
      openNotification({
        message:
          err.data?.axiosError?.response?.data.detail ??
          `Failed to update ${paymentGateway}!`,
        severity: 'error',
      });
    },
  });

  const {
    formState: {
      isDirty: updateIsDirty,
      isValid: updateIsValid,
      isSubmitting: updateIsSubmitting,
    },
    ...updateConnectionFormMethods
  } = useForm<Yup.InferType<typeof updateStripeConnectSchema>>({
    mode: 'all',
    resolver: yupResolver(updateStripeConnectSchema),
    defaultValues: {
      name: '',
      supportedCurrencies: [],
    },
  });

  useEffect(() => {
    updateConnectionFormMethods.reset({
      name: connectRecord?.name ?? '',
      supportedCurrencies: connectRecord?.supportedCurrencies ?? [],
    });
  }, [connectRecord]);

  const onUpdateConnectionFormSubmit = (
    formData: Yup.InferType<typeof updateStripeConnectSchema>
  ) => {
    updateConnectionMutation.mutate({
      gateway: connectRecord.gatewayName,
      gatewayId: connectRecord.gatewayId,
      updateParams: {
        name: formData.name,
        supportedCurrencies: !isEmpty(formData.supportedCurrencies)
          ? formData.supportedCurrencies
          : undefined,
      },
    });
  };

  return (
    <form>
      <GenericFormSection control={updateConnectionFormMethods.control} />
      <Portal container={() => actionAreaRef}>
        <LoadingButton
          onClick={updateConnectionFormMethods.handleSubmit(
            onUpdateConnectionFormSubmit
          )}
          disabled={!updateIsDirty || !updateIsValid || updateIsSubmitting}
          loading={updateIsSubmitting || updateConnectionMutation.isLoading}
          variant="outlined"
        >
          Update
        </LoadingButton>
      </Portal>
    </form>
  );
};

export default StripeUpdateConfiguration;
