import type {FC} from 'react';
import React from 'react';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {HandsInThemeProvider} from '@hands-in/theme';
import {HelmetProvider} from 'react-helmet-async';
import Routes from './Routes';

const App: FC<React.PropsWithChildren<unknown>> = () => (
  <HelmetProvider>
    <HandsInThemeProvider>
      <Routes />
      <ReactQueryDevtools initialIsOpen={false} />
    </HandsInThemeProvider>
  </HelmetProvider>
);

export default App;
