import type {FC} from 'react';
import React from 'react';
import {Avatar, Link, Tooltip} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/Help';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import StyledMenu from './StyledMenu';

export const HelpPopover: FC<React.PropsWithChildren<unknown>> = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Help and Support" arrow>
        <IconButton onClick={handleClick} disableRipple sx={{p: 0}}>
          <Avatar
            variant="rounded"
            style={{
              backgroundColor: 'transparent',
              border: '0.1px solid lightgray',
            }}
          >
            <HelpIcon sx={{color: 'black !important'}} />
          </Avatar>
        </IconButton>
      </Tooltip>
      <StyledMenu
        id="help-and-feedback-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          component={Link}
          style={{textDecoration: 'none'}}
          color="inherit"
          rel="noopener noreferrer"
          href="mailto:support@handsin.com?subject=I need some help!&body=Please let us know what problem you ran into here. Feel free to add any images or documents to help."
          target="_top"
        >
          <ListItemText>Get In Touch</ListItemText>
          <ContactSupportIcon fontSize="small" />
        </MenuItem>
        <MenuItem
          component={Link}
          style={{textDecoration: 'none'}}
          color="inherit"
          href="https://www.handsin.com/help"
          target="_blank"
        >
          <ListItemText>FAQs</ListItemText>
          <QuestionAnswerIcon fontSize="small" />
        </MenuItem>
      </StyledMenu>
    </>
  );
};
