import type {FC} from 'react';
import React, {useEffect} from 'react';
import {useRouteError} from 'react-router-dom';
import {Alert, Container, Stack, Typography} from '@mui/material';
import {captureException} from '@sentry/react';
import config from '../config';

const ErrorPage: FC<React.PropsWithChildren<unknown>> = () => {
  const error = useRouteError();
  useEffect(() => {
    captureException(error, {level: 'fatal'});
  }, [error]);

  if (error instanceof Error) {
    return (
      <Container maxWidth="lg">
        <Stack>
          <Typography variant="h1">Error!</Typography>
          <Typography variant="caption">{error.message}</Typography>
          {config.ENV === 'development' && (
            <Alert color="error">{error.stack}</Alert>
          )}
        </Stack>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h1">Oops! Something went wrong.</Typography>
    </Container>
  );
};

export default ErrorPage;
