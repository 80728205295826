import type {FC} from 'react';
import React from 'react';
import Loading from '../components/atoms/Loading';
import config from '../config';
import {isUnauthorizedError, useGetUser} from '../libs/trpc/trpc';

const PrivateUserRoute: FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const {isInitialLoading: isUserLoading} = useGetUser(undefined, {
    onError: err => {
      if (isUnauthorizedError(err)) {
        localStorage.setItem('oldRedirectUrl', window.location.href);
        const url = `${window.location.origin}/callback`;
        window.location.href = `${config.AUTH_URL}/login?redirect=${url}`;
      }
    },
  });

  if (isUserLoading) {
    return <Loading />;
  }

  return children;
};

export default PrivateUserRoute;
