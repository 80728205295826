import {
  Stack,
  styled,
  AppBar as MuiAppBar,
  Toolbar,
  Box,
  Avatar,
  Tooltip,
  IconButton,
} from '@mui/material';
import type {FC} from 'react';
import React from 'react';
import {FaBook} from 'react-icons/fa';
import {Link} from 'react-router-dom';
import {BreadCrumbsWidget} from './components/BreadCrumbsWidget';
import {HelpPopover} from './components/HelpPopover';
import {AccountPopover} from './components/AccountPopover';
import {NavbarButton} from './components/NavbarButton';
import {
  BANNER_DESKTOP,
  BANNER_MOBILE,
  APP_BAR_DESKTOP,
  APP_BAR_MOBILE,
  NAV_WIDTH,
} from '../dashboard-config';
import {bgBlur} from '../../utils/cssStyles';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const StyledAppBar = styled(MuiAppBar)(({theme}) => ({
  ...bgBlur({color: theme.palette.background.paper}),
  boxShadow: 'none',
  color: 'black',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({theme}) => ({
  marginTop: BANNER_MOBILE,
  minHeight: APP_BAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    marginTop: BANNER_DESKTOP,
    minHeight: APP_BAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

interface AppBarProps {
  onNavOpen: () => void;
}

const AppBar: FC<React.PropsWithChildren<AppBarProps>> = ({onNavOpen}) => (
  <StyledAppBar>
    <StyledToolbar>
      <Stack direction="row" flex="auto" alignItems="center" spacing={1}>
        <BreadCrumbsWidget sx={{display: {xs: 'none', lg: 'inherit'}}} />
        <NavbarButton onClick={onNavOpen} sx={{display: {lg: 'none'}}} />
        <Box sx={{flexGrow: 1}} />
        <Tooltip title="Open API Docs" arrow>
          <IconButton
            component={Link}
            to="/connect/readme"
            disableRipple
            sx={{p: 0}}
          >
            <Avatar
              variant="rounded"
              style={{
                backgroundColor: 'transparent',
                border: '0.1px solid lightgray',
              }}
            >
              <FaBook color="black" fontSize="1.15rem" />
            </Avatar>
          </IconButton>
        </Tooltip>

        <HelpPopover />

        <AccountPopover />
      </Stack>
    </StyledToolbar>
  </StyledAppBar>
);

export default AppBar;
