import type {Customer} from '@local/backend/@types/updated-api-types/customers/customer';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import type {FC} from 'react';
import React from 'react';
import PersonIcon from '@mui/icons-material/Person';

interface CustomerAvatarProps {
  customer: Customer | undefined;
}

const CustomerAvatar: FC<React.PropsWithChildren<CustomerAvatarProps>> = ({
  customer,
}) => {
  const firstInitial = customer?.firstName.at(0)?.toUpperCase();
  const lastInitial = customer?.lastName?.at(0)?.toUpperCase();

  return (
    <Avatar sx={{width: '48px', height: '48px', backgroundColor: '#E3F0FF'}}>
      {firstInitial ? (
        <Typography
          variant="subtitle2"
          sx={{fontWeight: 600, color: '#6E86D2'}}
          align="center"
        >
          {`${firstInitial}${lastInitial ?? ''}`}
        </Typography>
      ) : (
        <PersonIcon fontSize="large" />
      )}
    </Avatar>
  );
};

export default CustomerAvatar;
