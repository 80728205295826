/* eslint-disable @typescript-eslint/ban-ts-comment */
import type {FC} from 'react';
import React, {useEffect} from 'react';
import {
  Button,
  Card,
  MenuItem,
  Portal,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import type {PublicConnectDTO} from '@local/backend/@types/updated-api-types/gateways/Gateway';
import {Gateway} from '@local/backend/@types/updated-api-types/gateways/Gateway';
import {
  useCreatePaymentGatewayConnections,
  useUpdatePaymentGatewayConnection,
} from '@local/frontend/libs/trpc/trpc';
import LoadingButton from '@local/frontend/components/atoms/buttons/LoadingButton';
import {useNotification} from '@local/frontend/hooks/useNotification';
import FormSectionTitle from '@local/frontend/components/atoms/FormSectionTitle';
import {Env} from '@local/frontend/@types/env';
import {useEnvironment} from '@local/frontend/hooks/useEnvironment';
import {PlutoEnv} from '@local/backend/@types/updated-api-types/gateways/PlutoEnv';
import {Currency} from '@handsin/money';
import type {GatewayConfigurationProps} from './common/GatewayConfigurationProps';
import GenericFormSection from './common/GenericFormSection';

const createPlutoConnectionSchema = Yup.object({
  name: Yup.string().optional(),
  supportedCurrencies: Yup.array(
    Yup.mixed<Currency>().oneOf(Object.values(Currency)).required()
  ).optional(),
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
  env: Yup.mixed<PlutoEnv>()
    .oneOf(Object.values(PlutoEnv))
    .required('Environment is required'),
}).optional();

const updatePlutoConnectSchema = Yup.object({
  name: Yup.string().optional(),
  supportedCurrencies: Yup.array(
    Yup.mixed<Currency>().oneOf(Object.values(Currency)).required()
  ).optional(),
  googlePayMerchantId: Yup.string().optional(),
  '3dsData': Yup.array(
    Yup.object({
      code: Yup.string().required('Domain Service code is required'),
      acquirer: Yup.object({
        acquirerBin: Yup.string().required('Acquirer BIN is required'),
        acquirerMerchantId: Yup.string().required(
          'Acquirer Merchant ID is required'
        ),
      }).required(),
      merchant: Yup.object({
        mcc: Yup.string().required('Merchant Category Code is required'),
        merchantName: Yup.string().required('Merchant name is required'),
      }).required(),
    }).optional()
  ).optional(),
  env: Yup.mixed<PlutoEnv>()
    .oneOf(Object.values(PlutoEnv))
    .required('Environment is required'),
});

const getPlutoEnvOptions = (
  env: Env
): {default: PlutoEnv; options: {name: string; value: PlutoEnv}[]} => {
  switch (env) {
    case Env.PRODUCTION:
      return {
        default: PlutoEnv.PRODUCTION,
        options: [
          {name: 'Production', value: PlutoEnv.PRODUCTION},
          {name: 'Staging', value: PlutoEnv.STAGING},
        ],
      };
    case Env.SANDBOX:
    case Env.STAGING:
    case Env.DEVELOPMENT:
    default:
      return {
        default: PlutoEnv.PRE_PROD,
        options: [{name: 'Pre-Production', value: PlutoEnv.PRE_PROD}],
      };
  }
};

const PlutoUpdateConfiguration: FC<
  React.PropsWithChildren<
    GatewayConfigurationProps & {connectRecord: PublicConnectDTO}
  >
> = ({connectRecord, actionAreaRef, paymentGateway, setConnectRecord}) => {
  const env = useEnvironment();
  const {open: openNotification} = useNotification();
  const updateConnectionMutation = useUpdatePaymentGatewayConnection({
    onSuccess: newConnectRecord => {
      openNotification({
        message: `Successfully updated ${paymentGateway}!`,
        severity: 'success',
      });
      setConnectRecord(newConnectRecord);
    },
    onError: err => {
      openNotification({
        message:
          err.data?.axiosError?.response?.data.detail ??
          `Failed to update ${paymentGateway}!`,
        severity: 'error',
      });
    },
  });

  const plutoData = connectRecord?.data[Gateway.PLUTO];
  const plutoEnvOptions = getPlutoEnvOptions(env);
  const {
    formState: {
      isDirty: updateIsDirty,
      isValid: updateIsValid,
      isSubmitting: updateIsSubmitting,
    },
    ...updateConnectionFormMethods
  } = useForm<Yup.InferType<typeof updatePlutoConnectSchema>>({
    mode: 'all',
    resolver: yupResolver(updatePlutoConnectSchema),
    defaultValues: {
      name: '',
      supportedCurrencies: [],
      googlePayMerchantId: '',
      '3dsData': [],
      env: plutoEnvOptions.default,
    },
  });

  const threeDSFieldArray = useFieldArray({
    name: '3dsData',
    control: updateConnectionFormMethods.control,
    keyName: 'code',
  });

  useEffect(() => {
    updateConnectionFormMethods.reset({
      name: connectRecord?.name ?? '',
      googlePayMerchantId: plutoData?.googlePay?.merchantId ?? '',
      '3dsData': plutoData?.['3dsData'] ?? [],
      env: plutoData?.env ?? plutoEnvOptions.default,
      supportedCurrencies: connectRecord?.supportedCurrencies ?? [],
    });
    threeDSFieldArray.replace(plutoData?.['3dsData'] ?? []);
  }, [connectRecord]);

  const onUpdateConnectionFormSubmit = (
    formData: Yup.InferType<typeof updatePlutoConnectSchema>
  ) => {
    updateConnectionMutation.mutate({
      gateway: connectRecord.gatewayName,
      gatewayId: connectRecord.gatewayId,
      updateParams: {
        name: formData.name,
        supportedCurrencies: formData.supportedCurrencies,
        data: {
          pluto: {
            googlePay: formData.googlePayMerchantId
              ? {
                  merchantId: formData.googlePayMerchantId,
                }
              : undefined,
            '3dsData': formData['3dsData'],
            env: formData.env,
          },
        },
      },
    });
  };

  return (
    <form>
      <Stack direction="column">
        {/* @ts-expect-error */}
        <GenericFormSection control={updateConnectionFormMethods.control} />
        <FormSectionTitle
          title="Google Pay Settings"
          helper="To activate Google Pay, you must provide the merchant ID given to you by Google"
        />
        <Controller
          name="googlePayMerchantId"
          control={updateConnectionFormMethods.control}
          render={({field, fieldState}) => (
            <TextField
              {...field}
              label="Google Pay Merchant ID"
              type="text"
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
        <FormSectionTitle
          title="Environment"
          helper="Select the environment you wish to use"
        />
        <Controller
          name="env"
          control={updateConnectionFormMethods.control}
          render={({field}) => (
            <Select
              {...field}
              variant="filled"
              disabled={plutoEnvOptions.options.length <= 1}
            >
              {plutoEnvOptions.options.map(envOpt => (
                <MenuItem value={envOpt.value} key={envOpt.value}>
                  {envOpt.name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormSectionTitle
          sx={{mt: 1}}
          title="3DS Settings"
          helper="Please provide at least one domain service to activate 3DS"
        />
        {threeDSFieldArray.fields.map((threeDSField, idx) => (
          <Card key={threeDSField.code} variant="outlined" sx={{p: 1}}>
            <Stack direction="column" spacing={1} width="100%">
              <Controller
                name={`3dsData.${idx}.code`}
                control={updateConnectionFormMethods.control}
                render={({field, fieldState}) => (
                  <TextField
                    {...field}
                    required
                    label="Domain Service Code"
                    placeholder="VIS"
                    type="text"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name={`3dsData.${idx}.acquirer.acquirerBin`}
                control={updateConnectionFormMethods.control}
                render={({field, fieldState}) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    label="Acquirer BIN"
                    type="text"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name={`3dsData.${idx}.acquirer.acquirerMerchantId`}
                control={updateConnectionFormMethods.control}
                render={({field, fieldState}) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    label="Acquirer Merchant ID"
                    type="text"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name={`3dsData.${idx}.merchant.mcc`}
                control={updateConnectionFormMethods.control}
                render={({field, fieldState}) => (
                  <TextField
                    {...field}
                    required
                    label="Merchant Category Code (MCC)"
                    type="text"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name={`3dsData.${idx}.merchant.merchantName`}
                control={updateConnectionFormMethods.control}
                render={({field, fieldState}) => (
                  <TextField
                    {...field}
                    required
                    label="Merchant Name"
                    type="text"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Button
                variant="text"
                color="error"
                onClick={() => threeDSFieldArray.remove(idx)}
              >
                Remove
              </Button>
            </Stack>
          </Card>
        ))}
        <Button
          sx={{mt: 1}}
          variant="outlined"
          onClick={() =>
            threeDSFieldArray.append({
              code: '',
              acquirer: {acquirerBin: '', acquirerMerchantId: ''},
              merchant: {mcc: '', merchantName: ''},
            })
          }
        >
          Connect {threeDSFieldArray.fields.length > 0 ? 'Another' : ''} 3DS
          Issuer
        </Button>
      </Stack>
      <Portal container={() => actionAreaRef}>
        <LoadingButton
          onClick={updateConnectionFormMethods.handleSubmit(
            onUpdateConnectionFormSubmit
          )}
          disabled={!updateIsDirty || !updateIsValid || updateIsSubmitting}
          loading={updateIsSubmitting || updateConnectionMutation.isLoading}
          variant="outlined"
        >
          Update
        </LoadingButton>
      </Portal>
    </form>
  );
};

const PlutoCreateConfiguration: FC<
  React.PropsWithChildren<GatewayConfigurationProps>
> = ({actionAreaRef, paymentGateway, setConnectRecord}) => {
  const env = useEnvironment();
  const {open: openNotification} = useNotification();
  const createConnectionMutation = useCreatePaymentGatewayConnections({
    onSuccess: newConnectRecord => {
      openNotification({
        message: `Successfully connected to ${paymentGateway}!`,
        severity: 'success',
      });
      setConnectRecord(newConnectRecord);
    },
    onError: err => {
      openNotification({
        message:
          err.data?.axiosError?.response?.data.detail ??
          `Failed  to connect to ${paymentGateway}!`,
        severity: 'error',
      });
    },
  });

  const plutoEnvOptions = getPlutoEnvOptions(env);

  const {
    formState: {isValid: createIsValid, isSubmitting: createIsSubmitting},
    ...createConnectionFormMethods
  } = useForm<Yup.InferType<typeof createPlutoConnectionSchema>>({
    mode: 'all',
    resolver: yupResolver(createPlutoConnectionSchema),
    defaultValues: {
      name: '',
      username: '',
      password: '',
      supportedCurrencies: [],
      env: plutoEnvOptions.default,
    },
  });

  const onCreateConnectionFormSubmit = (
    formData: Yup.InferType<typeof createPlutoConnectionSchema>
  ) => {
    createConnectionMutation.mutate({
      gateway: Gateway.PLUTO,
      name: formData?.name,
      supportedCurrencies: formData.supportedCurrencies,
      auth: {
        pluto: {
          username: formData.username,
          password: formData.password,
        },
      },
      data: {
        pluto: {
          env: formData.env,
        },
      },
    });
  };

  return (
    <form>
      <Stack direction="column">
        {/* @ts-expect-error */}
        <GenericFormSection control={createConnectionFormMethods.control} />
        <FormSectionTitle
          title="Environment"
          helper="Select the environment you wish to use"
        />
        <Controller
          name="env"
          control={createConnectionFormMethods.control}
          render={({field}) => (
            <Select
              {...field}
              variant="filled"
              disabled={plutoEnvOptions.options.length <= 1}
            >
              {plutoEnvOptions.options.map(envOpt => (
                <MenuItem value={envOpt.value} key={envOpt.value}>
                  {envOpt.name}
                </MenuItem>
              ))}
            </Select>
          )}
        />

        <FormSectionTitle
          title="Authentication"
          helper="Please provide your username and password to authenticate to Pluto"
        />
        <Stack spacing={1}>
          <Controller
            name="username"
            control={createConnectionFormMethods.control}
            render={({field, fieldState}) => (
              <TextField
                {...field}
                required
                label="Username"
                type="text"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="password"
            control={createConnectionFormMethods.control}
            render={({field, fieldState}) => (
              <TextField
                {...field}
                required
                label="Password"
                type="password"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Stack>
      </Stack>
      <Portal container={() => actionAreaRef}>
        <LoadingButton
          onClick={createConnectionFormMethods.handleSubmit(
            onCreateConnectionFormSubmit
          )}
          disabled={!createIsValid || createIsSubmitting}
          loading={createIsSubmitting || createConnectionMutation.isLoading}
          variant="outlined"
        >
          Connect
        </LoadingButton>
      </Portal>
    </form>
  );
};

const PlutoConfiguration: FC<
  React.PropsWithChildren<GatewayConfigurationProps>
> = ({connectRecord, ...props}) => {
  if (!connectRecord) {
    return <PlutoCreateConfiguration {...props} />;
  }

  return <PlutoUpdateConfiguration {...props} connectRecord={connectRecord} />;
};

export default PlutoConfiguration;
