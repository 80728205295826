import * as Yup from 'yup';

export const YupNameValidation = (field: string) =>
  Yup.string()
    .matches(/^(?!.*\s\s).*$|^$/, `${field} cannot contain consecutive spaces`)
    .trim();

export const emailValidation = () =>
  Yup.string()
    .trim()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      'Invalid email. Please check the email contains an @ (e.g @gmail.com, @hotmail.co.uk, @domain.xyz)'
    );

export const emailNotRequiredValidation = () =>
  Yup.string()
    .trim()
    .matches(
      /^(?:$|[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})$/,
      'Invalid email. Please check the email contains an @ (e.g @gmail.com, @hotmail.co.uk, @domain.xyz)'
    )
    .notRequired();

export const phoneNumberValidation = () =>
  Yup.string()
    .trim()
    .notRequired()
    .transform(value => (!value ? undefined : value)) // required to prevent min error
    .min(8, 'Phone number is too short') // 7 digits + 1 symbol
    .max(16, 'Phone number is too long') // 15 digits + 1 symbol
    .matches(/^\+.*$/, 'Must include + at the start.')
    .matches(/^\+\d*$/, 'Phone number can only contain digits. No spaces')
    .matches(
      /^\+[1-9]\d*$/,
      'Phone number cannot start with 0. Please use the area code (e.g +44, +1)'
    )
    .matches(
      /^\+[1-9]\d{6,14}$/,
      'Invalid format for the provided phone number'
    );
