import type {FC} from 'react';
import React from 'react';
import type {BoxProps} from '@mui/material';
import {Box} from '@mui/material';
import {CardBrand} from '@local/backend/@types/updated-api-types/payments/CardBrand';

const cardBrandToIconImage = (cardBrand: CardBrand | null): string => {
  switch (cardBrand) {
    case CardBrand.VISA:
      return '/assets/card-brands/VISA.png';
    case CardBrand.MASTERCARD:
      return '/assets/card-brands/MASTERCARD.png';
    case CardBrand.AMERICAN_EXPRESS:
      return '/assets/card-brands/AMEX.png';
    case CardBrand.DISCOVER:
      return '/assets/card-brands/DISCOVER.png';
    case CardBrand.DISCOVER_DINERS:
      return '/assets/card-brands/DINERS.png';
    case CardBrand.JCB:
      return '/assets/card-brands/JCB.png';
    case CardBrand.AMAZON:
      return '/assets/card-brands/AMAZON.png';
    case CardBrand.CIRRUS:
      return '/assets/card-brands/CIRRUS.png';
    case CardBrand.DIRECT:
      return '/assets/card-brands/DIRECT.png';
    case CardBrand.EBAY:
      return '/assets/card-brands/EBAY.png';
    case CardBrand.EWAY:
      return '/assets/card-brands/EWAY.png';
    case CardBrand.GOOGLE:
      return '/assets/card-brands/GOOGLE.png';
    case CardBrand.MAESTRO:
      return '/assets/card-brands/MAESTRO.png';
    case CardBrand.PAYPAL:
      return '/assets/card-brands/PAYPAL.png';
    case CardBrand.SAGE:
      return '/assets/card-brands/SAGE.png';
    case CardBrand.SHOPIFY:
      return '/assets/card-brands/SHOPIFY.png';
    case CardBrand.SKRILL:
      return '/assets/card-brands/SKRILL.png';
    case CardBrand.SKRILL_MONEY:
      return '/assets/card-brands/SKRILLMONEY.png';
    case CardBrand.VISA_ELECTRON:
      return '/assets/card-brands/VISAELECTRON.png';
    case CardBrand.WESTERN_UNION:
      return '/assets/card-brands/WESTERNUNION.png';
    case CardBrand.WORLDPAY:
      return '/assets/card-brands/WORLDPAY.png';
    default:
      return '/assets/card-brands/OTHER.png';
  }
};

interface CardBrandIconProps extends BoxProps {
  cardBrand: CardBrand | null;
}

const CardBrandIcon: FC<React.PropsWithChildren<CardBrandIconProps>> = ({
  cardBrand,
  ...props
}) => (
  <Box
    component="img"
    src={cardBrandToIconImage(cardBrand)}
    alt={`${cardBrand} Icon`}
    sx={{
      border: 'solid 1px lightgrey',
      borderRadius: '10%',
      objectFit: 'contain',
      ...props.sx,
    }}
    {...props}
  />
);

export default CardBrandIcon;
