import type {FC} from 'react';
import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import type {UpdateCustomerRequest} from '@handsin/api-node';
import {Country, Language} from '@handsin/api-node';
import {useQueryClient} from '@tanstack/react-query';
import {useNotification} from '@local/frontend/hooks/useNotification';
import {DEFAULT_CREATED_CUSTOMER_EMAIL} from '@local/frontend/options';
import {trpc, useUpdateCustomer} from '@local/frontend/libs/trpc/trpc';
import type {Customer} from '@local/backend/@types/updated-api-types/customers/customer';
import {formatFullName} from '@local/frontend/utils/formatters';
import {getQueryKey} from '@trpc/react-query';
import {useCustomModals} from '@local/frontend/libs/modals/useModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import {addNewCustomerValidationSchema} from '../../forms/schemas';
import EditCustomerForm from '../../forms/EditCustomerForm';

export interface EditCustomerModalProps {
  customer: Customer;
}

const EditCustomerModal: FC<
  React.PropsWithChildren<EditCustomerModalProps>
> = ({customer}) => {
  const queryClient = useQueryClient();
  const {closeModal} = useCustomModals();
  const {open: openNotification} = useNotification();

  const formMethods = useForm({
    mode: 'all',
    resolver: yupResolver(addNewCustomerValidationSchema),
    defaultValues: {
      firstName: customer.firstName,
      lastName: customer.lastName ?? '',
      email:
        customer.email === DEFAULT_CREATED_CUSTOMER_EMAIL ? '' : customer.email,
      phoneNumber: customer.phoneNumber ?? '',
      phoneNumberCountry: customer.address?.country ?? Country.GB,
      language: customer.language ?? Language.EN,
    },
  });

  const updateCustomerMutation = useUpdateCustomer();

  const handleEditCustomerSubmit = async (
    formValues: UpdateCustomerRequest
  ) => {
    updateCustomerMutation.mutate(
      {
        customerId: customer.id,
        params: {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          email: formValues.email,
          phoneNumber: formValues.phoneNumber,
        },
      },
      {
        onSuccess: async (updatedCustomer: Customer) => {
          await queryClient.invalidateQueries(
            getQueryKey(trpc.customers.get, {customerId: updatedCustomer.id})
          );

          closeModal(ModalName.EDIT_CUSTOMER);

          openNotification({
            message: `Updated ${formatFullName(
              updatedCustomer.firstName,
              updatedCustomer.lastName
            )} details successfully`,
            severity: 'success',
          });
        },
        onError: err => {
          openNotification({
            message:
              err.data?.axiosError?.response?.data.detail ??
              'Failed to update customer details',
            severity: 'error',
          });
        },
      }
    );
  };

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit(values => {
          handleEditCustomerSubmit(values).catch((err: Error) => {
            console.error(err);
          });
        })}
        style={{width: '100%'}}
      >
        <EditCustomerForm
          loading={updateCustomerMutation.isLoading}
          disabled={updateCustomerMutation.isLoading}
        />
      </form>
    </FormProvider>
  );
};

export default EditCustomerModal;
