import {Env} from '@local/frontend/@types/env';
import backendClient from '@local/frontend/config/backend';
import {useNotification} from '@local/frontend/hooks/useNotification';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {trpc} from '../trpc/trpc';

const switchEnvironment = async (env: Env): Promise<void> =>
  backendClient.put('/session/env', {env}).then(res => res.data);

export const useChangeSessionEnvironment = () => {
  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();

  return useMutation(switchEnvironment, {
    onSuccess: async (_, env) => {
      const formatEnv = () => {
        switch (env) {
          case Env.PRODUCTION:
            return 'live';
          default:
            return env;
        }
      };
      await queryClient.invalidateQueries();
      queryClient.setQueryData(trpc.session.getEnvironment.getQueryKey(), env);
      openNotification({
        message: `Switched to ${formatEnv()} mode`,
        severity: 'success',
      });
    },
    onError: () => {
      openNotification({
        message: 'Failed to switch mode',
        severity: 'error',
      });
    },
  });
};
