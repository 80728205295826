import {Outlet} from 'react-router-dom';
import React, {lazy} from 'react';
import IfHasPermissions from '@local/frontend/hocs/IfHasPermissions';
import {PaymentsScope} from '@local/backend/@types/scopes/PaymentsScopes';
import NewPaymentsTable from './NewPaymentsTable';

const ListPayments = lazy(() => import('./ListPayments'));

const ManagePaymentPage = lazy(() => import('./manage/ManagePaymentPage'));

export const paymentsRoutes = {
  path: 'payments',
  element: <Outlet />,
  children: [
    {
      index: true,
      element: (
        <IfHasPermissions
          thenRender={() => <ListPayments />}
          permissions={[PaymentsScope.READ]}
        />
      ),
    },
    {
      path: 'new',
      element: (
        <IfHasPermissions
          thenRender={() => <NewPaymentsTable />}
          permissions={[PaymentsScope.READ]}
        />
      ),
    },
    {
      path: ':paymentId',
      element: (
        <IfHasPermissions
          thenRender={() => <ManagePaymentPage />}
          permissions={[PaymentsScope.READ]}
        />
      ),
    },
  ],
};
