import type {CSSProperties, FC} from 'react';
import React from 'react';
import {
  useStripe,
  useElements,
  CardElement as StripeCardElement,
  Elements,
} from '@stripe/react-stripe-js';
import {Stack, styled} from '@mui/material';
import type {PaymentMethod} from '@stripe/stripe-js';
import {useForm} from 'react-hook-form';
import type {LoadingButtonProps} from '../../../../../components/atoms/buttons/LoadingButton';
import LoadingButton from '../../../../../components/atoms/buttons/LoadingButton';
import {stripePromise} from '../../../../../config/stripe';

const StyledCardElement = styled(StripeCardElement)<{style?: CSSProperties}>(
  ({style}) => ({
    borderRadius: '10px',
    borderWidth: '1px',
    borderColor: '#8797cd',
    borderStyle: 'solid',
    backgroundColor: '#f3f6ff',
    fontSize: '16px',
    height: '48px',
    padding: '14px 10px',
    ...style,
  })
);

type StripePaymentMethodFormProps = {
  onChange: (paymentMethod: PaymentMethod | undefined) => void;
  setError: (message: string | undefined) => void;
  render?: (elems: {
    CardElement: typeof StyledCardElement;
    buttonProps: LoadingButtonProps;
  }) => React.ReactNode;
} & Omit<LoadingButtonProps, 'onChange'>;

const StripePaymentMethodForm: FC<
  React.PropsWithChildren<StripePaymentMethodFormProps>
> = ({
  onChange,
  setError,
  render = ({buttonProps, CardElement}) => (
    <Stack direction="column" spacing={1}>
      <CardElement />
      <LoadingButton {...buttonProps}>Set payment method</LoadingButton>
    </Stack>
  ),
  ...props
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const {formState, handleSubmit} = useForm();

  const updateOrCreatePaymentMethod = async () => {
    const cardElement = elements?.getElement(StripeCardElement);

    if (stripe == null || cardElement == null) {
      return;
    }

    const paymentMethodResult = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (paymentMethodResult.error) {
      setError(paymentMethodResult.error.message);
    } else {
      onChange(paymentMethodResult.paymentMethod);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(updateOrCreatePaymentMethod)}
      style={{width: '100%'}}
    >
      {render({
        CardElement: StyledCardElement,
        buttonProps: {
          type: 'submit',
          disabled:
            !stripe || !elements || formState.isSubmitting || props.disabled,
          loading: formState.isSubmitting || props.loading,
        },
      })}
    </form>
  );
};

const StripePaymentMethodFormWithContext: FC<
  React.PropsWithChildren<StripePaymentMethodFormProps>
> = props => (
  <Elements stripe={stripePromise}>
    <StripePaymentMethodForm {...props} />
  </Elements>
);

export default StripePaymentMethodFormWithContext;
