import type {FC} from 'react';
import React from 'react';
import * as yup from 'yup';
import {Controller, useForm} from 'react-hook-form';
import {Grid, TextField} from '@mui/material';
import {yupResolver} from '@hookform/resolvers/yup';
import {useQueryClient} from '@tanstack/react-query';
import type {WebhookEndpoint} from '@local/backend/@types/updated-api-types/merchants/WebhookEndpoint';
import {useNotification} from '@local/frontend/hooks/useNotification';
import type {WebhookEndpointCreationParams} from '@local/backend/routes/webhooks.controller';
import {useCustomModals} from '@local/frontend/libs/modals/useModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import WebhookSelector from '../atoms/input/WebhookSelector';
import {trpc} from '../../libs/trpc/trpc';
import LoadingButton from '../atoms/buttons/LoadingButton';

const schema = yup.object({
  name: yup.string().min(1).max(100).required(),
  url: yup.string().min(11).required(),
  events: yup.array().required(),
});

const CreateWebhookForm: FC<React.PropsWithChildren<unknown>> = () => {
  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();
  const {closeModal} = useCustomModals();

  const {
    formState: {isSubmitting, isValid},
    ...formMethods
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      url: '',
      events: [],
    },
  });

  const addWebhookMutation = trpc.webhooks.add.useMutation({
    onSuccess: async (webhookEndpoint: WebhookEndpoint) => {
      closeModal(ModalName.CREATE_WEBHOOK);

      openNotification({
        message: `Webhook Endpoint '${webhookEndpoint.name}' Created`,
        severity: 'success',
      });

      await queryClient.invalidateQueries(trpc.webhooks.getQueryKey());
    },
    onError: err => {
      openNotification({
        message:
          err.data?.axiosError?.response?.data?.detail ??
          'Something went wrong',
        severity: 'error',
      });
    },
  });

  const onSubmit = (webhookEndpoint: WebhookEndpointCreationParams) => {
    addWebhookMutation.mutate({webhookEndpoint});
  };

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)}>
      <Grid container direction="column" rowSpacing={2}>
        <Grid item xs={12}>
          <Controller
            control={formMethods.control}
            name="name"
            render={({field, fieldState}) => (
              <TextField
                {...field}
                fullWidth
                required
                label="Webhook Name"
                placeholder="My Webhook"
                type="text"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={formMethods.control}
            name="url"
            render={({field, fieldState}) => (
              <TextField
                {...field}
                fullWidth
                required
                label="URL"
                placeholder="https://www.example.com/webhooks"
                type="text"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={formMethods.control}
            name="events"
            render={({field, fieldState}) => (
              <WebhookSelector
                {...field}
                variant="outlined"
                fullWidth
                required
                label="Select Events"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            loading={isSubmitting || addWebhookMutation.isLoading}
            disabled={addWebhookMutation.isLoading || isSubmitting || !isValid}
            type="submit"
            variant="contained"
          >
            Submit
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateWebhookForm;
