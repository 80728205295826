import type {Country} from '@handsin/api-node';
import {Currency} from '@handsin/api-node';
import {CountryCurrency} from '../@types/iso/CountryCurrency';
import {CountryName} from '../@types/iso/CountryName';

export const getCurrencyOfCountry = (country: Country): Currency =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Currency[CountryCurrency[country]];

export const getCountryName = (country: Country): CountryName =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  CountryName[country];
