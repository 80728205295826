import dayjs from 'dayjs';
import type {PaymentRecord} from '@local/backend/@types/updated-api-types/payments/PaymentRecord';
import {CustomCellRendererProps} from '@ag-grid-community/react';

export const DateCell = ({
  data,
}: CustomCellRendererProps<PaymentRecord, string>) => {
  if (!data?.createdAt) {
    return null;
  }

  return dayjs(data?.createdAt).format('LLL');
};
