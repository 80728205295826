import type {FC} from 'react';
import React from 'react';
import type {TextFieldProps} from '@mui/material';
import {Autocomplete, TextField, createFilterOptions} from '@mui/material';
import type {Currency} from '@handsin/api-node';
import {MoneyUtils} from '@handsin/money';
import merge from 'lodash-es/merge';
import type {CurrencyOption} from './currency-options';
import options from './currency-options';

function currencyCodeToOption(
  currencyCode: Currency
): CurrencyOption | undefined {
  return options.find(option => option.code === currencyCode);
}

export type CurrencySelectProps = Omit<
  TextFieldProps,
  'select' | 'onChange' | 'value'
> & {
  value?: Currency;
  defaultValue?: Currency;
  onChange: (currencyCode: Currency) => void;
  disabled?: boolean;
  length?: 'long' | 'short';
};

const CurrencySelect: FC<React.PropsWithChildren<CurrencySelectProps>> =
  React.forwardRef(
    (
      {value, defaultValue, disabled, onChange, length = 'short', ...props},
      ref
    ) => {
      const defaultSelected = defaultValue
        ? currencyCodeToOption(defaultValue)
        : undefined;
      const selected = value ? currencyCodeToOption(value) : undefined;

      return (
        <Autocomplete
          ref={ref}
          options={options.sort((a: CurrencyOption, b: CurrencyOption) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          )}
          getOptionLabel={option => {
            const currencySymbol = new MoneyUtils({
              amount: 0,
              currency: option.code,
            }).getCurrencySymbol();

            if (length === 'long') {
              return `${option.name} (${currencySymbol})`;
            }
            return `${option.code} (${currencySymbol})`;
          }}
          filterOptions={createFilterOptions<CurrencyOption>({
            ignoreCase: true,
            trim: true,
            matchFrom: 'any',
            stringify: (option: CurrencyOption) =>
              `${option.code.toLocaleLowerCase()} (${new MoneyUtils({
                amount: 0,
                currency: option.code,
              }).getCurrencySymbol()}) ${option.name}`,
          })}
          renderInput={params => (
            <TextField
              {...merge(params, props)}
              sx={{minWidth: 150, ...props.sx}}
            />
          )}
          isOptionEqualToValue={(a: CurrencyOption, b: CurrencyOption) =>
            a.code === b.code
          }
          disableClearable
          value={selected}
          defaultValue={defaultSelected}
          disabled={disabled}
          onChange={(
            _e: React.SyntheticEvent,
            option: CurrencyOption | null
          ) => {
            if (option) {
              onChange(option.code);
            }
          }}
        />
      );
    }
  );

CurrencySelect.displayName = 'CurrencySelect';

export default CurrencySelect;
