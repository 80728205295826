import CustomTooltip from '@local/frontend/components/atoms/CustomToolTip';
import useCanAccess from '@local/frontend/hooks/useCanAccess';
import {KeyboardArrowDown, KeyboardArrowRight} from '@mui/icons-material';
import {
  ListItemText,
  Collapse,
  ListItemIcon,
  ListItemButton,
  List,
  Box,
} from '@mui/material';
import type {FC} from 'react';
import React, {useEffect, useState} from 'react';
import type {LinkProps as RouterLinkProps} from 'react-router-dom';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import type {PageConfig} from '../config';

// needed to map MUI link props -> React router link props
const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
  (itemProps, ref) => <RouterLink ref={ref} {...itemProps} role={undefined} />
);
Link.displayName = 'DisplayName';

// custom hook to control open submenus
const useNavMenuController = (): {
  showSubroute: (path: string, active: boolean) => void;
  routeToVisibility: Record<string, boolean>;
} => {
  const [showRoutesMap, updateActiveMenu] = useState<Record<string, boolean>>(
    {}
  );

  return {
    showSubroute: (path: string, active: boolean): void => {
      updateActiveMenu({...showRoutesMap, [path]: active});
    },
    routeToVisibility: showRoutesMap,
  };
};

// ------------------ NAV ITEM COMPONENT ------------------
interface NavItemProps {
  route: PageConfig;
  depth?: number;
}

const NavItem: FC<React.PropsWithChildren<NavItemProps>> = ({
  route,
  depth = 0,
}) => {
  const location = useLocation();
  const {showSubroute, routeToVisibility} = useNavMenuController();
  const canAccessRoute = useCanAccess(route.permissions ?? []);

  const isSubpath = location.pathname.startsWith(route.path);

  useEffect(() => {
    if (isSubpath) {
      showSubroute(route.path, true);
    }
  }, []);

  // render non-nested nav links
  if (!route.subpages || route.subpages.length === 0) {
    const active = location.pathname === route.path;

    return (
      <CustomTooltip
        title="You do not have access to these resources."
        enabled={!canAccessRoute}
      >
        <ListItemButton
          sx={{
            backgroundColor: active ? 'secondary.lighter' : undefined,
            color: active ? 'primary.darker' : undefined,
            '&:hover': {
              backgroundColor: active ? 'secondary.lighter' : undefined,
              color: active ? 'primary.darker' : undefined,
            },
            borderRadius: depth === 0 ? 1 : 0,
            m: depth === 0 ? 1 : 0,
            pl: depth === 0 ? undefined : 3 * depth,
          }}
          component={Link}
          to={route.path}
          id={route.id}
          disabled={!canAccessRoute}
        >
          {route.icon && (
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 2,
                color: 'inherit',
              }}
            >
              {route.icon}
            </ListItemIcon>
          )}
          <ListItemText
            primaryTypographyProps={{
              noWrap: true,
              width: '100%',
              fontSize: 13,
              fontWeight: active ? 600 : undefined,
            }}
            primary={route.name}
          />
        </ListItemButton>
      </CustomTooltip>
    );
  }

  const isOpen = routeToVisibility[route.path] === true;
  // render nested nav links
  return (
    <Box
      sx={{
        backgroundColor: isOpen ? 'secondary.lighter' : undefined,
        borderRadius: depth === 0 ? 1 : 0,
        m: depth === 0 ? 1 : 0,
        pl: depth === 0 ? undefined : 3 * depth,
        overflow: 'hidden',
      }}
    >
      <CustomTooltip
        title="You do not have access to this page."
        enabled={!canAccessRoute}
      >
        <ListItemButton
          sx={{
            backgroundColor: isSubpath ? 'secondary.lighter' : undefined,
            color: isSubpath ? 'primary.darker' : undefined,
            '&:hover': {
              backgroundColor: isSubpath ? 'secondary.lighter' : undefined,
              color: isSubpath ? 'primary.darker' : undefined,
            },
            borderBottomLeftRadius: isOpen ? 0 : undefined,
            borderBottomRightRadius: isOpen ? 0 : undefined,
          }}
          onClick={() => showSubroute(route.path, !isOpen)}
          id={route.id}
          disabled={!canAccessRoute}
        >
          {route.icon && (
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 2,
                color: 'inherit',
              }}
            >
              {route.icon}
            </ListItemIcon>
          )}
          <ListItemText
            primaryTypographyProps={{
              noWrap: true,
              width: '100%',
              fontSize: 13,
              fontWeight: isSubpath ? 600 : undefined,
            }}
            primary={route.name}
          />
          {isOpen ? (
            <KeyboardArrowDown sx={{minWidth: 0, ml: 1}} />
          ) : (
            <KeyboardArrowRight sx={{minWidth: 0, ml: 1}} />
          )}
        </ListItemButton>
      </CustomTooltip>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List disablePadding>
          {route.subpages.map(subpage => (
            <NavItem key={subpage.id} route={subpage} depth={depth + 1} />
          ))}
        </List>
      </Collapse>
    </Box>
  );
};

export default NavItem;
