import type {FC} from 'react';
import React from 'react';
import {useQueries} from '@tanstack/react-query';
import GlobalModal from './GlobalModal';
import {modalConfig} from './config';
import type {ModalQueryData} from './ModalQueryData';

const ModalProvider: FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const initModals = useQueries({
    queries: Object.values(modalConfig).map(config => ({
      queryKey: ['modalConfig', config.name],
      queryFn: (): ModalQueryData => ({
        name: config.name,
        props: config.defaultProps,
        vars: undefined,
      }),
      refetchOnWindowFocus: false,
    })),
  });

  return (
    <>
      {children}
      {initModals.map(modal => {
        if (!modal.data) {
          return null;
        }

        const {Element} = modalConfig[modal.data.name];
        const vars = (modal.data.vars ?? {}) as React.ComponentProps<
          typeof Element
        >;
        return (
          modal.data?.props.open && (
            <GlobalModal
              key={modal.data.name}
              name={modal.data.name}
              {...(modal.data.props ?? {})}
            >
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-expect-error */}
              <Element {...vars} />
            </GlobalModal>
          )
        );
      })}
    </>
  );
};

export default ModalProvider;
