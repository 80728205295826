import type {FC} from 'react';
import React from 'react';
import {useParams} from 'react-router-dom';
import {useMerchant, useMultiCard} from '../libs/trpc/trpc';
import ValidationError from '../components/atoms/ValidationError';
import Loading from '../components/atoms/Loading';

const MultiCardValidationWrapper: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const {multiCardPaymentId} = useParams();
  const {
    data: groupPayment,
    error: groupPaymentError,
    isInitialLoading: isGroupPaymentInitialLoading,
  } = useMultiCard(multiCardPaymentId ? {multiCardPaymentId} : undefined, {
    enabled: !!multiCardPaymentId,
  });

  const {
    data: merchant,
    error: merchantError,
    isInitialLoading: isProfileLoading,
  } = useMerchant(undefined, {
    enabled: !!multiCardPaymentId,
  });

  // check if no Id was provided
  if (!multiCardPaymentId) {
    return <ValidationError message="No multi card ID supplied" />;
  }

  // check if we are in a loading state
  if (isGroupPaymentInitialLoading || isProfileLoading) {
    return <Loading />;
  }

  // check if no profile merchant data could be found
  if (!merchant || merchantError) {
    return <ValidationError message="No merchant profile found" />;
  }

  // check if no group payment
  if (!groupPayment || groupPaymentError) {
    return (
      <ValidationError
        message={`A group payment with ID '${multiCardPaymentId}' does not exist for merchant: ${merchant.name}`}
      />
    );
  }

  return children;
};

export default MultiCardValidationWrapper;
