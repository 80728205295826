import type {ChipProps, TypographyProps} from '@mui/material';
import {Chip} from '@mui/material';
import type {FC} from 'react';
import React, {useRef} from 'react';
import CopyText from '../text/CopyText';

interface CopyChipProps extends Omit<ChipProps, 'children'> {
  value: string;
  onCopy?: () => void;
  textProps?: TypographyProps;
  timeout?: number;
  children?: React.ReactNode;
}

const CopyChip: FC<React.PropsWithChildren<CopyChipProps>> = ({
  value,
  onCopy,
  textProps,
  timeout,
  children,
  ...props
}) => {
  const ref = useRef<HTMLSpanElement | null>(null);

  return (
    <Chip
      size="small"
      color="default"
      {...props}
      sx={{
        borderRadius: 1,
        ...props.sx,
      }}
      clickable
      onClick={() => ref.current?.click()}
      label={
        <CopyText
          {...textProps}
          ref={ref}
          value={value}
          onCopy={onCopy}
          timeout={timeout}
        >
          {children ?? value}
        </CopyText>
      }
    />
  );
};

export default CopyChip;
