export enum CardBrand {
  OTHER_BRAND = 'OTHER',
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  AMERICAN_EXPRESS = 'AMEX',
  DISCOVER = 'DISCOVER',
  DISCOVER_DINERS = 'DISCOVER DINERS',
  JCB = 'JCB',
  AMAZON = 'AMAZON',
  CIRRUS = 'CIRRUS',
  DIRECT = 'DIRECT DEBIT',
  EBAY = 'EBAY',
  EWAY = 'EWAY',
  GOOGLE = 'GOOGLE',
  MAESTRO = 'MAESTRO',
  PAYPAL = 'PAYPAL',
  SAGE = 'SAGE',
  SHOPIFY = 'SHOPIFY',
  SKRILL = 'SKRILL',
  SKRILL_MONEY = 'SKRILL MONEY',
  VISA_ELECTRON = 'VISA ELECTRON',
  WESTERN_UNION = 'WESTERN UNION',
  WORLDPAY = 'WORLDPAY',
}
