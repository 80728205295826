import type {FC} from 'react';
import React from 'react';
import type {MerchantScopes} from '@local/backend/@types/scopes/MerchantScopes';
import {Navigate} from 'react-router-dom';
import useCanAccess from '../hooks/useCanAccess';
import usePermissions from '../hooks/usePermissions';

interface IfHasPermissionsProps {
  permissions: MerchantScopes[];
  thenRender: () => JSX.Element;
  elseRender?: (
    requiredPermissions: MerchantScopes[],
    actualPermissions: MerchantScopes[]
  ) => JSX.Element;
}

const IfHasPermissions: FC<React.PropsWithChildren<IfHasPermissionsProps>> = ({
  elseRender = () => (
    <Navigate to="/403" replace state={{redirectPath: '/dashboard/home'}} />
  ),
  thenRender,
  permissions,
}) => {
  const actualPermissions = usePermissions();
  const canAccess = useCanAccess(permissions);

  if (canAccess) {
    return thenRender();
  }

  return elseRender(permissions, actualPermissions);
};

export default IfHasPermissions;
