import type {FC} from 'react';
import React from 'react';
import type {AutocompleteRenderInputParams} from '@mui/material/Autocomplete';
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import type {TextFieldProps} from '@mui/material/TextField';
import TextField from '@mui/material/TextField';
import {Country} from '@handsin/api-node';
import {Box} from '@mui/material';
import {CountryName} from '../../../@types/iso/CountryName';
import {getCountryName} from '../../../utils/country';

interface FilterCountryOption {
  name: CountryName;
  code: string;
}

function countryCodeToOption(
  countryCode: Country
): FilterCountryOption | undefined {
  const countryName: CountryName | undefined = getCountryName(countryCode);
  return countryName ? {code: countryCode, name: countryName} : undefined;
}

export type CountrySelectProps = Omit<
  TextFieldProps,
  'value' | 'defaultValue' | 'onChange'
> & {
  value?: Country; // countryCode
  defaultValue?: Country; // countryCode
  onChange: (countryCode: Country | null) => void;
  disabled?: boolean;
};

const CountrySelect: FC<React.PropsWithChildren<CountrySelectProps>> =
  React.forwardRef(
    ({value, defaultValue, onChange, disabled, ...props}, ref) => {
      const defaultSelected = defaultValue
        ? countryCodeToOption(defaultValue)
        : undefined;
      const selected = value ? countryCodeToOption(value) : undefined;

      return (
        <Autocomplete
          inputMode="search"
          autoComplete
          disabled={disabled}
          defaultValue={defaultSelected ?? null} // need null to prevent MUI control errors
          value={selected ?? null} // need null to prevent MUI control errors
          options={Object.entries(CountryName)
            .map(([countryCode, countryName]) => ({
              name: countryName,
              code: countryCode,
            }))
            .sort((a: FilterCountryOption, b: FilterCountryOption) =>
              a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            )}
          isOptionEqualToValue={(
            a: FilterCountryOption,
            b: FilterCountryOption
          ) => a.code === b.code}
          filterOptions={createFilterOptions<FilterCountryOption>({
            ignoreCase: true,
            trim: true,
            matchFrom: 'any',
            stringify: (option: FilterCountryOption) =>
              `${option.name} ${option.code}`,
          })}
          groupBy={(option: FilterCountryOption) =>
            option.name[0].toUpperCase()
          }
          getOptionLabel={(option: FilterCountryOption) => option.name}
          onChange={(
            _e: React.SyntheticEvent,
            option: FilterCountryOption | null
          ) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const country = option?.code ? Country[option.code] : undefined;
            onChange(country ?? null);
          }}
          renderOption={(renderProps, option) => (
            <Box
              component="li"
              sx={{'& > img': {mr: 2, flexShrink: 0}}}
              {...renderProps}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.name} ({option.code})
            </Box>
          )}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              {...props}
              inputProps={{
                ...params.inputProps,
                ...props.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                ...params.InputProps,
                startAdornment: selected ? (
                  <img
                    loading="lazy"
                    width="20"
                    style={{marginLeft: 5, marginRight: 5}}
                    src={`https://flagcdn.com/w20/${selected?.code?.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${selected?.code?.toLowerCase()}.png`}
                    alt=""
                  />
                ) : null,
              }}
              inputRef={ref}
            />
          )}
        />
      );
    }
  );

CountrySelect.displayName = 'CountrySelect';

export default CountrySelect;
