import type {FC} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import type {
  CellClickedEvent,
  ColDef,
  FilterChangedEvent,
  FilterModel,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  IDatasource,
} from '@ag-grid-community/core';
import type {PaymentRecord} from '@local/backend/@types/updated-api-types/payments/PaymentRecord';
import DateTimeRangePicker from '@local/frontend/components/atoms/input/DateTimeRangePicker';
// import SetFilter from '@local/frontend/components/tables/SetFilter';
import config from '@local/frontend/config';
import {Env} from '@local/frontend/@types/env';
import {MoneyUtils} from '@handsin/money';
import {useNavigate} from 'react-router-dom';
// import {PaymentStatus} from '@handsin/api-node';
import {AgGridReact} from '@ag-grid-community/react';
import {InfiniteRowModelModule} from '@ag-grid-community/infinite-row-model';
import {CsvExportModule} from '@ag-grid-community/csv-export';
import {
  Button,
  CircularProgress,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import dayjs from 'dayjs';
import {useMerchant, useQuery} from '../../../libs/trpc/trpc';
import CustomerCell from '../../../components/tables/cells/CustomerCell';
import {DateCell} from '../../../components/tables/cells/DateCell';
import {IdCell} from '../../../components/tables/cells/IdCell';
import {PaymentStatusCell} from '../../../components/tables/cells/PaymentStatusCell';
import {MoneyCell} from '../../../components/tables/cells/MoneyCell';
import '@ag-grid-community/styles/ag-grid.css'; // Core CSS
import '@ag-grid-community/styles/ag-theme-quartz.css'; // Theme
import {ProcessorCell} from '../../../components/tables/cells/PaymentMethodCell';
import SearchBar from '../../../components/tables/SearchBar';

const loadAtATime = 100;
const modules = [InfiniteRowModelModule, CsvExportModule];

const TableStyle = styled('div')`
  width: 100%;
  height: 100%;

  @media screen and (max-width: 720px) {
    div.ag-theme-quartz,
    div.ag-theme-quartz-dark {
      --ag-font-size: 12px;
      --ag-grid-size: 6px;
    }
  }

  .ag-theme-quartz {
    --ag-background-color: none;
    --ag-font-family: Poppins, sans-serif;
  }

  .ag-theme-quartz .ag-body-viewport,
  .ag-theme-quartz-dark .ag-body-viewport {
    padding-top: 8px;
  }

  .ag-theme-quartz .ag-row-group .ag-icon,
  .ag-theme-quartz-dark .ag-row-group .ag-icon {
    opacity: 0.5;
  }

  .ag-theme-quartz .ag-row,
  .ag-theme-quartz-dark .ag-row {
    border-bottom: none;
  }

  .ag-theme-quartz .ag-row:not(.ag-row-level-1),
  .ag-theme-quartz-dark .ag-row:not(.ag-row-level-1) {
    border-radius: 8px;
    overflow: hidden;
  }

  /* Required to transition background color for row hover */
  .ag-theme-quartz .ag-row:not(.ag-row-level-1)::before,
  .ag-theme-quartz-dark .ag-row:not(.ag-row-level-1)::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }

  .ag-theme-quartz .ag-cell-expandable,
  .ag-theme-quartz-dark .ag-cell-expandable {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ag-theme-quartz .ag-cell,
  .ag-theme-quartz-dark .ag-cell {
    display: flex;
    align-items: center;
  }

  .ag-theme-quartz .ag-cell:first-child,
  .ag-theme-quartz-dark .ag-cell:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .ag-theme-quartz .ag-cell:last-child,
  .ag-theme-quartz-dark .ag-cell:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .ag-theme-quartz .ag-right-aligned-cell,
  .ag-theme-quartz-dark .ag-right-aligned-cell {
    justify-content: flex-end;
  }

  .ag-theme-quartz-dark .ag-header {
    background-color: ${({theme}) => theme.palette.primary.lighter};
    border-radius: 8px;
    border: none;
  }

  .ag-theme-quartz .ag-header {
    background-color: ${({theme}) => theme.palette.primary.lighter};
    color: #39485d;
    border-radius: 8px;
    border: 1px solid #eff0f1;
  }

  .ag-theme-quartz .ag-header-cell-text,
  .ag-theme-quart-dark .ag-header-cell-text {
    color: #1f383c;
    display: flex;
    align-items: center;
  }

  .ag-theme-quartz-dark .ag-header-cell-text {
    color: #c9d0da;
  }

  .ag-theme-quartz .ag-root-wrapper,
  .ag-theme-quartz-dark .ag-root-wrapper {
    border: none;
  }

  .ag-theme-quartz .ag-full-width-container .ag-row,
  .ag-theme-quartz-dark .ag-full-width-container .ag-row,
  .ag-theme-quartz .ag-full-width-container .ag-details-row,
  .ag-theme-quartz-dark .ag-full-width-container .ag-details-row {
    background-color: transparent;
  }

  .ag-theme-quartz .ag-details-row,
  .ag-theme-quartz-dark .ag-details-row {
    padding: 16px;
  }

  .ag-theme-quartz .ag-paging-panel {
    height: 40px;
  }

  .ag-theme-quartz .ag-paging-panel > span:last-child {
    margin-right: 0;
  }

  .ag-center-cols-viewport {
    min-height: unset !important;
  }

  .grid {
    --ag-header-background-color: transparent !important;

    height: calc(
      100vh - var(--layout-grid-header-height) - 62px - var(
          --layout-grid-margin
        )
    );
    margin: 0 var(--layout-grid-margin) var(--layout-grid-margin);
  }
`;

const NewPaymentsTable: FC<React.PropsWithChildren<unknown>> = () => {
  const {data: merchant} = useMerchant();
  const navigate = useNavigate();
  const [filterModel, setFilterModel] = useState<FilterModel>({
    createdAt: {
      type: 'inRange',
      dateFrom: dayjs()
        .subtract(1, 'month')
        .startOf('day')
        .toDate()
        .toISOString(),
      dateTo: null,
    },
  });
  const [gridApi, setGridApi] = useState<GridApi | undefined>();

  const queryPayments = useQuery(
    {
      category: 'payments',
      limit: loadAtATime,
      filter: filterModel,
    },
    {
      getNextPageParam: lastPage => lastPage.cursor,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const dataSource: IDatasource = useMemo(
    () => ({
      rowCount: undefined,
      getRows: async ({startRow, endRow, successCallback, failCallback}) => {
        try {
          let resp = await queryPayments.fetchNextPage();
          let flatData = resp.data?.pages.flatMap(page => page.data) ?? [];

          while (
            flatData.length < endRow &&
            resp.hasNextPage === true &&
            !resp.isFetching
          ) {
            // eslint-disable-next-line no-await-in-loop
            resp = await queryPayments.fetchNextPage();
            flatData = resp.data?.pages?.flatMap(page => page.data) ?? [];
          }
          const rowsThisPage = flatData.slice(startRow, endRow);
          // if on or after the last page, work out the last row.
          let lastRow = -1;
          if (!resp.hasNextPage) {
            lastRow = flatData.length;
          }

          if (rowsThisPage.length === 0) {
            gridApi?.showNoRowsOverlay();
          } else {
            gridApi?.hideOverlay();
          }

          // call the success callback
          successCallback(rowsThisPage, lastRow);
        } catch (err) {
          failCallback();
        }
      },
    }),
    [gridApi, filterModel]
  );

  const columnDefs = useMemo<ColDef<PaymentRecord>[]>(
    () => [
      {
        minWidth: 100,
        type: 'numericColumn',
        headerName: 'Amount',
        valueFormatter: ({data}) =>
          data?.amountMoney ? MoneyUtils.formatMoney(data?.amountMoney) : '',
        field: 'totalMoney',
        cellRenderer: MoneyCell,
      },
      {
        minWidth: 100,
        headerName: 'Status',
        field: 'status',
        cellRenderer: PaymentStatusCell,
        // filter: SetFilter,
        // filterParams: {
        //   options: Object.values(PaymentStatus),
        //   filterPlaceholder: 'Filter by status',
        // },
      },
      {
        headerName: 'Payment method',
        field: 'gatewayName',
        cellRenderer: ProcessorCell,
      },
      {
        minWidth: 180,
        headerName: 'Reference ID',
        field: 'referenceId',
        cellRenderer: IdCell,
        filter: true,
        filterParams: {
          filterOptions: ['equals', 'contains'],
          filterPlaceholder: 'Filter by reference ID',
        },
      },
      {
        flex: 2,
        minWidth: 200,
        headerName: 'Customer',
        field: 'customerId',
        filter: true,
        filterParams: {
          filterOptions: ['equals'],
          filterPlaceholder: 'Filter by customer ID',
        },
        cellRenderer: CustomerCell,
      },
      {
        flex: 2,
        minWidth: 200,
        headerName: 'Description',
        field: 'description',
        filter: true,
        filterParams: {
          filterOptions: ['equals', 'contains'],
          filterPlaceholder: 'Filter by description',
        },
      },
      {
        minWidth: 250,
        headerName: 'Date',
        field: 'createdAt',
        cellDataType: 'date',
        type: 'rightAligned',
        cellStyle: {
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        },
        valueFormatter: ({data}) =>
          data?.createdAt ? dayjs(data.createdAt).toISOString() : '',
        cellRenderer: DateCell,
      },
    ],
    []
  );

  const handleFilterChange = useCallback((params: FilterChangedEvent) => {
    const changedFilterModel = params.api.getFilterModel();
    setFilterModel(changedFilterModel);
  }, []);

  useEffect(() => {
    gridApi?.setGridOption('loading', queryPayments.isLoading);
  }, [gridApi, queryPayments.isLoading]);

  const rowStyle = useMemo(
    () => ({
      cursor: 'pointer',
    }),
    []
  );

  const defaultColDef = useMemo<ColDef<PaymentRecord>>(
    () => ({
      resizable: true,
      sortable: false,
      filter: false,
      filterParams: {
        defaultOption: 'equals',
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        maxNumConditions: 1,
      },
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
      },
    }),
    []
  );

  const getRowId = useCallback(
    (params: GetRowIdParams<PaymentRecord>) => params.data.id,
    []
  );

  const onCellClicked = useCallback((e: CellClickedEvent) => {
    if (!e.column.isPinned()) navigate(`/dashboard/payments/${e.data?.id}`);
  }, []);

  // const rowSelection = useMemo<RowSelectionOptions | 'single' | 'multiple'>(
  //   () => ({
  //     mode: 'multiRow',
  //     selectAll: 'currentPage',
  //   }),
  //   []
  // );

  // const selectionColumnDef = useMemo<SelectionColumnDef>(
  //   () => ({
  //     maxWidth: 50,
  //     pinned: 'left',
  //   }),
  //   []
  // );

  const onGridReady = useCallback((params: GridReadyEvent) => {
    setGridApi(params.api);
  }, []);

  return (
    <Stack spacing={1} width="100%" height="100%">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <SearchBar
            size="small"
            variant="outlined"
            color="primary"
            placeholder="Search for Payment ID"
            disabled={!gridApi}
            onChange={value => {
              setFilterModel({
                id: {
                  type: 'equals',
                  filterType: 'text',
                  filter: value,
                },
              });
            }}
            value={filterModel?.id?.filter}
          />
          {queryPayments.isFetching ? (
            <CircularProgress variant="indeterminate" size={20} />
          ) : (
            <Typography variant="caption">
              {queryPayments.data?.pages.flatMap(page => page.data)?.length}
              {queryPayments.hasNextPage && '+'} results
            </Typography>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Tooltip arrow title="Coming soon!">
            <span>
              <Button
                disabled={!gridApi || config.ENV !== Env.DEVELOPMENT}
                color="inherit"
                variant="outlined"
                size="small"
                onClick={() => {
                  gridApi?.exportDataAsCsv({
                    fileName: 'payments.csv',
                    exportedRows: 'all',
                    allColumns: true,
                  });
                }}
                startIcon={<DownloadForOfflineIcon />}
              >
                Export
              </Button>
            </span>
          </Tooltip>
          <DateTimeRangePicker
            disabled={!gridApi}
            minDate={
              merchant?.createdAt ? new Date(merchant.createdAt) : undefined
            }
            maxDate={new Date()}
            startDate={
              filterModel.createdAt?.dateFrom
                ? dayjs(filterModel.createdAt.dateFrom).toDate()
                : undefined
            }
            onRangeChange={(startDate, endDate) => {
              setFilterModel(fm => ({
                ...fm,
                createdAt: {
                  ...fm.createdAt,
                  type: 'inRange',
                  dateFrom: startDate?.toISOString(),
                  dateTo: endDate?.toISOString(),
                },
              }));
            }}
            endDate={
              filterModel.createdAt?.dateTo
                ? dayjs(filterModel.createdAt.dateTo).toDate()
                : undefined
            }
          />
        </Stack>
      </Stack>
      <TableStyle>
        <div
          className="ag-theme-quartz"
          style={{height: '100%', width: '100%'}}
        >
          <AgGridReact<PaymentRecord>
            onGridReady={onGridReady}
            // selectionColumnDef={selectionColumnDef}
            popupParent={document.body}
            // rowSelection={rowSelection}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={rowStyle}
            onCellClicked={onCellClicked}
            getRowId={getRowId}
            datasource={dataSource}
            pagination
            onFilterChanged={handleFilterChange}
            rowModelType="infinite"
            cacheBlockSize={100}
            cacheOverflowSize={2}
            maxConcurrentDatasourceRequests={1}
            infiniteInitialRowCount={1}
            maxBlocksInCache={2}
            modules={modules}
          />
          {queryPayments.isError && (
            <div>Error: {JSON.stringify(queryPayments.error)}</div>
          )}
        </div>
      </TableStyle>
    </Stack>
  );
};

export default NewPaymentsTable;
