import type {FC} from 'react';
import React, {useEffect, useState} from 'react';
import {Link as RouterLink, useLocation, useParams} from 'react-router-dom';
import type {BreadcrumbsProps} from '@mui/material';
import {Breadcrumbs, Link} from '@mui/material';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {routeToBreadcrumbName} from '../../header/config';

interface BreadcrumbCollapsedPaths {
  [pathname: string]: boolean | undefined;
}

export const BreadCrumbsWidget: FC<
  React.PropsWithChildren<BreadcrumbsProps>
> = props => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);

  const urlParams = useParams();

  const [breadcrumbCollapsedPaths, toggleBreadcrumbCollapsedPaths] =
    useState<BreadcrumbCollapsedPaths>({});

  useEffect(() => {
    toggleBreadcrumbCollapsedPaths(
      Object.values(urlParams).reduce(
        (collapsedPaths, pathName) =>
          pathName ? {...collapsedPaths, [pathName]: true} : collapsedPaths,
        {}
      )
    );
  }, [urlParams]);

  return (
    <Breadcrumbs
      maxItems={3}
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
      {...props}
    >
      {pathnames.map((pathName, index) => {
        const last = index === pathnames.length - 1;

        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
        const breadcrumbPathName = routeToBreadcrumbName[to] ?? pathName;

        return last ? (
          <Typography
            noWrap={breadcrumbCollapsedPaths[pathName]}
            color="text.primary"
            key={to}
            onClick={() =>
              toggleBreadcrumbCollapsedPaths(
                (old: BreadcrumbCollapsedPaths) => ({
                  ...old,
                  [pathName]:
                    old[pathName] !== undefined ? !old[pathName] : undefined,
                })
              )
            }
            sx={{
              width: '50px',
              whiteSpace: 'nowrap',
              '&:hover': {
                cursor: 'pointer',
                textDecoration: 'underline',
                color: 'inherit',
              },
            }}
          >
            {breadcrumbPathName}
          </Typography>
        ) : (
          <Link
            underline="hover"
            color="inherit"
            to={to}
            key={to}
            component={RouterLink}
          >
            {breadcrumbPathName}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};
