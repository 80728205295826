import axios from 'axios';

export const BACKEND_CONFIG = {
  URL: process.env.REACT_APP_BACKEND_URL ?? '/proxy/api',
};

const backendClient = axios.create({
  baseURL: BACKEND_CONFIG.URL,
});

export default backendClient;
