import type {FC} from 'react';
import React from 'react';
import type {AutocompleteRenderInputParams} from '@mui/material/Autocomplete';
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import type {TextFieldProps} from '@mui/material/TextField';
import TextField from '@mui/material/TextField';
import {Checkbox, Stack, Typography} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import type {EventType} from '@local/backend/@types/updated-api-types/webhooks/EventType';
import type {WebhookOption} from './webhook-options';
import {webhookOptions} from './webhook-options';

const webhookCodesToWebhookOptions = (
  webhookCodes: EventType[]
): WebhookOption[] =>
  webhookCodes
    .map(webhookCode =>
      webhookOptions.find(option => option.code === webhookCode)
    )
    .filter(
      (maybeWebhookOption): maybeWebhookOption is WebhookOption =>
        !!maybeWebhookOption
    );

type WebhookSelectorProps = TextFieldProps & {
  value?: EventType[]; // webhookCodes
  defaultValue?: EventType[]; // webhookCodes
  onChange: (webhookCodes: EventType[]) => void;
};

const WebhookSelector: FC<React.PropsWithChildren<WebhookSelectorProps>> =
  React.forwardRef(({value, defaultValue, onChange, ...props}, ref) => {
    const defaultSelected = defaultValue
      ? webhookCodesToWebhookOptions(defaultValue)
      : [];
    const selected = value ? webhookCodesToWebhookOptions(value) : [];

    return (
      <Autocomplete
        inputMode="text"
        autoComplete
        multiple
        disableCloseOnSelect
        defaultValue={defaultSelected}
        value={selected}
        options={webhookOptions.sort((a: WebhookOption, b: WebhookOption) =>
          a.code.toLowerCase() > b.code.toLowerCase() ? 1 : -1
        )}
        isOptionEqualToValue={(a: WebhookOption, b: WebhookOption) =>
          a.code === b.code
        }
        filterOptions={createFilterOptions<WebhookOption>({
          ignoreCase: true,
          trim: true,
          matchFrom: 'any',
          stringify: (option: WebhookOption) => `${option.name} ${option.code}`,
        })}
        groupBy={(option: WebhookOption) => {
          const lastUnderscore = option.code.lastIndexOf('_');
          if (lastUnderscore === -1) {
            return (
              option.code.charAt(0).toUpperCase() +
              option.code.substring(1).toLowerCase()
            );
          }
          return (
            option.code.charAt(0).toUpperCase() +
            option.code
              .slice(1, lastUnderscore)
              .toLowerCase()
              .split('_')
              .join(' ')
          );
        }}
        getOptionLabel={(option: WebhookOption) => option.name}
        onChange={(_: React.SyntheticEvent, options: WebhookOption[]) => {
          if (options.find(option => option.code === 'all')) {
            onChange(
              webhookOptions.length === options.length
                ? []
                : (webhookOptions
                    .filter(option => option.code !== 'all')
                    .map(option => option.code) as EventType[])
            );
          } else {
            onChange(
              options
                .filter(option => option.code !== 'all')
                .map(option => option.code) as EventType[]
            );
          }
        }}
        renderOption={(renderProps, option, {selected: checkboxActive}) => {
          if (option.code === 'all') {
            return (
              <li {...renderProps}>
                <Stack direction="row" spacing={1}>
                  <Stack direction="column" alignItems="center">
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      checked={webhookOptions.length - 1 === selected.length}
                    />
                  </Stack>
                  <Stack direction="column" alignItems="center">
                    <Typography>{option.name}</Typography>
                  </Stack>
                </Stack>
              </li>
            );
          }

          return (
            <li {...renderProps}>
              <Stack direction="row" spacing={1}>
                <Stack direction="column" alignItems="center">
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    checked={checkboxActive}
                  />
                </Stack>
                <Stack direction="column">
                  <Stack direction="row" justifyContent="space-between">
                    <Typography>{option.code}</Typography>
                  </Stack>
                  <Typography fontSize="small" sx={{color: 'grey.700'}}>
                    {option.description}
                  </Typography>
                </Stack>
              </Stack>
            </li>
          );
        }}
        renderTags={(options: WebhookOption[]) =>
          `Selected ${options.length} event${options.length > 1 ? 's' : ''}`
        }
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            {...props}
            inputProps={{
              ...params.inputProps,
              ...props.inputProps,
              autoComplete: 'new-password', // disables some autocomplete and autofill
              form: {
                autocomplete: 'off', // allows form to be submitted with this field rather than recognised as empty
              },
            }}
            inputRef={ref}
          />
        )}
      />
    );
  });

WebhookSelector.displayName = 'WebhookSelector';

export default WebhookSelector;
