import * as Yup from 'yup';
import type {SplitType} from '@local/backend/@types/updated-api-types/group-payments/SplitType';
import type {LineItem} from '@local/backend/@types/updated-api-types/payments/LineItem';
import {Currency} from '@handsin/money';
import {Country, Language} from '@handsin/api-node';
import {imageFileSchema} from '@local/frontend/libs/schema/image-file.schema';
import {
  emailValidation,
  YupNameValidation,
  phoneNumberValidation,
} from './custom-validation';

export interface FormCustomer {
  firstName: string;
  lastName?: string;
  email: string;
  phoneNumber?: string;
}

export type CreateItemFormValue = Yup.InferType<typeof itemValidationSchema>;

export interface CreateGroupPaymentFormValues {
  currency: Currency;
  lineItems: LineItem[];
  owner: FormCustomer;
  invitedMembers: FormCustomer[];
  expectedGroupSize?: number;
  expirationDate?: Date;
  splitType: SplitType | undefined;
}

export interface CreateCheckoutFormValues {
  currency: Currency;
  lineItems: LineItem[];
  customer?: FormCustomer;
  expirationDate?: Date;
}

export const itemValidationSchema = Yup.object({
  name: Yup.string()
    .required('Please provide a product name')
    .min(2, 'Product name is too short'),
  description: Yup.string(),
  amountMoney: Yup.object({
    amount: Yup.number()
      .required('A price for this product is required')
      .test(
        'greaterThanZero',
        'The price for this product must be more than 0',
        value => (value ? value > 0 : false)
      ),
    currency: Yup.mixed<Currency>().oneOf(Object.values(Currency)).required(),
  }).required(),
  imageFile: imageFileSchema(),
  extraProductInformation: Yup.array(
    Yup.object({
      key: Yup.string().required(),
      value: Yup.string().required(),
    }).required()
  ).optional(),
});

export const productsValidationSchema = Yup.object({
  currency: Yup.mixed<Currency>().oneOf(Object.values(Currency)).required(),
  lineItems: Yup.array().of(Yup.mixed<LineItem>().required()).min(1).required(),
});

export const addNewCustomerValidationSchema = Yup.object({
  firstName: YupNameValidation('First name').required('First name is required'),
  lastName: YupNameValidation('Last name').notRequired(),
  email: emailValidation().required('Email is required'),
  phoneNumber: phoneNumberValidation(),
  phoneNumberCountry: Yup.mixed<Country>().oneOf(Object.values(Country)),
  language: Yup.mixed<Language>().oneOf(Object.values(Language)),
});

export const refundCustomerFormSchema = Yup.object({
  refundAmount: Yup.object({
    amount: Yup.string()
      .required('Refund Amount required')
      .matches(/^\d+(.\d{1,2})?$/, 'Incorrect format (e.g: 10.00, 10.0)'),
    currency: Yup.string(),
  }),
  reason: Yup.string().max(1000, 'Reason is too long'),
  shouldRefundFullAmount: Yup.boolean(),
});
