import React, {lazy} from 'react';
import {createBrowserRouter, Navigate, Outlet} from 'react-router-dom';
import {wrapCreateBrowserRouter} from '@sentry/react';
import {userRoutes} from './user/routes';
import {dashboardRoutes} from './dashboard/routes';
import {connectRoutes} from './connect/routes';
import ErrorPage from './ErrorPage';
import NotFoundPage from './error/NotFoundPage';

const CallbackPage = lazy(() => import('./CallbackPage'));

const ForbiddenPage = lazy(() => import('./error/ForbiddenPage'));

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const routes = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Outlet />,
    errorElement: <ErrorPage />,
    children: [
      {index: true, element: <Navigate to="dashboard" replace />},
      userRoutes,
      dashboardRoutes,
      connectRoutes,
      {path: 'callback', element: <CallbackPage />},
    ],
  },
  {
    path: '403',
    element: <ForbiddenPage />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]);
