import React from 'react';
import CopyChip from '@local/frontend/components/atoms/chips/CopyChip';
import type {PaymentRecord} from '@local/backend/@types/updated-api-types/payments/PaymentRecord';
import {CustomCellRendererProps} from '@ag-grid-community/react';

export const IdCell = ({
  value: paymentId,
}: CustomCellRendererProps<PaymentRecord, string>) => {
  if (!paymentId) {
    return null;
  }

  return <CopyChip value={paymentId} />;
};
