import type * as Yup from 'yup';
import type {RemoveIndex} from '../../../../@types/custom';
import type {billingPlanSchema} from '../upgrade-billing-plan-schema';

export type UpgradeBillingPlanFormDataType = RemoveIndex<
  Yup.InferType<typeof billingPlanSchema>
>;

export enum ChangeBillingPlanSteps {
  BILLING_PLAN_SELECT = 0,
  BILLING_PLAN_PAYMENT_FORM = 1,
}
