import type {MerchantScopes} from '@local/backend/@types/scopes/MerchantScopes';
import {useMerchantPermissions} from '../libs/trpc/trpc';
import {verifyMerchantScopes} from '../utils/verifyMerchantScopes';

const useCanAccess = (requiredPermissions: MerchantScopes[]): boolean => {
  const {data: userPermissions} = useMerchantPermissions();

  if (!userPermissions) {
    throw new Error(
      'useCanAccess requires the PrivateMerchantRoute provider to be wrapped around it'
    );
  }

  return verifyMerchantScopes(requiredPermissions, userPermissions);
};

export default useCanAccess;
