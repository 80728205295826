import {CircularProgress} from '@mui/material';
import type {IconButtonProps} from '@mui/material/IconButton';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import type {FC, ReactNode} from 'react';
import React from 'react';

interface ActionIconButtonProps extends IconButtonProps {
  title: string;
  icon: ReactNode;
  loading?: boolean;
  href?: string;
}

const ActionIconButton: FC<React.PropsWithChildren<ActionIconButtonProps>> = ({
  title,
  icon,
  loading,
  ...props
}) => (
  <Tooltip title={title} arrow>
    <IconButton
      sx={{
        borderRadius: 1,
        backgroundColor: 'grey.200',
        fontSize: 20,
        '&:hover': {color: 'primary.main'},
      }}
      size="small"
      {...props}
    >
      {loading ? <CircularProgress size={20} /> : icon}
    </IconButton>
  </Tooltip>
);

export default ActionIconButton;
