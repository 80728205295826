import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';

export enum CancelOrUpdate {
  CANCEL = 'CANCEL',
  UPDATE = 'UPDATE',
}

export const checkWhetherToCancelOrUpdate = (
  gpr: GroupPaymentRecord
): CancelOrUpdate => {
  // check if any money has been paid into the group
  const hasApprovedMoney = gpr.approvedMoney && gpr.approvedMoney.amount > 0;

  // check if money has been approved
  if (hasApprovedMoney) {
    // if so we may cancel payments, so run onCancel callback
    return CancelOrUpdate.CANCEL;
  }
  // else no money has been paid into the group, therefore continue with update as normal
  return CancelOrUpdate.UPDATE;
};
