import type {FC} from 'react';
import React from 'react';
import {Drawer, useMediaQuery, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import NavContent from './NavContent';
import {BANNER_DESKTOP, NAV_WIDTH} from '../dashboard-config';

interface NavProps {
  onClose: () => void;
  open: boolean;
}

export const Nav: FC<React.PropsWithChildren<NavProps>> = ({onClose, open}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const top = isMobile ? 0 : BANNER_DESKTOP;

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: {lg: 0},
        width: {lg: NAV_WIDTH},
      }}
    >
      <Drawer
        anchor="left"
        open={isMobile ? open : true}
        onClose={isMobile ? onClose : undefined}
        variant={isMobile ? 'temporary' : 'permanent'}
        ModalProps={{
          keepMounted: isMobile,
        }}
        PaperProps={{
          sx: {
            top,
            height: `calc(100% - ${top}px)`,
            width: NAV_WIDTH,
            borderRight: isMobile ? 'thin' : 'none',
          },
        }}
        sx={{
          zIndex: theme.zIndex.drawer + 1,
        }}
      >
        <NavContent />
      </Drawer>
    </Box>
  );
};
