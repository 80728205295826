import type {FC} from 'react';
import React from 'react';
import Stack from '@mui/material/Stack';
import {useQueryClient} from '@tanstack/react-query';
import {getQueryKey} from '@trpc/react-query';
import Typography from '@mui/material/Typography';
import {useNotification} from '@local/frontend/hooks/useNotification';
import {useCustomModals} from '@local/frontend/libs/modals/useModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import {trpc} from '../../../libs/trpc/trpc';
import StripePaymentMethodForm from '../../../pages/user/onboarding/confirm/components/StripePaymentMethodForm';
import LoadingButton from '../../atoms/buttons/LoadingButton';

const useAddPaymentMethodMutation = trpc.billing.addPaymentMethod.useMutation;

const AddPaymentMethodModal: FC<React.PropsWithChildren<unknown>> = () => {
  const queryClient = useQueryClient();
  const {closeModal} = useCustomModals();
  const {open: openNotification} = useNotification();

  const addCardMutation = useAddPaymentMethodMutation({
    onSuccess: async () => {
      openNotification({
        message: 'Card added successfully',
        severity: 'success',
      });
      await queryClient.invalidateQueries(
        getQueryKey(trpc.billing.getPaymentMethods)
      );
      closeModal(ModalName.ADD_PAYMENT_METHOD);
    },
    onError: err => {
      openNotification({
        message: err.message ?? 'Failed to add payment method',
        severity: 'error',
      });
    },
  });

  return (
    <StripePaymentMethodForm
      fullWidth
      onChange={paymentMethod => {
        if (paymentMethod) {
          addCardMutation.mutate({paymentMethodId: paymentMethod.id});
        }
      }}
      setError={message =>
        openNotification({
          message: message ?? 'Error adding card',
          severity: 'error',
        })
      }
      render={({CardElement, buttonProps: {loading, ...btnProps}}) => (
        <Stack direction="column" spacing={2}>
          <Typography variant="body2">Add your card details</Typography>
          <CardElement style={{width: '100%'}} />
          <LoadingButton
            {...btnProps}
            variant="outlined"
            loading={loading || addCardMutation.isLoading}
            disabled={btnProps.disabled || loading || addCardMutation.isLoading}
          >
            Add card
          </LoadingButton>
        </Stack>
      )}
    />
  );
};

export default AddPaymentMethodModal;
