import type {FC} from 'react';
import React from 'react';
import type {TextFieldProps} from '@mui/material';
import {Autocomplete, TextField, createFilterOptions} from '@mui/material';
import type {Currency} from '@handsin/money';
import {MoneyUtils} from '@handsin/money';
import type {CurrencyOption} from '@local/frontend/components/atoms/input/CurrencySelect/currency-options';
import options from '@local/frontend/components/atoms/input/CurrencySelect/currency-options';

export type CurrencyMultiSelectProps = TextFieldProps & {
  value: Currency[] | undefined;
  onChange: (newValue: Currency[] | undefined) => void;
};

const CurrencyMultiSelect: FC<
  React.PropsWithChildren<CurrencyMultiSelectProps>
> = React.forwardRef(({value, onChange, ...props}, ref) => (
  <Autocomplete
    ref={ref}
    multiple
    limitTags={4}
    disableCloseOnSelect
    value={value
      ?.map(val => options.find(option => option.code === val))
      .filter((option): option is CurrencyOption => !!option)}
    options={options.sort((a: CurrencyOption, b: CurrencyOption) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    )}
    getOptionLabel={option => {
      const currencySymbol = new MoneyUtils({
        amount: 0,
        currency: option.code,
      }).getCurrencySymbol();

      return `${option.code} (${currencySymbol})`;
    }}
    filterOptions={createFilterOptions<CurrencyOption>({
      ignoreCase: true,
      trim: true,
      matchFrom: 'any',
      stringify: (option: CurrencyOption) =>
        `${option.code.toLocaleLowerCase()} (${new MoneyUtils({
          amount: 0,
          currency: option.code,
        }).getCurrencySymbol()}) ${option.name}`,
    })}
    renderInput={params => <TextField {...params} {...props} />}
    isOptionEqualToValue={(a: CurrencyOption, b: CurrencyOption) =>
      a.code === b.code
    }
    onChange={(_e: React.SyntheticEvent, opts: CurrencyOption[]) => {
      onChange(opts.map(option => option.code));
    }}
  />
));

CurrencyMultiSelect.displayName = 'CurrencyMultiSelect';

export default CurrencyMultiSelect;
