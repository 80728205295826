import {Navigate, Outlet} from 'react-router-dom';
import React, {lazy} from 'react';
import NotificationProvider from '../../providers/NotificationProvider';
import PrivateUserRoute from '../../providers/PrivateUserRoute';
import {onboardingRoutes} from './onboarding/routes';

const SelectAccountPage = lazy(() => import('./SelectAccountPage'));

const JoinMerchantTeamPage = lazy(() => import('./JoinMerchantTeamPage'));

export const userRoutes = {
  path: '/user',
  element: (
    <PrivateUserRoute>
      <NotificationProvider>
        <Outlet />
      </NotificationProvider>
    </PrivateUserRoute>
  ),
  children: [
    {
      index: true,
      element: <Navigate to="select" replace />,
    },
    {
      path: 'select',
      element: <SelectAccountPage />,
    },
    {
      path: 'join',
      element: <JoinMerchantTeamPage />,
    },
    onboardingRoutes,
  ],
};
