import type {FC} from 'react';
import React, {useEffect, useState} from 'react';
import type {TextFieldProps} from '@mui/material/TextField';
import {Currency} from '@handsin/api-node/build/api';
import {Stack, TextField} from '@mui/material';
import {MoneyUtils} from '@handsin/money';
import CurrencySelect from './CurrencySelect/CurrencySelect';

export type MoneyTextFieldProps = {
  min?: number;
  max?: number;
  value?: number;
  onChange?: (value: number) => void;
  onCurrencyChange?: (value: Currency) => void;
  enableCurrencySelect?: boolean;
  currency?: Currency;
  locales?: string | string[];
} & Omit<TextFieldProps, 'value' | 'onChange'>;

const MoneyTextField: FC<React.PropsWithChildren<MoneyTextFieldProps>> =
  React.forwardRef(
    (
      {
        value: initValue = 0,
        min = 0,
        max,
        currency: defaultCurrency,
        onChange,
        onCurrencyChange,
        enableCurrencySelect,
        locales,
        ...props
      },
      ref
    ) => {
      const [fieldType, setFieldType] = useState<'text' | 'number'>('text');
      const [amount, setAmount] = useState<number | null>(min);
      const [currency, setCurrency] = useState(defaultCurrency ?? Currency.USD);

      const ensureAmountInBounds = (amountToValidate: number) => {
        if (amountToValidate < min) {
          return min;
        }
        if (max && amountToValidate > max) {
          return max;
        }
        return amountToValidate;
      };

      useEffect(() => {
        if (defaultCurrency) {
          setCurrency(defaultCurrency);
        }
      }, [defaultCurrency]);

      useEffect(() => {
        setAmount(ensureAmountInBounds(initValue));
      }, [initValue]);

      const handleAmountChange = (newAmount: number) => {
        if (onChange) {
          onChange(newAmount);
        }
        setAmount(newAmount);
      };

      const handleCurrencyChange = (newCurrency: Currency) => {
        if (onCurrencyChange) {
          onCurrencyChange(newCurrency);
        }
        setCurrency(newCurrency);
      };

      const getValue = (): string | number | null => {
        if (fieldType === 'text') {
          return MoneyUtils.formatMoney({amount: amount ?? 0, currency});
        }
        if (amount) {
          return Number(MoneyUtils.getMoneyAsFloat({amount, currency}));
        }
        return null;
      };

      return (
        <Stack direction="row" alignItems="center" spacing={1} width="100%">
          {(!defaultCurrency || enableCurrencySelect) && (
            <CurrencySelect
              label="Currency"
              required
              placeholder="currency"
              length="short"
              onChange={handleCurrencyChange}
              value={currency}
            />
          )}
          <TextField
            {...props}
            fullWidth
            ref={ref}
            value={getValue()}
            type={fieldType}
            onFocus={() => setFieldType('number')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.value === '') {
                setAmount(null);
              }
              handleAmountChange(
                ensureAmountInBounds(
                  MoneyUtils.convertFloatToMoney(event.target.value, currency)
                    .amount
                )
              );
            }}
            onBlur={() => {
              setFieldType('text');
            }}
          />
        </Stack>
      );
    }
  );

MoneyTextField.displayName = 'MoneyTextField';

export default MoneyTextField;
