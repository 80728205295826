import type {FC} from 'react';
import React from 'react';
import {Portal, Stack, Typography} from '@mui/material';
import {useGetPaymentGatewayRedirect} from '@local/frontend/libs/trpc/trpc';
import LoadingButton from '@local/frontend/components/atoms/buttons/LoadingButton';
import {useNotification} from '@local/frontend/hooks/useNotification';
import type {GatewayConfigurationProps} from './GatewayConfigurationProps';

export const OauthConfiguration: FC<
  React.PropsWithChildren<GatewayConfigurationProps>
> = ({actionAreaRef, paymentGateway}) => {
  const {open: openNotification} = useNotification();
  const createConnectionMutation = useGetPaymentGatewayRedirect({
    onSuccess: redirectUrl => {
      window.location.href = redirectUrl;
    },
    onError: err => {
      openNotification({
        message:
          err.data?.axiosError?.response?.data.detail ??
          `Failed to connect to ${paymentGateway}!`,
        severity: 'error',
      });
    },
  });

  const onCreateConnectionFormSubmit = () => {
    createConnectionMutation.mutate({
      gateway: paymentGateway,
    });
  };

  return (
    <form>
      <Stack direction="column">
        <Typography>
          By clicking the &quot;connect&quot; button below you will be
          redirected to {paymentGateway} and prompted to grant Hands In access
          to your account. Once completed you will be automatically redirected
          back to your dashboard.
        </Typography>
      </Stack>
      <Portal container={() => actionAreaRef}>
        <LoadingButton
          onClick={onCreateConnectionFormSubmit}
          loading={createConnectionMutation.isLoading}
          variant="outlined"
        >
          Connect
        </LoadingButton>
      </Portal>
    </form>
  );
};
