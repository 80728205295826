import type {FC} from 'react';
import React from 'react';

import {useQueryClient} from '@tanstack/react-query';
import {useRefundPaymentMutation} from '@local/frontend/libs/api/payments';
import {Controller, useForm, useWatch} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {PaymentStatus} from '@handsin/api-node';
import {usePayment} from '@local/frontend/libs/trpc/trpc';
import {
  getRefundIdempKey,
  refreshRefundIdempKey,
} from '@local/frontend/utils/idemp-key-handlers';
import type {Money} from '@handsin/money';
import {
  Currency,
  LocalMoney,
  LocalMoneyCalculator,
  MoneyUtils,
} from '@handsin/money';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {openNotification} from '@local/frontend/libs/notifications/notificationHelpers';
import * as Yup from 'yup';
import Alert from '@mui/material/Alert';
import type {PaymentRecord} from '@local/backend/@types/updated-api-types/payments/PaymentRecord';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import {useCustomModals} from '@local/frontend/libs/modals/useModals';
import MoneyTextField from '../../atoms/input/MoneyTextField';
import LoadingButton from '../../atoms/buttons/LoadingButton';
import LoadingModal from '../../atoms/LoadingModal';

const createRefundFormSchema = (maxRefundAmount: Money) =>
  Yup.object({
    refundAmount: Yup.object({
      amount: Yup.number()
        .positive('Amount must be positive')
        .max(
          maxRefundAmount.amount,
          `Maximum amount left to refund is ${MoneyUtils.formatMoney({
            amount: maxRefundAmount.amount,
            currency: maxRefundAmount.currency,
          })}`
        )
        .required('Refund Amount required'),
      currency: Yup.mixed<Currency>()
        .oneOf(Object.values(Currency))
        .required('Currency is required'),
    }),
    reason: Yup.string().max(1000, 'Reason is too long').optional(),
    shouldRefundFullAmount: Yup.boolean(),
  });

const RefundPaymentForm: FC<
  React.PropsWithChildren<{
    paymentRecord: PaymentRecord;
  }>
> = ({paymentRecord}) => {
  const queryClient = useQueryClient();
  const {closeModal} = useCustomModals();
  const refundPaymentMutation = useRefundPaymentMutation();

  const maxRefundAmount = paymentRecord.capturedMoney
    ? new LocalMoneyCalculator(paymentRecord.capturedMoney)
        .subtract(
          paymentRecord.refundedMoney ?? {
            amount: 0,
            currency: paymentRecord.totalMoney.currency,
          }
        )
        .calculate()
    : {amount: 0, currency: paymentRecord.totalMoney.currency};
  const refundFormSchema = createRefundFormSchema(maxRefundAmount);
  const {
    formState: {isValid, isSubmitting},
    ...formMethods
  } = useForm<Yup.InferType<typeof refundFormSchema>>({
    mode: 'all',
    resolver: yupResolver(refundFormSchema),
    defaultValues: {
      refundAmount: {amount: 0, currency: paymentRecord.totalMoney.currency},
      reason: '',
      shouldRefundFullAmount: false,
    },
  });

  const closeRefundModal = () => closeModal(ModalName.REFUND_PAYMENT);

  const handleRefundFormSubmit = async (
    formValues: Yup.InferType<typeof refundFormSchema>
  ) => {
    if (!formValues.refundAmount) {
      openNotification(queryClient, {
        message: 'Something went wrong, please try refreshing your page',
        severity: 'error',
      });
      throw new Error('No refund amount provided');
    }

    let idempotencyKey = getRefundIdempKey(paymentRecord.id);
    if (!idempotencyKey) {
      refreshRefundIdempKey(paymentRecord.id);
      idempotencyKey = localStorage.getItem(
        `refundIdempKey-${paymentRecord.id}`
      );
    }

    if (!idempotencyKey) {
      openNotification(queryClient, {
        message: 'Something went wrong, please try refreshing your page',
        severity: 'error',
      });
      throw new Error('failed to retrieve refund idemp key');
    }

    await refundPaymentMutation.mutateAsync(
      {
        paymentId: paymentRecord.id,
        idempotencyKey,
        amountMoney: formValues.refundAmount,
        reason: formValues.reason?.trim(),
      },
      {
        onSuccess: () => {
          closeRefundModal();
        },
        onError: err => {
          const errorMessage = err.data?.axiosError?.response?.data.detail;
          openNotification(queryClient, {
            message: errorMessage,
            severity: 'error',
          });
        },
      }
    );
  };

  const refundAmount = useWatch({
    control: formMethods.control,
    name: 'refundAmount',
  });

  const isFullRefund = useWatch({
    control: formMethods.control,
    name: 'shouldRefundFullAmount',
  });

  const handleRefundFullAmountToggle = (isChecked: boolean) => {
    // check if refund full amount check box is checked
    if (isChecked) {
      // set form field value to full remaining refund amount
      formMethods.setValue('refundAmount', maxRefundAmount, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  // button disabled validation
  const isRefundButtonDisabled =
    paymentRecord.status !== PaymentStatus.COMPLETED ||
    refundPaymentMutation.isLoading ||
    !isValid ||
    isSubmitting;

  if (
    paymentRecord.refundedMoney &&
    paymentRecord.refundedMoney.amount > 0 &&
    paymentRecord.capturedMoney &&
    paymentRecord.capturedMoney.amount > 0 &&
    new LocalMoney(paymentRecord.refundedMoney).gte(paymentRecord.capturedMoney)
  ) {
    return (
      <Alert
        severity="success"
        sx={{
          textAlign: 'center',
        }}
      >
        <Typography variant="subtitle2" sx={{margin: 0}}>
          This payment has been fully refunded.
        </Typography>
      </Alert>
    );
  }

  return (
    <form onSubmit={formMethods.handleSubmit(handleRefundFormSubmit)}>
      <Stack>
        <Alert color="info">
          Please enter an amount to refund. Refunds can take 5-10 days to appear
          on a customer&apos;s statement.
        </Alert>

        <Grid container spacing={1} mt={1}>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12} sm order={{xs: 1, sm: 0}}>
              <Controller
                name="refundAmount.amount"
                control={formMethods.control}
                render={({field, fieldState}) => (
                  <MoneyTextField
                    {...field}
                    currency={paymentRecord.amountMoney.currency}
                    required
                    label="Refund"
                    max={maxRefundAmount.amount}
                    fullWidth
                    disabled={isFullRefund || refundPaymentMutation.isLoading}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs="auto" order={{xs: 0, sm: 1}}>
              <FormControlLabel
                sx={{mt: 1}}
                label="Full Refund"
                control={
                  <Controller
                    name="shouldRefundFullAmount"
                    control={formMethods.control}
                    render={({field}) => (
                      <Checkbox
                        {...field}
                        disableRipple
                        checked={field.value}
                        onChange={(event, checked) => {
                          field.onChange(event);
                          handleRefundFullAmountToggle(checked);
                        }}
                        disabled={refundPaymentMutation.isLoading}
                      />
                    )}
                  />
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="reason"
              control={formMethods.control}
              render={({field, fieldState}) => (
                <TextField
                  {...field}
                  value={field.value}
                  multiline
                  minRows={2}
                  maxRows={3}
                  label="Reason"
                  placeholder="Add more details about this refund"
                  fullWidth
                  disabled={refundPaymentMutation.isLoading}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          {paymentRecord.refundedMoney &&
            paymentRecord.refundedMoney.amount > 0 &&
            paymentRecord.capturedMoney &&
            paymentRecord.capturedMoney.amount > 0 && (
              <Grid item xs={12}>
                <Typography variant="caption" mt={1}>
                  Payment has already been partially refunded{' '}
                  <b>
                    {`(${MoneyUtils.formatMoney(
                      paymentRecord.refundedMoney
                    )} out of 
                ${MoneyUtils.formatMoney(paymentRecord.capturedMoney)})`}
                  </b>
                </Typography>
              </Grid>
            )}
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12} sm={6} order={{xs: 1, sm: 0}}>
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={closeRefundModal}
                disabled={refundPaymentMutation.isLoading}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} order={{xs: 0, sm: 1}}>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                disabled={isRefundButtonDisabled}
                loading={refundPaymentMutation.isLoading}
                fullWidth
              >
                Refund {MoneyUtils.formatMoney(refundAmount)}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </form>
  );
};

export interface RefundPaymentModalProps {
  paymentId: string;
}

const RefundPaymentModal: FC<
  React.PropsWithChildren<RefundPaymentModalProps>
> = ({paymentId}) => {
  const {data: paymentRecord, isLoading: isPaymentLoading} = usePayment({
    paymentId,
  });

  if (isPaymentLoading) {
    return <LoadingModal />;
  }

  if (!paymentRecord) {
    return (
      <Alert variant="outlined" severity="error">
        Oops...something went wrong trying to find this payment
      </Alert>
    );
  }

  return <RefundPaymentForm paymentRecord={paymentRecord} />;
};

export default RefundPaymentModal;
