import type {FC} from 'react';
import React from 'react';
import type {DialogProps} from '@mui/material/Dialog';
import Dialog from '@mui/material/Dialog';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {DialogContent, Typography} from '@mui/material';
import {useCustomModals} from '@local/frontend/libs/modals/useModals';
import type {ModalName} from './ModalName';
import CustomDialogTitle from '../../components/atoms/CustomDialogTitle';

const GlobalModal: FC<
  React.PropsWithChildren<DialogProps & {name: ModalName}>
> = ({children, name, open, sx, title, ...props}) => {
  const {closeModal} = useCustomModals();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const onClose = () => closeModal(name);

  return (
    <Dialog
      open={open}
      fullScreen={isMobile}
      onClose={onClose}
      sx={{overflow: 'auto', ...sx}}
      {...props}
    >
      {/* !important added to prevent styling issues in mui v5
      issue relates to https://github.com/mui/material-ui/issues/31185 */}
      <CustomDialogTitle onClose={onClose} sx={{pb: '0px !important'}}>
        <Typography variant="h4" color="primary" align="center">
          {title}
        </Typography>
      </CustomDialogTitle>
      <DialogContent sx={{pt: '20px !important'}}>{children}</DialogContent>
    </Dialog>
  );
};

export default GlobalModal;
