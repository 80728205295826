import type {FC} from 'react';
import React from 'react';
import type {IconButtonProps} from '@mui/material';
import {Box, Grid, ListItem, Skeleton, Stack, Tooltip} from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import type {AccountDTO} from '@handsin/api-node';
import {Link as RouterLink} from 'react-router-dom';
import {useLogoutMutation} from '@local/frontend/libs/api/auth/logout';
import useCanAccess from '@local/frontend/hooks/useCanAccess';
import {MerchantScope} from '@local/backend/@types/scopes/MerchantScopes';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupsIcon from '@mui/icons-material/Groups';
import ReceiptIcon from '@mui/icons-material/Receipt';
import HomeIcon from '@mui/icons-material/Home';
import {UserAvatar} from '../../../components/UserAvatar';
import {formatFullName} from '../../../utils/formatters';
import StyledMenu from './StyledMenu';
import {useGetUser} from '../../../libs/trpc/trpc';

interface AccountIconProps extends IconButtonProps {
  account: AccountDTO;
}

const AccountIcon: FC<
  React.PropsWithChildren<React.PropsWithChildren<AccountIconProps>>
> = ({account, ...props}) => {
  const fullName = formatFullName(account.firstName, account.lastName);
  return (
    <Tooltip
      placement="bottom-start"
      title={
        <Stack>
          <Typography fontSize="0.8rem" fontWeight={600}>
            Hands In
          </Typography>
          <Typography fontSize="0.7rem">{fullName}</Typography>
          <Typography fontSize="0.7rem">{account.email}</Typography>
        </Stack>
      }
      arrow
    >
      <IconButton {...props}>
        <UserAvatar user={account} />
      </IconButton>
    </Tooltip>
  );
};

export const AccountPopover: FC<
  React.PropsWithChildren<React.PropsWithChildren<unknown>>
> = () => {
  const logoutMutation = useLogoutMutation();
  const {data: user, isInitialLoading: isUserLoading} = useGetUser();
  const canViewBillingPage = useCanAccess([MerchantScope.ALL]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onLogout = () => {
    logoutMutation.mutate();
  };
  const fullName = formatFullName(user?.firstName ?? '', user?.lastName);

  if (isUserLoading) {
    <Skeleton variant="circular" width={40} height={40} />;
  }

  return (
    <>
      {user && <AccountIcon account={user} onClick={handleClick} />}

      <StyledMenu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <ListItem divider disableGutters>
          <Box sx={{my: 1.5, px: 2.5}}>
            <Grid container spacing={2}>
              <Grid item xs>
                {user && <UserAvatar user={user} />}
              </Grid>
              <Grid item xs>
                <Typography variant="subtitle2" fontWeight={600} noWrap>
                  {fullName}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{color: 'text.secondary'}}
                  noWrap
                >
                  {user?.email}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </ListItem>

        <MenuItem component={RouterLink} to="/dashboard/home">
          <ListItemText>Home</ListItemText>
          <HomeIcon fontSize="small" />
        </MenuItem>
        {canViewBillingPage && (
          <MenuItem component={RouterLink} to="/dashboard/billing">
            <ListItemText>Billing</ListItemText>
            <ReceiptIcon fontSize="small" />
          </MenuItem>
        )}
        <MenuItem component={RouterLink} to="/dashboard/settings/team">
          <ListItemText>Your Team</ListItemText>
          <GroupsIcon fontSize="small" />
        </MenuItem>
        <MenuItem component={RouterLink} to="/dashboard/settings">
          <ListItemText>Settings</ListItemText>
          <SettingsIcon fontSize="small" />
        </MenuItem>

        <MenuItem onClick={onLogout}>
          <ListItemText>Logout</ListItemText>
          <LogoutIcon fontSize="small" />
        </MenuItem>
      </StyledMenu>
    </>
  );
};
