import type {RouteObject} from 'react-router-dom';
import {Navigate, Outlet} from 'react-router-dom';
import React, {lazy} from 'react';
import PrivateMerchantRoute from '../../providers/PrivateMerchantRoute';

const ReadmeConnectPage = lazy(() => import('./readme/ReadmeConnectPage'));

export const connectRoutes: RouteObject = {
  path: 'connect',
  element: (
    <PrivateMerchantRoute>
      <Outlet />
    </PrivateMerchantRoute>
  ),
  children: [
    {index: true, element: <Navigate to="/404" replace />},
    {
      path: 'readme',
      element: <ReadmeConnectPage />,
    },
  ],
};
