import Button from '@mui/material/Button';
import type {DialogProps} from '@mui/material/Dialog';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import type {FC} from 'react';
import React from 'react';
import type {FieldValues} from 'react-hook-form';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import NoticeAlert from '../atoms/NoticeAlert';

interface RemoveCustomerModalProps extends DialogProps {
  onClose: () => void;
  handleOnRemove: (decreaseGroupSize?: boolean) => void;
  customerBeingRemoved: FieldValues;
  groupPayment: GroupPaymentRecord;
}

const RemoveCustomerModal: FC<
  React.PropsWithChildren<RemoveCustomerModalProps>
> = ({groupPayment, handleOnRemove, customerBeingRemoved, ...props}) => {
  const canKickAndReduce =
    groupPayment.splitAllocation && groupPayment.splitAllocation - 1 >= 2;

  return (
    <Dialog {...props}>
      <DialogTitle id="alert-dialog-title">
        {`Are you sure you would like to remove ${customerBeingRemoved.firstName} from the group?`}
      </DialogTitle>
      {canKickAndReduce && (
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            variant="subtitle1"
            sx={{lineHeight: '1.25'}}
          >
            <NoticeAlert
              message="You can choose to either remove the user from the group or remove the user and also decrease the group size alongside the user being removed"
              severity="info"
            />
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button onClick={props.onClose}>Close</Button>
          </Grid>
          <Grid container item xs justifyContent="flex-end">
            <Button onClick={() => handleOnRemove()}>Remove User</Button>
            {canKickAndReduce && (
              <Button onClick={() => handleOnRemove(true)}>
                Remove & Decrease group size
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveCustomerModal;
