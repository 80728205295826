import type {FC} from 'react';
import React from 'react';
import Grid from '@mui/material/Grid';
import {Card, Typography} from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import List from '@mui/material/List';
import {PackagePlanResolver, HandsInPackagePlan} from '@hands-in/billing';
import LoadingButton from '@local/frontend/components/atoms/buttons/LoadingButton';

interface PricingPlanSelectorProps {
  currentPlan?: HandsInPackagePlan;
  loading?: boolean;
  variant: 'upgrading' | 'selecting';
  value: string | undefined;
  onChange: (pricingPlan: string | undefined) => void;
}

const PricingPlanSelector: FC<
  React.PropsWithChildren<PricingPlanSelectorProps>
> = ({currentPlan, variant, loading, onChange, value}) => (
  <Grid container spacing={5} alignItems="flex-end">
    {PackagePlanResolver.getPackagePlanIds()
      .filter(plan => plan !== 'CUSTOM')
      .map(billingPlanId => {
        const isSelected = billingPlanId === value;
        const isCurrentPlan =
          (billingPlanId === HandsInPackagePlan.FREE && !currentPlan) ||
          billingPlanId === currentPlan;
        const billingPlan = PackagePlanResolver.getPackageInfo(billingPlanId);
        const usagePlans = PackagePlanResolver.listUsagePlans(billingPlanId);
        const isFreePlan = billingPlanId === HandsInPackagePlan.FREE;

        return (
          <Grid item key={billingPlan.title} xs={12} md={4}>
            <Card>
              <CardHeader
                title={billingPlan.title}
                subheader={billingPlan.description}
                titleTypographyProps={{align: 'center'}}
                subheaderTypographyProps={{
                  align: 'center',
                }}
                sx={{
                  backgroundColor: theme =>
                    isFreePlan
                      ? theme.palette.warning.light
                      : theme.palette.grey[200],
                  pb: 2,
                }}
              />
              <CardContent>
                <List>
                  {usagePlans.map(usage => {
                    const usagePlan = usage.getUsagePlanInfo();

                    return (
                      <Typography
                        key={usagePlan.id}
                        component="li"
                        variant="subtitle1"
                        align="center"
                      >
                        {usagePlan.description}
                      </Typography>
                    );
                  })}
                  {isFreePlan && (
                    <Typography
                      component="li"
                      variant="subtitle2"
                      align="center"
                      color="grey.500"
                    >
                      No card required
                    </Typography>
                  )}
                  {billingPlan.features.map(feature => (
                    <Typography
                      key={feature.key}
                      component="li"
                      variant="subtitle2"
                      align="center"
                      color="grey.500"
                    >
                      {feature.value} {feature.key.toLowerCase()}
                    </Typography>
                  ))}
                </List>
              </CardContent>
              <CardActions sx={{pt: 0}}>
                <LoadingButton
                  onClick={() => {
                    onChange(billingPlan.id);
                  }}
                  fullWidth
                  variant={isSelected ? 'contained' : 'outlined'}
                  loading={loading}
                  disabled={loading || isCurrentPlan}
                >
                  {(() => {
                    if (variant === 'selecting') {
                      return isSelected ? 'Selected' : 'Select';
                    }

                    return isCurrentPlan
                      ? 'Currently active'
                      : `Switch to ${billingPlan.title}`;
                  })()}
                </LoadingButton>
              </CardActions>
            </Card>
          </Grid>
        );
      })}
  </Grid>
);

export default PricingPlanSelector;
