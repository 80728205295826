import type {FC} from 'react';
import React from 'react';
import DialogActions from '@mui/material/DialogActions';
import {useQueryClient} from '@tanstack/react-query';
import {getQueryKey} from '@trpc/react-query';
import Stack from '@mui/material/Stack';
import {useNotification} from '@local/frontend/hooks/useNotification';
import {useCustomModals} from '@local/frontend/libs/modals/useModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import type {MerchantDTO} from '@local/backend/@types/updated-api-types/merchants/Merchant';
import LoadingButton from '../../atoms/buttons/LoadingButton';
import {trpc} from '../../../libs/trpc/trpc';
import NoticeAlert from '../../atoms/NoticeAlert';
import CancelBillingPlanInfo from './CancelBillingPlanInfo';

const useCancelBillingPlanMutation = trpc.billing.cancelBillingPlan.useMutation;

export interface CancelBillingPlanModalProps {
  merchant: MerchantDTO;
}

const CancelBillingPlanModal: FC<
  React.PropsWithChildren<CancelBillingPlanModalProps>
> = ({merchant}) => {
  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();
  const {closeModal} = useCustomModals();

  const cancelBillingPlan = useCancelBillingPlanMutation({
    onSuccess: async () => {
      openNotification({
        message: 'Payment plan cancelled successfully',
        severity: 'success',
      });

      await queryClient.invalidateQueries(getQueryKey(trpc.merchants));

      closeModal(ModalName.CANCEL_BILLING_PLAN);
    },
    onError: () => {
      openNotification({
        message:
          'Failed to cancel Payment plan. Please contact support, if the problem persists',
        severity: 'error',
      });
    },
  });

  const handleOnCancel = () => {
    if (!merchant) {
      openNotification({
        message: 'No merchant found. Could not cancel payment plan',
        severity: 'error',
      });
      return;
    }

    // cannot cancel a free plan
    if (!merchant.billingPlanId) {
      openNotification({
        message: 'No payment plan found. Could not cancel payment plan',
        severity: 'error',
      });
      return;
    }

    cancelBillingPlan.mutate({billingPlanId: merchant.billingPlanId});
  };

  return (
    <Stack>
      <NoticeAlert
        message="Please confirm you acknowledge the following:"
        severity="warning"
        sx={{fontWeight: 600}}
      >
        <CancelBillingPlanInfo />
      </NoticeAlert>
      <DialogActions>
        <LoadingButton
          variant="outlined"
          color="error"
          onClick={handleOnCancel}
          loading={cancelBillingPlan.isLoading}
          disabled={cancelBillingPlan.isLoading}
        >
          Yes, cancel my plan
        </LoadingButton>
        <LoadingButton
          variant="contained"
          onClick={() => closeModal(ModalName.CANCEL_BILLING_PLAN)}
          disabled={cancelBillingPlan.isLoading}
        >
          No, keep my plan
        </LoadingButton>
      </DialogActions>
    </Stack>
  );
};

export default CancelBillingPlanModal;
