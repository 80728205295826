export const NAV_WIDTH = 300;

export const BANNER_MOBILE = 25;
export const BANNER_DESKTOP = 25;

export const FOOTER_MOBILE = 25;
export const FOOTER_DESKTOP = 30;

export const APP_BAR_MOBILE = 60;
export const APP_BAR_DESKTOP = 70;
