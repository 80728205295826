import LoadingButton from '@local/frontend/components/atoms/buttons/LoadingButton';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import type {FC} from 'react';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import SendIcon from '@mui/icons-material/Send';
import type {AutocompleteRenderInputParams} from '@mui/material/Autocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  trpc,
  useMerchantTeamInviteMutation,
} from '@local/frontend/libs/trpc/trpc';
import {useNotification} from '@local/frontend/hooks/useNotification';
import {getQueryKey} from '@trpc/react-query';
import {useQueryClient} from '@tanstack/react-query';
import {MerchantTeamRoles} from '@local/backend/@types/updated-api-types/merchants/MerchantTeamRoles';
import * as Yup from 'yup';
import {emailValidation} from '@local/frontend/components/forms/schemas/custom-validation';
import {useCustomModals} from '@local/frontend/libs/modals/useModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';

interface InviteNewTeamMemberFormValues {
  email: string;
  role: MerchantTeamRoles;
}

const inviteNewTeamMemberSchema = Yup.object({
  email: emailValidation().required('Email is required'),
  role: Yup.mixed<MerchantTeamRoles>()
    .oneOf(Object.values(MerchantTeamRoles))
    .required('A role is required'),
});

const InviteNewTeamMemberModal: FC<React.PropsWithChildren<unknown>> = () => {
  const queryClient = useQueryClient();
  const {closeModal} = useCustomModals();
  const {open: openNotification} = useNotification();
  const {
    control,
    handleSubmit,
    formState: {isValid, isSubmitting},
  } = useForm<InviteNewTeamMemberFormValues>({
    mode: 'all',
    resolver: yupResolver(inviteNewTeamMemberSchema),
    defaultValues: {
      email: '',
      role: MerchantTeamRoles.STAFF,
    },
  });

  const merchantTeamInviteMutation = useMerchantTeamInviteMutation({
    onSuccess: async (_merchant, {role, email}) => {
      await queryClient.invalidateQueries(getQueryKey(trpc.merchants));

      openNotification({
        message: `Invited ${email} to the team as a ${role}`,
        severity: 'success',
      });

      closeModal(ModalName.INVITE_NEW_TEAM_MEMBER);
    },
    onError: err => {
      const errorMessage =
        err?.data?.axiosError?.response?.data?.detail ??
        'Failed to invite user. Please contact support, if the problem persists';

      openNotification({
        message: errorMessage,
        severity: 'error',
      });
    },
  });

  const handleInviteTeamMember = ({
    email,
    role,
  }: InviteNewTeamMemberFormValues) => {
    merchantTeamInviteMutation.mutate({email, role});
  };

  const isInviting = merchantTeamInviteMutation.isLoading || isSubmitting;

  return (
    <form onSubmit={handleSubmit(handleInviteTeamMember)}>
      <Grid container spacing={2} justifyContent="center">
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={8}>
            <Controller
              name="email"
              control={control}
              render={({field}) => (
                <TextField
                  {...field}
                  variant="filled"
                  id="email"
                  label="Email"
                  required
                  placeholder="Email"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="role"
              control={control}
              render={({field}) => (
                <Autocomplete
                  {...field}
                  autoComplete
                  options={[
                    MerchantTeamRoles.ADMIN,
                    MerchantTeamRoles.DEVELOPER,
                    MerchantTeamRoles.STAFF,
                  ]}
                  renderInput={(params: AutocompleteRenderInputParams) => (
                    <TextField {...params} />
                  )}
                  onChange={(_e, value) => {
                    field.onChange(value); // Manually trigger the field's onChange event
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{bgcolor: '#222', py: 2, px: 6}}
            endIcon={isInviting ? null : <SendIcon />}
            disabled={!isValid || isInviting}
            loading={isInviting}
          >
            Send Invite
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default InviteNewTeamMemberModal;
