import type {CheckoutsScope} from './CheckoutsScopes';
import type {CustomersScope} from './CustomersScopes';
import type {DeveloperScope} from './DeveloperScopes';
import type {GroupPaymentsScope} from './GroupPaymentsScopes';
import type {ItemsScope} from './ItemsScopes';
import type {MultiCardPaymentsScope} from './MultiCardPaymentsScopes';
import type {PaymentsScope} from './PaymentsScopes';
import type {RefundsScope} from './RefundsScopes';

// used to define all possible scopes for merchant endpoints.
export type MerchantScopes =
  | MerchantScope
  | DeveloperScope
  | ItemsScope
  | CheckoutsScope
  | PaymentsScope
  | GroupPaymentsScope
  | MultiCardPaymentsScope
  | RefundsScope
  | CustomersScope;

export enum MerchantScope {
  ALL = 'merchants',
  CREATE = 'merchants.create',
  READ = 'merchants.read',
  UPDATE = 'merchants.update',
  DELETE = 'merchants.delete',
}
