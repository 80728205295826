import React from 'react';
import ElectricalServicesTwoToneIcon from '@mui/icons-material/ElectricalServicesTwoTone';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import CodeTwoToneIcon from '@mui/icons-material/CodeTwoTone';
import {FaLink} from 'react-icons/fa';
import GroupsIcon from '@mui/icons-material/Groups';
import type {MerchantScopes} from '@local/backend/@types/scopes/MerchantScopes';
import {MerchantScope} from '@local/backend/@types/scopes/MerchantScopes';
import {DeveloperScope} from '@local/backend/@types/scopes/DeveloperScopes';
import {CheckoutsScope} from '@local/backend/@types/scopes/CheckoutsScopes';
import {GroupPaymentsScope} from '@local/backend/@types/scopes/GroupPaymentsScopes';
import {MultiCardPaymentsScope} from '@local/backend/@types/scopes/MultiCardPaymentsScopes';
import {PaymentsScope} from '@local/backend/@types/scopes/PaymentsScopes';
import PaymentIcon from '@mui/icons-material/Payment';
import PaymentsIcon from '@mui/icons-material/Payments';
import {CustomersScope} from '@local/backend/@types/scopes/CustomersScopes';
import Face2Icon from '@mui/icons-material/Face2';
// import {ItemsScope} from '@local/backend/@types/scopes/ItemsScopes';
// import InventoryIcon from '@mui/icons-material/Inventory';

export interface PageConfig {
  id: string;
  name: string;
  path: string;
  icon?: JSX.Element;
  subpages?: PageConfig[];
  permissions?: MerchantScopes[];
}

const config: PageConfig[] = [
  {
    id: 'home',
    name: 'Home',
    path: '/dashboard/home',
    icon: <DashboardTwoToneIcon color="inherit" />,
  },
  {
    id: 'connect-gateway',
    name: 'Payment Gateways',
    path: '/dashboard/connect',
    icon: <ElectricalServicesTwoToneIcon color="inherit" />,
    permissions: [MerchantScope.UPDATE],
    subpages: [
      {
        id: 'installed-gateways',
        name: 'Connected',
        path: '/dashboard/connect/installed',
        permissions: [MerchantScope.UPDATE],
      },
      {
        id: 'gateway-catalogue',
        name: 'Catalogue',
        path: '/dashboard/connect/catalogue',
        permissions: [MerchantScope.UPDATE],
      },
      {
        id: 'archived-gateways',
        name: 'Archived',
        path: '/dashboard/connect/archived',
        permissions: [MerchantScope.UPDATE],
      },
    ],
  },
  {
    id: 'customers',
    name: 'Customers',
    path: '/dashboard/customers',
    permissions: [CustomersScope.READ],
    icon: <Face2Icon color="inherit" />,
  },
  // {
  //   id: 'products',
  //   name: 'Products',
  //   path: '/dashboard/products',
  //   permissions: [ItemsScope.READ],
  //   icon: <InventoryIcon color="inherit" />,
  //   subpages: [
  //     {
  //       id: 'products-view',
  //       name: 'View',
  //       path: '/dashboard/products',
  //       permissions: [ItemsScope.READ],
  //     },
  //     {
  //       id: 'products-create',
  //       name: 'Create',
  //       path: '/dashboard/products/create',
  //       permissions: [ItemsScope.CREATE],
  //     },
  //   ],
  // },
  {
    id: 'payments',
    name: 'Payments',
    path: '/dashboard/payments',
    permissions: [PaymentsScope.READ],
    icon: <PaymentIcon color="inherit" />,
  },
  {
    id: 'multi-card-payments',
    name: 'Multi Card Payments',
    path: '/dashboard/multi-cards',
    permissions: [MultiCardPaymentsScope.READ],
    icon: <PaymentsIcon color="inherit" />,
    subpages: [
      {
        id: 'multi-card-payment-view',
        name: 'View',
        path: '/dashboard/multi-cards',
        permissions: [MultiCardPaymentsScope.READ],
      },
      {
        id: 'multi-card-payment-create',
        name: 'Create',
        path: '/dashboard/multi-cards/create',
        permissions: [MultiCardPaymentsScope.CREATE],
      },
    ],
  },
  {
    id: 'group-payments',
    name: 'Group Payments',
    path: '/dashboard/group-payments',
    permissions: [GroupPaymentsScope.READ],
    icon: <GroupsIcon color="inherit" />,
    subpages: [
      {
        id: 'group-payment-view',
        name: 'View',
        path: '/dashboard/group-payments',
        permissions: [GroupPaymentsScope.READ],
      },
      {
        id: 'group-payment-create',
        name: 'Create',
        path: '/dashboard/group-payments/create',
        permissions: [GroupPaymentsScope.CREATE],
      },
    ],
  },
  {
    id: 'payment-links',
    name: 'Payment Links',
    path: '/dashboard/payment-links',
    permissions: [CheckoutsScope.CREATE],
    icon: <FaLink color="inherit" />,
    subpages: [
      {
        id: 'payment-links-view',
        name: 'View',
        path: '/dashboard/payment-links',
        permissions: [CheckoutsScope.READ],
      },
      {
        id: 'payment-links-create',
        name: 'Create',
        path: '/dashboard/payment-links/create',
        permissions: [CheckoutsScope.CREATE],
      },
    ],
  },
  {
    id: 'developers',
    name: 'Developers',
    path: '/dashboard/developers',
    permissions: [DeveloperScope.ALL],
    icon: <CodeTwoToneIcon fontSize="small" color="inherit" />,
    subpages: [
      {
        id: 'developers-api-keys',
        name: 'API Keys',
        path: '/dashboard/developers/keys',
      },
      {
        id: 'webhooks',
        name: 'Webhooks',
        path: '/dashboard/developers/webhooks',
      },
      {
        id: 'events',
        name: 'Events',
        path: '/dashboard/developers/events',
      },
    ],
  },
];

export default config;
