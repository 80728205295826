import {useQueryClient} from '@tanstack/react-query';
import type {PopperProps} from '@mui/material';
import {
  Autocomplete,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  InputAdornment,
  InputBase,
  Popper,
  Stack,
  autocompleteClasses,
  styled,
} from '@mui/material';
import type {FC} from 'react';
import React, {useState} from 'react';
import {useLoginMutation} from '@local/frontend/libs/api/auth/login';
import {useNotification} from '@local/frontend/hooks/useNotification';
import SearchIcon from '@mui/icons-material/Search';
import {Link} from 'react-router-dom';
import {
  MerchantSelectButton,
  MerchantSelectButtonLoading,
} from './MerchantSelectButton';
import {useMerchantAccounts, useMerchant} from '../../../../libs/trpc/trpc';
import MerchantOption from './MerchantOption';

const StyledPopper = styled(Popper)(({theme}) => ({
  border: `1px solid ${theme.palette.grey[50]}`,
  boxShadow: theme.shadows[6],
  borderRadius: 6,
  width: '100%',
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.grey[700],
  backgroundColor: theme.palette.grey[50],
}));

const StyledInput = styled(InputBase)(({theme}) => ({
  padding: 5,
  borderRadius: 4,
  backgroundColor: theme.palette.primary.lighter,
  '& input': {
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontSize: 12,
  },
}));

const StyledAutocompletePopper = styled('div')(({theme}) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    color: 'inherit',
    fontSize: 13,
    borderRadius: 0,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      marginTop: 4,
      borderRadius: 4,
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 0,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.getContrastText(theme.palette.primary.dark),
      },
      '&[aria-selected="true"]': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.getContrastText(theme.palette.primary.dark),
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

const PopperComponent = (props: PopperProps) => {
  const {disablePortal, anchorEl, open, ...other} = props;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return <StyledAutocompletePopper {...other} />;
};

const MerchantSelect: FC<React.PropsWithChildren<unknown>> = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();

  const loginMutation = useLoginMutation();
  const {isInitialLoading: isMerchantLoading, data: merchant} = useMerchant();
  const {isInitialLoading: isMerchantAccountsLoading, data: merchantAccounts} =
    useMerchantAccounts();

  if (loginMutation.isLoading || isMerchantLoading || !merchant) {
    return <MerchantSelectButtonLoading />;
  }

  return (
    <>
      {/* button */}
      <MerchantSelectButton merchant={merchant} onClick={handleClick} />

      {/* menu */}
      <StyledPopper
        sx={{width: 220}}
        aria-label="merchant-select"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
      >
        <ClickAwayListener onClickAway={handleClose} touchEvent={false}>
          <div>
            <Stack direction="column" sx={{p: 1}} spacing={1}>
              <Button
                size="small"
                component={Link}
                to="/user/onboarding"
                sx={{fontSize: 14}}
              >
                Create account
              </Button>
              <Divider variant="middle">OR SWITCH TO</Divider>
              <Autocomplete
                open={open}
                disablePortal
                blurOnSelect
                disableClearable
                value={merchant}
                loading={isMerchantAccountsLoading}
                options={merchantAccounts ?? []}
                getOptionLabel={targetMerchant => targetMerchant.name}
                onChange={(_e, targetMerchant) => {
                  if (targetMerchant.id !== merchant.id) {
                    loginMutation.mutate(targetMerchant.id, {
                      onSuccess: async () => {
                        await queryClient.invalidateQueries();
                      },
                      onError: () => {
                        openNotification({
                          message: 'Failed to switch merchant',
                          severity: 'error',
                        });
                      },
                    });
                  }
                  handleClose();
                }}
                PopperComponent={PopperComponent}
                isOptionEqualToValue={(a, b) => a.id === b.id}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                    key={option.id}
                    sx={{width: '100%'}}
                  >
                    <MerchantOption merchant={option} {...props} />
                  </Box>
                )}
                renderInput={params => (
                  <StyledInput
                    {...params}
                    ref={params.InputProps.ref}
                    autoFocus
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon fontSize="inherit" />
                      </InputAdornment>
                    }
                  />
                )}
              />
            </Stack>
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
};

export default MerchantSelect;
