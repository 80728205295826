import {useQueryClient} from '@tanstack/react-query';
import type {NotifcationQueryParams} from '../libs/notifications/notification-types';
import {
  closeNotification,
  openNotification,
} from '../libs/notifications/notificationHelpers';

export const useNotification = () => {
  const queryClient = useQueryClient();

  return {
    open: (notificationParams: NotifcationQueryParams) =>
      openNotification(queryClient, notificationParams),
    close: () => closeNotification(queryClient),
  };
};
