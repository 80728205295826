import Grid from '@mui/material/Grid';
import type {FC} from 'react';
import React, {useState} from 'react';
import type {FieldValues} from 'react-hook-form';
import {FormProvider, useForm} from 'react-hook-form';
import {useQueryClient} from '@tanstack/react-query';
import {yupResolver} from '@hookform/resolvers/yup';
import {Country, type Customer} from '@handsin/api-node';
import {Alert} from '@mui/material';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {useUpdateCustomerMutation} from '@local/frontend/libs/api/customers';
import {
  useKickFromGroupPaymentMutation,
  useRemoveFromGroupPayment,
  useUpdateGroupPaymentMutation,
} from '@local/frontend/libs/api/group-payments';
import {trpc, useCustomer} from '@local/frontend/libs/trpc/trpc';
import {getQueryKey} from '@trpc/react-query';
import {useNotification} from '@local/frontend/hooks/useNotification';
import {useCustomModals} from '@local/frontend/libs/modals/useModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import UpdateCustomerForm from '../../forms/UpdateCustomerForm';
import {addNewCustomerValidationSchema} from '../../forms/schemas';
import {formatFullName} from '../../../utils/formatters';
import {
  CancelOrUpdate,
  checkWhetherToCancelOrUpdate,
} from '../../../utils/calculation-helpers';
import CustomConfirmationModal from '../../atoms/CustomConfirmationModal';
import RemoveCustomerModal from '../RemoveCustomerModal';
import UpdateCustomerActions from './UpdateCustomerActions';
import Loading from '../../atoms/Loading';

interface UpdateCustomerConfirmationParams {
  groupPayment: GroupPaymentRecord;
  customer: Customer;
  shouldDecreaseGroupSize: boolean;
}

export interface UpdateCustomerModalProps {
  groupPayment: GroupPaymentRecord;
  customerId: string;
}

const UpdateCustomerModal: FC<
  React.PropsWithChildren<UpdateCustomerModalProps>
> = ({groupPayment, customerId}) => {
  const queryClient = useQueryClient();
  const {closeModal} = useCustomModals();
  const {open: openNotification} = useNotification();
  const updateCustomerMutation = useUpdateCustomerMutation();
  const [isEditing, setIsEditing] = useState(false);
  const [
    updateCustomerConfirmationParams,
    setUpdateCustomerConfirmationParams,
  ] = useState<UpdateCustomerConfirmationParams | undefined>(undefined);
  const [showCancelPaymentsDialog, setShowCancelPaymentsDialog] =
    useState(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);

  const {data: customer, isInitialLoading: isCustomerLoading} = useCustomer({
    customerId,
  });

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(addNewCustomerValidationSchema),
    defaultValues: {
      firstName: customer?.firstName ?? '',
      lastName: customer?.lastName,
      email:
        customer?.email === 'tech@handsin.co.uk' ? '' : customer?.email ?? '',
      phoneNumber: customer?.phoneNumber,
      phoneNumberCountry: customer?.address?.country ?? Country.GB,
    },
  });

  const updateGroupSizeMutation = useUpdateGroupPaymentMutation();

  const kickFromGroupPaymentMutation = useKickFromGroupPaymentMutation({
    onMutate: () => {
      // close cancelPayments dialog if it was open
      if (showCancelPaymentsDialog) {
        setShowCancelPaymentsDialog(false);
      }

      // close modal after a customer has been removed as the merchant should no longer be able to update the customer.
      closeModal(ModalName.UPDATE_CUSTOMER);
    },
    onSuccess: async gpr => {
      const kickee = updateCustomerConfirmationParams?.customer;
      // show notification to indicate remove was successful
      openNotification({
        message: kickee
          ? `${formatFullName(
              kickee.firstName,
              kickee?.lastName
            )} was removed from the group`
          : 'Successfully removed from group',
        severity: 'success',
      });

      // check if we should also reduce the group size when removing the owner
      if (
        updateCustomerConfirmationParams?.shouldDecreaseGroupSize &&
        gpr.splitAllocation
      ) {
        const newGroupSize = gpr.splitAllocation - 1;
        await updateGroupSizeMutation.mutateAsync({
          groupPaymentId: gpr.id,
          params: {
            splitType: gpr.splitType,
            splitAllocation: newGroupSize,
          },
        });
      }
    },
  });

  const removeFromGroupPaymentMutation = useRemoveFromGroupPayment({
    onMutate: () => {
      // close cancelPayments dialog if it was open
      if (showCancelPaymentsDialog) {
        setShowCancelPaymentsDialog(false);
      }
      // close modal after a customer has been removed as the merchant should no longer be able to update the customer.
      closeModal(ModalName.UPDATE_CUSTOMER);
    },
    onSuccess: async (gpr, {shouldDecreaseGroupSize}) => {
      const kickee = updateCustomerConfirmationParams?.customer;
      openNotification({
        message: kickee
          ? `${formatFullName(
              kickee.firstName,
              kickee?.lastName
            )} was removed from the group`
          : 'Successfully removed from group',
        severity: 'success',
      });
      // check if we should also reduce the group size when removing the owner
      if (shouldDecreaseGroupSize && gpr.splitAllocation) {
        const newGroupSize = gpr.splitAllocation - 1;
        await updateGroupSizeMutation.mutateAsync({
          groupPaymentId: gpr.id,
          params: {
            splitType: groupPayment.splitType,
            splitAllocation: newGroupSize,
          },
        });
      }
    },
  });

  const handleOnDiscard = (originalCustomerData: Customer) => {
    // display edit button
    setIsEditing(false);
    // reset the form back to its original state including its default values
    methods.reset({
      firstName: originalCustomerData.firstName,
      lastName: originalCustomerData.lastName,
      email:
        originalCustomerData.email === 'tech@handsin.co.uk'
          ? ''
          : originalCustomerData.email,
      phoneNumber: originalCustomerData.phoneNumber,
    });
  };

  const handleRemoveUser = (
    latestGpr: GroupPaymentRecord,
    kickee: Customer,
    shouldDecreaseGroupSize: boolean
  ) => {
    // you shouldnt be able to do this anyway on the front end but just in case
    if (
      latestGpr.memberIds.includes(kickee.id) &&
      latestGpr.memberIds.length === 1
    ) {
      // show notification to inform merchant that they can't remove the last user. (this would cancel the group)
      openNotification({
        message: 'Not allowed to remove the last joined user in a group',
        severity: 'error',
      });
      // exit out of function
      return;
    }
    // check if the member being kicked is the owner
    if (kickee.id === latestGpr.ownerId) {
      // This will make the user (owner) leave the group. since the owner cannot kick themselves
      removeFromGroupPaymentMutation.mutate({
        groupPaymentId: latestGpr.id,
        customerId: kickee.id,
        shouldDecreaseGroupSize,
      });

      return;
    }

    // check if the member we are kicking is in the group
    if (latestGpr.memberIds.includes(kickee.id)) {
      kickFromGroupPaymentMutation.mutate({
        groupPaymentId: latestGpr.id,
        ownerId: latestGpr.ownerId,
        kickId: kickee.id,
      });

      return;
    }

    // check if we are kicking a non joined user (not in memberIds, but is in invited)
    if (
      !latestGpr.memberIds.includes(kickee.id) &&
      latestGpr.invited &&
      latestGpr.invited.includes(kickee.id)
    ) {
      removeFromGroupPaymentMutation.mutate({
        groupPaymentId: groupPayment.id,
        customerId: kickee.id,
        shouldDecreaseGroupSize,
      });
    }
  };

  const handleOnRemoveClick = (
    kickee: Customer,
    gpr: GroupPaymentRecord,
    shouldDecreaseGroupSize: boolean
  ) => {
    // check if the gpr has already been approved or completed
    if (gpr.status !== 'PENDING') {
      openNotification({
        message: 'Cannot remove a customer once the group has terminated',
        severity: 'error',
      });
      return;
    }

    if (!gpr.splitAllocation) {
      openNotification({
        message:
          'Cannot remove customer. Could not determine current group size',
        severity: 'error',
      });
      return;
    }

    if (shouldDecreaseGroupSize) {
      // check whether this update would warrant cancelling payments, completing the group or neither.
      const cancelOrUpdateStatus = checkWhetherToCancelOrUpdate(gpr);

      if (cancelOrUpdateStatus === CancelOrUpdate.CANCEL) {
        // show extra dialog for user confirmation that the kick and reduce group size with cancel all payments
        setShowCancelPaymentsDialog(true);
        setUpdateCustomerConfirmationParams({
          groupPayment: gpr,
          customer: kickee,
          shouldDecreaseGroupSize,
        });
      }

      if (cancelOrUpdateStatus === CancelOrUpdate.UPDATE) {
        // Not necessary to cancel payments. so just continue with removing user from the group & decreasing group size
        handleRemoveUser(gpr, kickee, shouldDecreaseGroupSize);
      }
    } else {
      handleRemoveUser(gpr, kickee, shouldDecreaseGroupSize);
    }
  };

  const onSubmit = async (
    customerUpdateData: FieldValues,
    gpr: GroupPaymentRecord
  ) => {
    // check if the group payment is in a terminal state
    if (gpr.status !== 'PENDING') {
      openNotification({
        message: `Cannot update customer when the group payment status is ${gpr.status.toLowerCase()}`,
        severity: 'error',
      });
      // ensure data is up to date after showing the error above
      await queryClient.invalidateQueries(getQueryKey(trpc.groupPayments));

      return;
    }

    // get all customerIds in the group
    const allGroupCustomerIds = groupPayment.invited
      ? groupPayment.memberIds.concat(groupPayment.invited)
      : groupPayment.memberIds;

    // check the customer we are trying to edit is still apart of the group
    if (!allGroupCustomerIds.includes(customerId)) {
      openNotification({
        message:
          'Cannot edit customer. This customer is no longer in the group',
        severity: 'error',
      });
      // ensure data is up to date after showing the error above
      await queryClient.invalidateQueries(getQueryKey(trpc.groupPayments));

      // exit out of function
      return;
    }

    // update the customer
    updateCustomerMutation.mutate(
      {
        customerId,
        params: {
          ...customerUpdateData,
          email: customerUpdateData.email.toLowerCase(),
        },
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      }
    );
  };

  if (isCustomerLoading) {
    return <Loading />;
  }

  if (!customer) {
    return <Alert color="error">No customer found</Alert>;
  }

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(async values => {
            const {phoneNumberCountry, ...submitValues} = values;
            await onSubmit(submitValues, groupPayment).catch(err => {
              openNotification({severity: 'error', message: err.message});
            });
          })}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <UpdateCustomerForm isEditing={isEditing} />
            </Grid>
            <Grid item xs={12}>
              <UpdateCustomerActions
                openRemoveConfirmation={() => setShowRemoveDialog(true)}
                handleOnDiscard={() => handleOnDiscard(customer)}
                handleEditButtonClick={() => setIsEditing(true)}
                customer={customer}
                groupPayment={groupPayment}
                isEditing={isEditing}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>

      <RemoveCustomerModal
        open={showRemoveDialog}
        groupPayment={groupPayment}
        onClose={() => setShowRemoveDialog(false)}
        handleOnRemove={decreaseGroupSize => {
          handleOnRemoveClick(
            customer,
            groupPayment,
            decreaseGroupSize ?? false
          );
        }}
        customerBeingRemoved={methods.getValues()}
      />

      <CustomConfirmationModal
        title="Confirm cancellation of payments"
        open={showCancelPaymentsDialog}
        onClose={() => setShowCancelPaymentsDialog(false)}
        buttonActions={{reject: 'Go Back', accept: 'Yes, Cancel Payments'}}
        loading={
          updateCustomerMutation.isLoading ||
          removeFromGroupPaymentMutation.isLoading ||
          kickFromGroupPaymentMutation.isLoading
        }
        description="Money has been paid into the group.

       Updating the group size could potentially change share amounts and some of the members may need
       to re-approve their new share prices.

       In some cases if the group size gets reduced to the current number of members and all existing members have made a payment, it could also complete the group.

       Please confirm you would like to continue?"
        onConfirm={() => {
          if (!updateCustomerConfirmationParams) {
            openNotification({
              message: 'Something went wrong, failed to update customer.',
              severity: 'error',
            });

            throw new Error(
              'Failed to update customer. Confirmation params are required'
            );
          }

          handleRemoveUser(
            updateCustomerConfirmationParams.groupPayment,
            updateCustomerConfirmationParams.customer,
            updateCustomerConfirmationParams.shouldDecreaseGroupSize
          );
        }}
      />
    </>
  );
};

export default UpdateCustomerModal;
