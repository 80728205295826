import type {Currency, Address} from '@handsin/api-node';
import dayjs from 'dayjs';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import type {Money} from '@handsin/money';
import {MoneyUtils} from '@handsin/money';
import {CurrencySymbol} from '../@types/iso/CurrencySymbol';
import {getCountryName} from './country';

export const formatAddress = (address: Address, delimiter = '\n'): string => {
  const parts: string[] = [];
  if (address.country) {
    parts.push(getCountryName(address.country));
  }
  if (address.addressLines) {
    if (address.addressLines.addressLine1) {
      parts.push(address.addressLines.addressLine1);
    }

    if (address.addressLines.addressLine2) {
      parts.push(address.addressLines.addressLine2);
    }

    if (address.addressLines.addressLine3) {
      parts.push(address.addressLines.addressLine3);
    }
  }

  if (address.city) {
    parts.push(address.city);
  }

  if (address.postalCode) {
    parts.push(address.postalCode);
  }

  return parts.join(delimiter);
};

export const getCurrencySymbol = (
  currency: Currency,
  locales?: string | string[]
): string =>
  CurrencySymbol[currency] ??
  Intl.NumberFormat(locales, {
    style: 'currency',
    currency: currency.toUpperCase(),
  })
    .formatToParts(0)
    .find(part => part.type === 'currency')?.value ??
  '';

export const formatFullName = (
  firstName: string,
  lastName?: string
): string => {
  let name;
  if (lastName) {
    name = `${firstName} ${lastName}`;
  } else {
    name = firstName;
  }
  return name.replace(/\b\w/g, word => word.toUpperCase());
};

export const formatDateTime = (date: Date): string =>
  dayjs(date).format('DD/MM/YYYY HH:mm:ss');

export const formatDate = (date: string): string =>
  dayjs(date).format('Do MMM YYYY');

export const formatStripeBillingDate = (timestamp: number) =>
  dayjs(timestamp * 1000).format('DD/MM/YYYY');

export function formatEmailDateTime(date: Date): string {
  return `${date.toLocaleDateString()}, ${date
    .toTimeString()
    .split(' ', 2)
    .join(' ')}`;
}

export const createGroupPaymentCustomerUrl = (
  gpr: Pick<GroupPaymentRecord, 'id' | 'merchantId' | 'url'>,
  customerId: string
): string => {
  const {host} = new URL(gpr.url);
  return `${host}/g/${gpr.id}/group-dashboard?mid=${gpr.merchantId}&cid=${customerId}`;
};

interface SmsVariables {
  ownerFirstName: string;
  ownerLastName?: string;
  merchantName: string;
  // 'invite' extra info = share amount (e.g. '£5.20')
  // 'reminder' extra info = time left (e.g. '10 minutes')
  extraInfo?: string;
  linkToJoin: string;
}

export function formatSmsText(variables: SmsVariables, smsType: string) {
  const {ownerFirstName, ownerLastName, merchantName, extraInfo, linkToJoin} =
    variables;
  // initialize permanent parts
  let startPart = '';
  switch (smsType) {
    case 'reminder':
      startPart = 'Payment Reminder! ';
      break;
    case 'invite':
      startPart = 'Hi! Join ';
      break;
    default:
      break;
  }
  const groupPaymentPart = ' group payment';
  const linkPart = `. To join, click here: ${linkToJoin}`;
  let charactersLeft = 160;
  charactersLeft -= (startPart + groupPaymentPart + linkPart).length;
  // initialize variable parts with min chars (initializes sms with min words/chars)
  let ownerNamePart = ownerFirstName[0];
  if (ownerLastName) {
    ownerNamePart += `.${ownerLastName[0]}`;
  }
  let merchantPart = '';
  let extraInfoPart = '';

  function checkNewSmsLength() {
    return (
      charactersLeft - (ownerNamePart + merchantPart + extraInfoPart).length >=
      0
    );
  }

  // try using owner first name
  let previousPartText = ownerNamePart;
  ownerNamePart = ownerFirstName;
  if (!checkNewSmsLength()) {
    ownerNamePart = previousPartText;
  }

  // try including merchant name
  previousPartText = merchantPart;
  merchantPart = ` for ${merchantName}`;
  if (!checkNewSmsLength()) {
    merchantPart = previousPartText;
  }

  // try including extra info
  if (extraInfo) {
    previousPartText = extraInfoPart;
    switch (smsType) {
      case 'reminder':
        extraInfoPart = `. ${extraInfo} left`;
        break;
      case 'invite':
        extraInfoPart = `. Your Share: ${extraInfo}`;
        break;
      default:
        break;
    }
    if (!checkNewSmsLength()) {
      extraInfoPart = previousPartText;
    }
  }

  // try including second name initial
  previousPartText = ownerNamePart;
  ownerNamePart = ownerFirstName;
  if (ownerLastName) {
    ownerNamePart += ` ${ownerLastName[0]}`;
  }
  if (!checkNewSmsLength()) {
    ownerNamePart = previousPartText;
  }

  // try using full name
  previousPartText = ownerNamePart;
  ownerNamePart = `${ownerFirstName} ${ownerLastName}`;
  if (!checkNewSmsLength()) {
    ownerNamePart = previousPartText;
  }

  return `${startPart}${ownerNamePart}${groupPaymentPart}${merchantPart}${extraInfoPart}${linkPart}`;
}

function isSmsLengthValid(
  currentText: string,
  textBeingAdded: string
): boolean {
  // 160 characters is the max for SMS
  return 160 - currentText.length - textBeingAdded.length >= 0;
}

export const formatMultiCardSmsReminder = (
  payUrl: string,
  merchantName: string,
  totalMoney: Money
) => {
  const startPart = 'Payment Reminder! ';
  const linkPart = `To pay, click here: ${payUrl}`;
  let smsText = `${startPart}${linkPart}`;

  const amountPart = `A payment for ${MoneyUtils.formatMoney(
    totalMoney
  )} is due`;

  // check if we can include the payment amount
  if (isSmsLengthValid(smsText, `${amountPart}. `)) {
    smsText = `${startPart}${amountPart}. ${linkPart}`;
  } else {
    return smsText;
  }

  const merchantPart = ` at ${merchantName}. `;

  if (isSmsLengthValid(smsText, merchantPart)) {
    return `${startPart}${amountPart}${merchantPart}${linkPart}`;
  }

  return smsText;
};
