import React from 'react';
import type {Currency} from '@handsin/money';
import type {PaymentRecord} from '@local/backend/@types/updated-api-types/payments/PaymentRecord';
import {CustomCellRendererProps} from '@ag-grid-community/react';
import PaymentStatusChip from '../../atoms/chips/PaymentStatusChip';

interface PaymentStatusCellProps
  extends CustomCellRendererProps<PaymentRecord, Currency> {
  linkSrc?: boolean;
}

export const PaymentStatusCell = ({data, linkSrc}: PaymentStatusCellProps) => {
  const paymentRecord = data;

  if (!paymentRecord) {
    return null;
  }

  return (
    <PaymentStatusChip
      sx={{fontWeight: 500}}
      paymentRecord={paymentRecord}
      linkSrc={linkSrc}
    />
  );
};
