import {MerchantScope} from '@local/backend/@types/scopes/MerchantScopes';
import IfHasPermissions from '@local/frontend/hocs/IfHasPermissions';
import React, {lazy} from 'react';

const BillingPage = lazy(() => import('./BillingPage'));

export const billingRoutes = {
  path: 'billing',
  children: [
    {
      index: true,
      element: (
        <IfHasPermissions
          thenRender={() => <BillingPage />}
          permissions={[MerchantScope.ALL]}
        />
      ),
    },
  ],
};
