import type {FC} from 'react';
import React, {Suspense} from 'react';
import {RouterProvider} from 'react-router-dom';
import {ErrorBoundary} from '@sentry/react';
import Loading from './components/atoms/Loading';
import {routes} from './pages/routes';

const Routes: FC<React.PropsWithChildren<unknown>> = () => (
  <ErrorBoundary fallback={<Loading />}>
    <Suspense fallback={<Loading />}>
      <RouterProvider router={routes} fallbackElement={<Loading />} />
    </Suspense>
  </ErrorBoundary>
);

export default Routes;
