export enum EventType {
  CHECKOUT_CREATED = 'CHECKOUT_CREATED',
  CHECKOUT_UPDATED = 'CHECKOUT_UPDATED',
  CHECKOUT_COMPLETED = 'CHECKOUT_COMPLETED',
  CHECKOUT_CANCELLED = 'CHECKOUT_CANCELLED',
  CHECKOUT_EXPIRED = 'CHECKOUT_EXPIRED',

  GATEWAY_CONNECTION_OBTAINED = 'GATEWAY_CONNECTION_OBTAINED',
  GATEWAY_CONNECTION_REVOKED = 'GATEWAY_CONNECTION_REVOKED',

  CUSTOMER_CREATED = 'CUSTOMER_CREATED',
  CUSTOMER_UPDATED = 'CUSTOMER_UPDATED',
  CUSTOMER_DELETED = 'CUSTOMER_DELETED',

  GROUP_PAYMENT_CREATED = 'GROUP_PAYMENT_CREATED',
  GROUP_PAYMENT_UPDATED = 'GROUP_PAYMENT_UPDATED',
  GROUP_PAYMENT_APPROVED = 'GROUP_PAYMENT_APPROVED',
  GROUP_PAYMENT_COMPLETED = 'GROUP_PAYMENT_COMPLETED',
  GROUP_PAYMENT_CANCELLED = 'GROUP_PAYMENT_CANCELLED',
  GROUP_PAYMENT_EXPIRED = 'GROUP_PAYMENT_EXPIRED',

  MULTI_CARD_CREATED = 'MULTI_CARD_CREATED',
  MULTI_CARD_UPDATED = 'MULTI_CARD_UPDATED',
  MULTI_CARD_APPROVED = 'MULTI_CARD_APPROVED',
  MULTI_CARD_COMPLETED = 'MULTI_CARD_COMPLETED',
  MULTI_CARD_CANCELLED = 'MULTI_CARD_CANCELLED',
  MULTI_CARD_EXPIRED = 'MULTI_CARD_EXPIRED',

  ITEM_CREATED = 'ITEM_CREATED',
  ITEM_UPDATED = 'ITEM_UPDATED',

  // a hands in user was invited to join the merchant team
  MERCHANT_TEAM_INVITE = 'MERCHANT_TEAM_INVITE',
  // a hands in user joined the merchant team and now has at least the base level of access
  MERCHANT_TEAM_JOIN = 'MERCHANT_TEAM_JOIN',
  // @TODO add webhook call when functionality implemented
  // the merchant team was update e.g. a member was given a different scope of permissions
  MERCHANT_TEAM_UPDATE = 'MERCHANT_TEAM_UPDATE',

  MERCHANT_API_KEY_CREATED = 'MERCHANT_API_KEY_CREATED',
  MERCHANT_API_KEY_DELETED = 'MERCHANT_API_KEY_DELETED',

  MERCHANT_UPDATED = 'MERCHANT_UPDATED',

  PAYMENT_CREATED = 'PAYMENT_CREATED',
  PAYMENT_UPDATED = 'PAYMENT_UPDATED',
  PAYMENT_APPROVED = 'PAYMENT_APPROVED',
  PAYMENT_COMPLETED = 'PAYMENT_COMPLETED',
  PAYMENT_CANCELLED = 'PAYMENT_CANCELLED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',

  REFUND_CREATED = 'REFUND_CREATED',
  REFUND_UPDATED = 'REFUND_UPDATED',
  REFUND_COMPLETED = 'REFUND_COMPLETED',
  REFUND_REJECTED = 'REFUND_REJECTED',
  REFUND_FAILED = 'REFUND_FAILED',
}
