import type {FC} from 'react';
import React from 'react';
import {useMediaQuery, useTheme} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {useInitialiseNotifications} from '../libs/notifications/notificationHelpers';
import {useNotification} from '../hooks/useNotification';

const NotificationProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  // initialise query for notifications
  const {data: alert} = useInitialiseNotifications();
  const {close: closeNotification} = useNotification();

  return (
    <>
      {children}
      <Snackbar
        open={alert?.isShowing}
        autoHideDuration={alert?.timeToShow}
        onClose={() => closeNotification()}
        sx={{zIndex: 10000, boxShadow: 24, position: 'fixed'}}
        anchorOrigin={{
          vertical: matches ? 'top' : 'bottom',
          horizontal: matches ? 'center' : 'right',
        }}
      >
        <Alert
          onClose={() => closeNotification()}
          severity={alert?.severity}
          sx={{width: '100%'}}
        >
          {alert?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default NotificationProvider;
