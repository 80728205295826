import {loadStripe} from '@stripe/stripe-js';

export const STRIPE_CONFIG = {
  PUBLISHABLE_KEY:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ??
    console.warn('REACT_APP_STRIPE_PUBLISHABLE_KEY has not been set') ??
    '',
};

export const stripePromise = loadStripe(STRIPE_CONFIG.PUBLISHABLE_KEY);
