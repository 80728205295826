import Button from '@mui/material/Button';
import type {DialogProps} from '@mui/material/Dialog';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import type {FC} from 'react';
import React from 'react';
import LoadingButton from './buttons/LoadingButton';
import NoticeAlert from './NoticeAlert';

interface CustomConfirmationModalProps extends DialogProps {
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  description: string;
  loading: boolean;
  buttonActions: {reject: string; accept: string};
}

const CustomConfirmationModal: FC<
  React.PropsWithChildren<CustomConfirmationModalProps>
> = ({
  onConfirm,
  description,
  buttonActions,
  loading,
  title,
  open,
  ...props
}) => (
  <Dialog open={open} {...props}>
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <NoticeAlert message={description} severity="error" />
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" onClick={props.onClose} disabled={loading}>
        {buttonActions.reject}
      </Button>

      <LoadingButton onClick={onConfirm} loading={loading} disabled={loading}>
        {buttonActions.accept}
      </LoadingButton>
    </DialogActions>
  </Dialog>
);

export default CustomConfirmationModal;
