import React from 'react';
import Typography from '@mui/material/Typography';
import type {Money} from '@handsin/money';
import {MoneyUtils} from '@handsin/money';
import type {PaymentRecord} from '@local/backend/@types/updated-api-types/payments/PaymentRecord';
import {CustomCellRendererProps} from '@ag-grid-community/react';

export const MoneyCell = ({
  data,
}: CustomCellRendererProps<PaymentRecord, Money>) => {
  if (!data?.totalMoney) {
    return null;
  }
  return (
    <Typography variant="body2" align="right" width="100%">
      {MoneyUtils.formatMoney(data.totalMoney)}{' '}
      <span style={{color: 'grey', marginLeft: 5}}>
        {data.totalMoney.currency}
      </span>
    </Typography>
  );
};
