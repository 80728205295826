import {useMutation, useQueryClient} from '@tanstack/react-query';
import {getQueryKey} from '@trpc/react-query';
import backendClient from '@local/frontend/config/backend';
import config from '@local/frontend/config';
import {Env} from '@local/frontend/@types/env';
import {useChangeSessionEnvironment} from '../session';
import {trpc} from '../../trpc/trpc';

export const useLoginMutation = () => {
  const queryClient = useQueryClient();
  const changeEnvironmentMutation = useChangeSessionEnvironment();

  return useMutation(
    async (merchantId: string | undefined) => {
      const res = await backendClient.post<{done: string}>('/auth/login', {
        merchantId,
      });
      return res.data;
    },
    {
      onSuccess: async () => {
        if (config.ENV === Env.PRODUCTION) {
          // always default to sandbox environment when logging into a production merchant
          changeEnvironmentMutation.mutate(Env.SANDBOX);
          await queryClient.invalidateQueries(getQueryKey(trpc.session));
        }
      },
    }
  );
};
