import type {TypographyProps} from '@mui/material';
import {Typography} from '@mui/material';
import type {FC} from 'react';
import React from 'react';
import {Helper} from './Helper';

export interface FormSectionTitleProps extends TypographyProps {
  title: string;
  helper?: string;
}

const FormSectionTitle: FC<React.PropsWithChildren<FormSectionTitleProps>> = ({
  title,
  helper,
  ...props
}) => (
  <Typography variant="subtitle2" {...props}>
    {title}
    {helper && <Helper text={helper} />}
  </Typography>
);

export default FormSectionTitle;
