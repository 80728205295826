import List from '@mui/material/List';
import {ListItem} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import type {FC} from 'react';
import React from 'react';
import type {PaymentMethod} from '@stripe/stripe-js';
import CardDetails from './CardDetails';

const PaymentMethodDetailsList: FC<
  React.PropsWithChildren<Pick<PaymentMethod, 'card' | 'billing_details'>>
> = ({card, billing_details}) => (
  <List disablePadding>
    {card && <CardDetails {...card} />}
    {billing_details.email && (
      <ListItem>
        <ListItemText primary={billing_details.email} />
      </ListItem>
    )}
    {billing_details.phone && (
      <ListItem>
        <ListItemText primary={billing_details.phone} />
      </ListItem>
    )}
    {billing_details.address && (
      <>
        {billing_details.address.line1 && (
          <ListItem>
            <ListItemText primary={billing_details.address.line1} />
          </ListItem>
        )}
        {billing_details.address.line2 && (
          <ListItem>
            <ListItemText primary={billing_details.address.line2} />
          </ListItem>
        )}
        {billing_details.address.country && (
          <ListItem>
            <ListItemText
              primary={billing_details.address.country}
              secondary={billing_details.address.city}
            />
          </ListItem>
        )}
        {billing_details.address.postal_code && (
          <ListItem>
            <ListItemText
              primary="Post Code"
              secondary={billing_details.address.postal_code}
            />
          </ListItem>
        )}
      </>
    )}
  </List>
);

export default PaymentMethodDetailsList;
