import type {FC} from 'react';
import React, {useEffect, useState} from 'react';

export interface ImagePreviewProps
  extends Omit<
    React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >,
    'src'
  > {
  src?: File | string;
}

const ImagePreview: FC<React.PropsWithChildren<ImagePreviewProps>> = ({
  src,
  ...props
}) => {
  const file = src instanceof File ? src : undefined;
  const imageUrl = typeof src === 'string' ? src : undefined;

  const [filePreviewUrl, setFilePreviewUrl] = useState<string | undefined>();

  useEffect(() => {
    let previewUrl: string | undefined;

    if (file) {
      previewUrl = URL.createObjectURL(file);
      setFilePreviewUrl(previewUrl);
    } else if (imageUrl) {
      setFilePreviewUrl(imageUrl);
    }

    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [src]);

  if (!filePreviewUrl) {
    return <div {...props} />;
  }

  return <img {...props} alt={props.alt ?? file?.name} src={filePreviewUrl} />;
};

export default ImagePreview;
