import {CustomCellRendererProps} from '@ag-grid-community/react';
import {PaymentRecord} from '@handsin/api-node';
import React from 'react';
import {Gateway} from '@local/backend/@types/updated-api-types/gateways/Gateway';
import {Avatar, Stack, Typography} from '@mui/material';
import CardBrandIcon from '@local/frontend/components/atoms/CardBrandIcon';
import {CardBrand} from '@local/backend/@types/updated-api-types/payments/CardBrand';
import {gatewayToServiceOptions} from '../../../pages/dashboard/connect/components/ServiceOptions';

export const ProcessorCell = ({
  data,
}: CustomCellRendererProps<
  PaymentRecord & {gatewayName: Gateway},
  Gateway
>) => {
  if (!data) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Avatar
        sx={{width: 25, height: 25}}
        variant="rounded"
        src={gatewayToServiceOptions[data.gatewayName]?.iconUrl}
        alt={data.gatewayName}
      />
      {data?.cardDetails?.card?.cardBrand && (
        <CardBrandIcon
          sx={{width: 40, height: 25}}
          cardBrand={
            Object.values(CardBrand).find(
              brand =>
                brand === data?.cardDetails?.card?.cardBrand.toUpperCase()
            ) ?? null
          }
        />
      )}
      <Typography variant="caption">
        {data?.cardDetails?.card?.last4
          ? `**** ${data?.cardDetails?.card?.last4}`
          : undefined}{' '}
      </Typography>
    </Stack>
  );
};
