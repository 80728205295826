import type {FC} from 'react';
import React from 'react';
import {LinearProgress, Stack} from '@mui/material';

const Loading: FC<React.PropsWithChildren<unknown>> = () => (
  <Stack sx={{width: '100%', color: 'grey.500'}}>
    <LinearProgress color="inherit" />
  </Stack>
);

export default Loading;
