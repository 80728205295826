import {usePostHog} from 'posthog-js/react';
import {FC, PropsWithChildren, useEffect} from 'react';
import {useGetUser, useMerchant} from '../libs/trpc/trpc';

const AnalyticsProvider: FC<PropsWithChildren> = ({children}) => {
  const posthog = usePostHog();
  const {data: user} = useGetUser();
  const {data: merchant} = useMerchant();

  useEffect(() => {
    if (user) {
      posthog.identify(user.id, {
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        language: user.language,
        emailVerified: user.emailVerified,
        country: user.country,
        createdAt: user.createdAt,
      });
    }
  }, [user]);

  useEffect(() => {
    if (merchant) {
      posthog.group('merchant', merchant.id, {
        name: merchant.name,
        email: merchant.contactDetails.email,
        phoneNumber: merchant.contactDetails.phoneNumber ?? null,
        ownerId: merchant.ownerId,
        billingPlanId: merchant.billingPlanId ?? null,
        billingPlanExpiresAt: merchant.billingPlanExpiresAt ?? null,
      });
    }
  }, [merchant]);

  return children;
};

export default AnalyticsProvider;
