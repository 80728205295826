import {Navigate} from 'react-router-dom';
import React, {lazy} from 'react';
import IfHasPermissions from '@local/frontend/hocs/IfHasPermissions';
import {MerchantScope} from '@local/backend/@types/scopes/MerchantScopes';

const ProfileSettingsPage = lazy(() => import('./profile/ProfileSettingsPage'));

const MerchantTeamPage = lazy(() => import('./team/MerchantTeamPage'));

export const settingsRoutes = {
  path: 'settings',
  children: [
    {index: true, element: <Navigate to="profile" replace />},
    {
      path: 'profile',
      element: (
        <IfHasPermissions
          thenRender={() => <ProfileSettingsPage />}
          permissions={[MerchantScope.READ]}
        />
      ),
    },
    {
      path: 'team',
      element: (
        <IfHasPermissions
          thenRender={() => <MerchantTeamPage />}
          permissions={[MerchantScope.READ]}
        />
      ),
    },
  ],
};
