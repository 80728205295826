import type {MerchantScopes} from '@local/backend/@types/scopes/MerchantScopes';

export const verifyMerchantScopes = (
  requiredScopes: MerchantScopes[],
  actualScopes: string[]
): boolean => {
  const actualScopesSet = new Set(actualScopes);

  return requiredScopes.every(
    requiredScope =>
      actualScopesSet.has(requiredScope) ||
      actualScopes.some(actualScope => requiredScope.indexOf(actualScope) === 0)
  );
};
