import {Outlet} from 'react-router-dom';
import React, {lazy} from 'react';
import IfHasPermissions from '@local/frontend/hocs/IfHasPermissions';
import {CheckoutsScope} from '@local/backend/@types/scopes/CheckoutsScopes';

const ViewPaymentLinksPage = lazy(() => import('./view/ViewPaymentLinksPage'));

const CreatePaymentLinkPage = lazy(
  () => import('./create/CreatePaymentLinkPage')
);

const ManagePaymentLinkPage = lazy(
  () => import('./manage/ManagePaymentLinkPage')
);

export const paymentLinkRoutes = {
  path: 'payment-links',
  element: <Outlet />,
  children: [
    {
      index: true,
      element: (
        <IfHasPermissions
          thenRender={() => <ViewPaymentLinksPage />}
          permissions={[CheckoutsScope.READ]}
        />
      ),
    },
    {
      path: 'create',
      element: (
        <IfHasPermissions
          thenRender={() => <CreatePaymentLinkPage />}
          permissions={[CheckoutsScope.CREATE]}
        />
      ),
    },
    {
      path: ':checkoutId',
      element: <Outlet />,
      children: [
        {
          index: true,
          element: (
            <IfHasPermissions
              thenRender={() => <ManagePaymentLinkPage />}
              permissions={[CheckoutsScope.READ]}
            />
          ),
        },
      ],
    },
  ],
};
