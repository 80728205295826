import {Env} from '../@types/env';
import {useEnvironmentSession} from '../libs/trpc/trpc';

export const useEnvironment = (): Env => {
  const {data: env} = useEnvironmentSession();
  if (!env) {
    throw new Error('No environment has been set');
  }

  return env;
};
