import type {LineItem} from '@local/backend/@types/updated-api-types/payments/LineItem';
import type {CardProps} from '@mui/material/Card';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import type {FC} from 'react';
import React from 'react';
import Grid from '@mui/material/Grid';
import LineItemCard from './LineItemCard';

interface LineItemCardListProps extends CardProps {
  lineItems: LineItem[];
}

const LineItemCardList: FC<React.PropsWithChildren<LineItemCardListProps>> = ({
  lineItems,
  ...props
}) => (
  <Card
    variant="outlined"
    {...props}
    sx={{
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      ...props.sx,
    }}
  >
    <CardHeader
      title={`Order Information ${
        lineItems.length > 2 ? `(${lineItems.length})` : ''
      }`}
      sx={{color: 'primary.main'}}
    />
    <CardContent sx={{maxHeight: 150, overflowY: 'auto'}}>
      <Grid container spacing={2}>
        {lineItems.map(lineItem => (
          <Grid item xs={12} md={6} key={lineItem.item.id}>
            <LineItemCard lineItem={lineItem} />
          </Grid>
        ))}
      </Grid>
    </CardContent>
  </Card>
);

export default LineItemCardList;
