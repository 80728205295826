import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import type {FC} from 'react';
import React, {useEffect, useMemo, useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import type {GridColDef, GridRowsProp} from '@mui/x-data-grid';
import {DataGrid} from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import {useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {useNotification} from '@local/frontend/hooks/useNotification';
import {MoneyUtils} from '@handsin/money';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import {CardContent} from '@mui/material';
import dayjs from 'dayjs';
import type {LineItem} from '@local/backend/@types/updated-api-types/payments/LineItem';
import {useCustomModals} from '@local/frontend/libs/modals/useModals';
import type {
  CreateGroupPaymentFormValues,
  FormCustomer,
} from '../../forms/schemas';
import ImagePreview from '../../atoms/ImagePreview';
import {
  useCreateGroupPayment,
  useInviteToGroupPayment,
  useMerchant,
} from '../../../libs/trpc/trpc';
import {handleGroupPaymentCreationSubmission} from './review-modal-handlers';
import {createGroupPaymentPreview} from './helpers/createGroupPaymentPreview';

interface GroupDetailInfoProps {
  title: string;
  value: string;
}

const GroupDetailInfo: FC<React.PropsWithChildren<GroupDetailInfoProps>> = ({
  title,
  value,
}) => (
  <Stack>
    <Typography variant="subtitle1" color="primary">
      {title}:
    </Typography>
    <Typography variant="subtitle1">{value}</Typography>
  </Stack>
);

const ReviewModal: FC<React.PropsWithChildren<unknown>> = () => {
  const {data: merchant} = useMerchant();
  const queryClient = useQueryClient();
  const {closeModal} = useCustomModals();
  const navigate = useNavigate();
  const formMethods = useForm();
  const {open: openNotification} = useNotification();

  const groupPaymentCreationParams =
    queryClient.getQueryData<CreateGroupPaymentFormValues>([
      'group-payment-creation-data',
    ]);

  const createGroupPaymentMutation = useCreateGroupPayment();
  const inviteToGroupMutation = useInviteToGroupPayment();

  const [creationStepMessage, setCreationStepMessage] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (createGroupPaymentMutation.isLoading) {
      setCreationStepMessage('Creating group payment');
    } else {
      setCreationStepMessage('Creating...');
    }
  }, [createGroupPaymentMutation.isLoading, inviteToGroupMutation.isLoading]);

  const generateGroupDetailRows = (customers: FormCustomer[]): GridRowsProp =>
    customers.map(
      ({firstName, lastName, email, phoneNumber}, index: number) => ({
        id: index,
        firstName,
        lastName,
        email,
        phoneNumber,
      })
    );

  const groupDetailColumns: GridColDef[] = [
    {field: 'firstName', headerName: 'First Name', width: 250, sortable: false},
    {field: 'lastName', headerName: 'Last Name', width: 250, sortable: false},
    {field: 'email', headerName: 'Email', width: 250, sortable: false},
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      width: 250,
      sortable: false,
    },
  ];

  const generateLineItemRows = (lineItems: LineItem[]): GridRowsProp =>
    lineItems.map((lineItem, index) => ({
      id: index,
      imageUrl: lineItem.item.imageUrls?.at(0),
      name: lineItem.item.name,
      description: lineItem.item.description,
      price: MoneyUtils.formatMoney(lineItem.totalMoney),
      extraProductInformation: Object.entries(
        lineItem.item.attributes ?? {}
      ).map(([key, value]) => `${key} - ${value}`),
    }));

  const lineItemColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'imageUrl',
        headerName: 'Image',
        width: 150,
        renderCell: ({row}) => (
          <div style={{width: '100%', height: '100%'}}>
            <ImagePreview
              src={row.imageUrl ?? merchant?.logoUrl}
              alt={row.name}
              style={{
                width: '100%',
                height: '100%',
                padding: '10px 0px',
                objectFit: 'contain',
              }}
            />
          </div>
        ),
      },
      {field: 'name', headerName: 'Name', width: 150},
      {field: 'description', headerName: 'Description', width: 250},
      {field: 'price', headerName: 'Price', width: 150},
      {
        field: 'extraProductInformation',
        headerName: 'Extra Info',
        width: 300,
        renderCell: params => (
          <p style={{whiteSpace: 'pre'}}>{params.value?.join('\n')}</p>
        ),
      },
    ],
    []
  );

  if (!groupPaymentCreationParams?.splitType) {
    return (
      <Alert color="error">
        Something went wrong. No split type was set. Please contact support if
        this problem persists.
      </Alert>
    );
  }

  const groupPaymentPreviewValues = createGroupPaymentPreview({
    customers: [],
    splitType: groupPaymentCreationParams.splitType,
    expectedGroupSize: groupPaymentCreationParams.expectedGroupSize,
    currency: groupPaymentCreationParams.currency,
    lineItems: groupPaymentCreationParams.lineItems,
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Summary" />
            <CardContent>
              <Stack spacing={1}>
                {groupPaymentPreviewValues.totalMoney && (
                  <GroupDetailInfo
                    title="You are collecting"
                    value={MoneyUtils.formatMoney(
                      groupPaymentPreviewValues.totalMoney
                    )}
                  />
                )}
                {groupPaymentCreationParams.expirationDate && (
                  <GroupDetailInfo
                    title="The group will expire on"
                    value={dayjs(
                      groupPaymentCreationParams.expirationDate
                    ).format('LLLL')}
                  />
                )}
                {groupPaymentPreviewValues.splitAllocation && (
                  <GroupDetailInfo
                    title="You expect the group size to be"
                    value={`${groupPaymentPreviewValues.splitAllocation.toString()} people`}
                  />
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="Products" />
            <CardContent>
              <DataGrid
                rows={generateLineItemRows(
                  groupPaymentCreationParams.lineItems
                )}
                columns={lineItemColumns}
                autoHeight
                rowHeight={85}
                disableColumnMenu
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="Group Members" />
            <CardContent>
              <DataGrid
                rows={generateGroupDetailRows([
                  groupPaymentCreationParams.owner,
                  ...groupPaymentCreationParams.invitedMembers,
                ])}
                columns={groupDetailColumns}
                autoHeight
                disableColumnMenu
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid container item xs={12} justifyContent="center">
          <form
            onSubmit={formMethods.handleSubmit(async () => {
              await handleGroupPaymentCreationSubmission(
                groupPaymentCreationParams,
                createGroupPaymentMutation,
                closeModal,
                navigate
              ).catch((err: Error) => {
                // notify merchant of error
                openNotification({
                  message: err.message,
                  severity: 'error',
                });
              });
            })}
          >
            <Button
              variant="contained"
              type="submit"
              fullWidth
              disabled={formMethods.formState.isSubmitting}
            >
              Confirm & Create Payment
            </Button>
          </form>
        </Grid>
      </Grid>

      {/* @TODO: possibly refactor into component */}
      <Backdrop
        sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}}
        open={formMethods.formState.isSubmitting}
      >
        <Stack spacing={2} justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" />
          <Typography variant="h4">{creationStepMessage}</Typography>
        </Stack>
      </Backdrop>
    </>
  );
};

export default ReviewModal;
