import type {MutationOptions} from '@tanstack/react-query';
import {useQueryClient} from '@tanstack/react-query';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {getQueryKey} from '@trpc/react-query';

import {useNotification} from '@local/frontend/hooks/useNotification';
import type {TrpcError, TrpcRouterInputs} from '../../trpc/trpc';
import {
  trpc,
  useCancelGroupPayment,
  useCaptureGroupPayment,
  useCreateGroupPayment,
  useJoinGroupPayment,
  useKickFromGroupPayment,
  useLeaveGroupPayment,
  useRefundGroupPayment,
  useUpdateGroupPayment,
} from '../../trpc/trpc';

export const useCreateGroupMutation = () => {
  const {open: openNotification} = useNotification();

  return useCreateGroupPayment({
    onSuccess: () => {
      openNotification({
        message: 'Group has been created',
        severity: 'success',
      });
    },
    onError: e => {
      const message = e?.data?.axiosError?.response?.data.detail;
      openNotification({
        message: message ?? 'Failed to create Group.',
        severity: 'error',
      });
    },
  });
};

export const useUpdateGroupPaymentMutation = (
  mutationOptions?: MutationOptions<
    GroupPaymentRecord,
    TrpcError,
    TrpcRouterInputs['groupPayments']['update']
  >
) => {
  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();

  const updateGroupMutation = useUpdateGroupPayment({
    onMutate: mutationOptions?.onMutate,
    onSuccess: async (groupPayment, onSuccessParams, onSuccessCtx) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(groupPayment, onSuccessParams, onSuccessCtx);
      }

      await queryClient.invalidateQueries(getQueryKey(trpc.groupPayments));

      await queryClient.invalidateQueries(getQueryKey(trpc.payments));
    },
    onError: (err, onErrorParams, ctx) => {
      if (mutationOptions?.onError) {
        mutationOptions.onError(err, onErrorParams, ctx);
      }

      openNotification({
        message:
          err.data?.axiosError?.response?.data.detail ??
          'Failed to update group payment',
        severity: 'error',
      });
    },
    onSettled: (gpr, error, onSettledParams, ctx) => {
      if (mutationOptions?.onSettled) {
        mutationOptions.onSettled(gpr, error, onSettledParams, ctx);
      }
    },
  });

  return updateGroupMutation;
};

export const useJoinGroupMutation = (
  mutationOptions?: MutationOptions<
    GroupPaymentRecord,
    TrpcError,
    TrpcRouterInputs['groupPayments']['join']
  >
) => {
  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();

  return useJoinGroupPayment({
    onMutate: mutationOptions?.onMutate,
    onSuccess: async (gpr, variables, ctx) => {
      if (mutationOptions?.onSuccess) {
        await mutationOptions.onSuccess(gpr, variables, ctx);
      }
      // invalidate relevant queries
      await queryClient.invalidateQueries(getQueryKey(trpc.groupPayments));

      openNotification({
        message: 'customer joined the group',
        severity: 'success',
      });
    },
    onError: (error, variables, ctx) => {
      if (mutationOptions?.onError) {
        mutationOptions.onError(error, variables, ctx);
      }
      openNotification({
        message:
          error.data?.axiosError?.response?.data?.detail ??
          'Customer failed to join group',
        severity: 'error',
      });
    },
    onSettled: (gpr, error, variables, ctx) => {
      if (mutationOptions?.onSettled) {
        mutationOptions.onSettled(gpr, error, variables, ctx);
      }
    },
  });
};

export const useCancelGroupPaymentMutation = () => {
  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();

  return useCancelGroupPayment({
    onSuccess: async (_, args) => {
      await queryClient.invalidateQueries(
        trpc.groupPayments.get.getQueryKey({
          groupPaymentId: args.groupPaymentId,
        })
      );
      openNotification({
        message: 'Group has been cancelled',
        severity: 'success',
      });
    },
    onError: () => {
      openNotification({
        message: 'Group failed to be cancelled',
        severity: 'error',
      });
    },
  });
};

export const useCaptureGroupPaymentMutation = () => {
  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();

  return useCaptureGroupPayment({
    onSuccess: async (_, args) => {
      await queryClient.invalidateQueries(
        trpc.groupPayments.get.getQueryKey({
          groupPaymentId: args.groupPaymentId,
        })
      );
      await queryClient.invalidateQueries(getQueryKey(trpc.payments));
      openNotification({
        message: 'Group has been sucessfully captured',
        severity: 'success',
      });
    },
    onError: err => {
      openNotification({
        message:
          err.data?.axiosError?.response?.data.detail ??
          'Failed to capture group payments',
        severity: 'error',
      });
    },
  });
};

export const useRefundGroupPaymentMutation = () => {
  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();

  return useRefundGroupPayment({
    onSuccess: async (_, args) => {
      await queryClient.invalidateQueries(
        trpc.groupPayments.get.getQueryKey({
          groupPaymentId: args.groupPaymentId,
        })
      );
      openNotification({
        message: 'All Group members have been fully refunded',
        severity: 'success',
      });
    },
    onError: () => {
      openNotification({
        message: 'Failed to refund the group',
        severity: 'error',
      });
    },
  });
};

export const useKickFromGroupPaymentMutation = (
  mutationOptions?: MutationOptions<
    GroupPaymentRecord,
    TrpcError,
    TrpcRouterInputs['groupPayments']['kick']
  >
) => {
  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();

  return useKickFromGroupPayment({
    onMutate: mutationOptions?.onMutate,
    onSuccess: async (gpr: GroupPaymentRecord, variables, ctx) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(gpr, variables, ctx);
      }

      await queryClient.invalidateQueries(getQueryKey(trpc.groupPayments));
    },
    onError: (error, variables, ctx) => {
      if (mutationOptions?.onError) {
        mutationOptions.onError(error, variables, ctx);
      }

      openNotification({
        message:
          error.data?.axiosError?.response?.data.detail ??
          'Failed to kick selected user from group',
        severity: 'error',
      });
    },
    onSettled: (gpr, error, variables, ctx) => {
      if (mutationOptions?.onSettled) {
        mutationOptions.onSettled(gpr, error, variables, ctx);
      }
    },
  });
};

// use's leave endpoint @TODO: will need to be changed in future for merchants to be able to remove people from a group.
export const useRemoveFromGroupPayment = ({
  onMutate,
  onSuccess,
  onError,
  onSettled,
}: MutationOptions<
  GroupPaymentRecord,
  TrpcError,
  TrpcRouterInputs['groupPayments']['leave']
>) => {
  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();

  const leaveGroupMutation = useLeaveGroupPayment({
    onMutate,
    onSuccess: async (groupPayment, variables, ctx) => {
      if (onSuccess) {
        onSuccess(groupPayment, variables, ctx);
      }

      await queryClient.invalidateQueries(getQueryKey(trpc.groupPayments));
    },
    onError: async (error, variables, ctx) => {
      if (onError) {
        onError(error, variables, ctx);
      }
      const message = error.data?.axiosError?.response?.data.detail;
      openNotification({
        message: message ?? 'Failed to kick user from group',
        severity: 'error',
      });
    },
    onSettled: (gpr, error, variables, ctx) => {
      if (onSettled) {
        onSettled(gpr, error, variables, ctx);
      }
    },
  });

  return leaveGroupMutation;
};
