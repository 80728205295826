import type {FC} from 'react';
import React from 'react';
import {Typography} from '@mui/material';

interface CopyrightProps {
  date?: Date;
  company: string;
}

export const Copyright: FC<React.PropsWithChildren<CopyrightProps>> = ({
  date = new Date(),
  company,
}) => (
  <Typography variant="caption">
    &copy; Copyright {date.getFullYear()}, {company}
  </Typography>
);
