import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import {useNavigate} from 'react-router-dom';
import type {CustomCellRendererProps} from '@ag-grid-community/react';
import Skeleton from '@mui/material/Skeleton';
import {Avatar, IconButton, Tooltip} from '@mui/material';
import {stringToHexColor} from '@local/frontend/utils/custom-hash';
import {formatFullName} from '../../../utils/formatters';
import {useCustomer} from '../../../libs/trpc/trpc';

export const SimpleCustomerData = ({
  customerId,
}: {
  customerId: string | undefined;
}) => {
  const navigate = useNavigate();
  const {data: customer, isLoading: isCustomerLoading} = useCustomer(
    customerId
      ? {
          customerId,
        }
      : undefined,
    {
      enabled: !!customerId,
      refetchOnWindowFocus: false,
    }
  );

  if (!customerId) {
    return null;
  }

  if (isCustomerLoading) {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Skeleton variant="text" width="100px" sx={{fontSize: 16}} />
      </Stack>
    );
  }

  if (!customer) {
    return null;
  }
  const fullName = formatFullName(customer.firstName, customer.lastName);

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Tooltip
        arrow
        placement="bottom-start"
        title={
          <Stack>
            <Typography variant="subtitle2">{fullName}</Typography>
            {customer.email && (
              <Typography
                component="a"
                variant="caption"
                href={`mailto:${customer.email}`}
              >
                {customer.email}
              </Typography>
            )}
            {customer.phoneNumber && (
              <Typography
                component="a"
                variant="caption"
                href={`tel:${customer.phoneNumber}`}
              >
                {customer.phoneNumber}
              </Typography>
            )}
          </Stack>
        }
      >
        <IconButton size="small">
          <Avatar
            onClick={() => navigate(`/dashboard/customers/${customerId}`)}
            variant="circular"
            sx={{
              width: 25,
              height: 25,
              fontSize: 12,
              backgroundColor: stringToHexColor(fullName),
            }}
            src={customer.avatarUrl}
            alt={fullName}
          >
            {fullName
              .split(' ')
              .map(name => name.at(0)?.toUpperCase())
              .join('')}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Typography
        variant="body2"
        fontSize={14}
        noWrap
        sx={{display: 'inline-flex', alignItems: 'center'}}
      >
        {fullName}
      </Typography>
    </Stack>
  );
};

const CustomerCell = ({
  value: customerId,
}: CustomCellRendererProps<unknown, string>) => (
  <SimpleCustomerData customerId={customerId ?? undefined} />
);

export default CustomerCell;
