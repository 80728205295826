import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import type {FC} from 'react';
import React from 'react';
import type {Money} from '@handsin/money';
import {LocalMoneyCalculator, MoneyUtils} from '@handsin/money';
import {useQueryClient} from '@tanstack/react-query';
import Alert from '@mui/material/Alert';
import {useForm} from 'react-hook-form';
import {useNotification} from '@local/frontend/hooks/useNotification';
import {
  useCreateCustomer,
  useCreateMultiCard,
} from '@local/frontend/libs/trpc/trpc';
import {PaymentSource} from '@local/frontend/@types/payment-source';
import {v4 as uuidv4} from 'uuid';
import {useNavigate} from 'react-router-dom';
import type {Customer} from '@local/backend/@types/updated-api-types/customers/customer';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import {useCustomModals} from '@local/frontend/libs/modals/useModals';
import LoadingButton from '../../atoms/buttons/LoadingButton';
import type {MultiCardPaymentFormValues} from '../../forms/schemas/@types/MultiCardPaymentFormValues';
import CustomerCard from '../../atoms/CustomerCard';
import LineItemCardList from '../../atoms/LineItemCardList';

interface MultiCardDetailInfoProps {
  title: string;
  value: string;
}

const MultiCardDetailInfo: FC<
  React.PropsWithChildren<MultiCardDetailInfoProps>
> = ({title, value}) => (
  <Stack>
    <Typography variant="subtitle1" color="primary">
      {title}:
    </Typography>
    <Typography variant="subtitle1">{value}</Typography>
  </Stack>
);

const MultiCardReviewModal: FC<React.PropsWithChildren<unknown>> = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {open: openNotification} = useNotification();
  const {closeModal} = useCustomModals();
  const formMethods = useForm();

  const createMultiCardMutation = useCreateMultiCard();
  const createCustomerMutation = useCreateCustomer();

  const multiCardCreationParams =
    queryClient.getQueryData<MultiCardPaymentFormValues>([
      'multi-card-creation-data',
    ]);

  if (!multiCardCreationParams) {
    return (
      <Alert color="error">
        Something went wrong. Please contact support if this problem persists.
      </Alert>
    );
  }

  const totalMoney = multiCardCreationParams.lineItems
    .reduce(
      (calc, lineItem) => calc.add(lineItem.totalMoney),
      new LocalMoneyCalculator({
        amount: 0,
        currency: multiCardCreationParams.currency,
      })
    )
    .calculate();

  const handleMultiCardPaymentSubmission = async (
    creationParams: MultiCardPaymentFormValues,
    amountMoney: Money
  ) => {
    let customer: Customer | undefined;
    const customerDetails = creationParams.customers?.at(0);

    if (customerDetails) {
      customer = await createCustomerMutation.mutateAsync({
        firstName: customerDetails.firstName,
        lastName: customerDetails.lastName,
        email: customerDetails.email,
        phoneNumber: customerDetails.phoneNumber,
      });
    }

    createMultiCardMutation.mutate(
      {
        idempotencyKey: uuidv4(),
        amountMoney,
        lineItemParams: creationParams.lineItems.map(lineItem => ({
          item: lineItem.item.id,
          quantity: lineItem.quantity,
        })),
        pageOptions: {
          maxNumberCards: creationParams.maxCardLimit
            ? parseInt(creationParams.maxCardLimit, 10)
            : undefined,
        },
        customer: customer?.id,
        expirationDate: creationParams.expirationDate,
        source: PaymentSource.PAYMENT_LINK,
      },
      {
        onSuccess: multiCardRecord => {
          closeModal(ModalName.REVIEW_CREATE_MULTI_CARD);
          if (multiCardRecord.customerId) {
            navigate(`./multi-cards/${multiCardRecord.id}/confirm`);
          } else {
            navigate(`./multi-cards/${multiCardRecord.id}`);
          }
        },
      }
    );
  };

  const customer = multiCardCreationParams.customers?.at(0);

  return (
    <Grid container spacing={2}>
      {multiCardCreationParams.lineItems && (
        <Grid item xs>
          <LineItemCardList
            lineItems={multiCardCreationParams.lineItems}
            sx={{
              minHeight: 150,
            }}
          />
        </Grid>
      )}

      {customer && (
        <Grid item xs={12} md>
          {/* id is provided randomly to just satisfy the type */}
          <CustomerCard
            customer={{id: uuidv4(), ...customer}}
            sx={{
              minHeight: 150,
            }}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader title="Summary" sx={{color: 'primary.main'}} />
          <CardContent>
            <Stack spacing={1}>
              <MultiCardDetailInfo
                title="Total Payment Amount"
                value={MoneyUtils.formatMoney(totalMoney)}
              />
              {multiCardCreationParams.expirationDate && (
                <MultiCardDetailInfo
                  title="This payment will expire at"
                  value={dayjs(multiCardCreationParams.expirationDate).format(
                    'LLLL'
                  )}
                />
              )}
              {multiCardCreationParams.maxCardLimit && (
                <MultiCardDetailInfo
                  title="Max Card Limit"
                  value={`${multiCardCreationParams.maxCardLimit} cards`}
                />
              )}
            </Stack>
          </CardContent>
        </Card>
      </Grid>

      <Grid container item xs={12} justifyContent="center">
        <form
          onSubmit={formMethods.handleSubmit(async () => {
            await handleMultiCardPaymentSubmission(
              multiCardCreationParams,
              totalMoney
            ).catch((err: Error) => {
              // notify merchant of error
              openNotification({
                message: err.message,
                severity: 'error',
              });
            });
          })}
        >
          <LoadingButton
            variant="contained"
            type="submit"
            fullWidth
            loading={
              formMethods.formState.isSubmitting ||
              createCustomerMutation.isLoading ||
              createMultiCardMutation.isLoading
            }
            disabled={
              formMethods.formState.isSubmitting ||
              createCustomerMutation.isLoading ||
              createMultiCardMutation.isLoading
            }
          >
            Create Multi Card Payment
          </LoadingButton>
        </form>
      </Grid>
    </Grid>
  );
};

export default MultiCardReviewModal;
