import type {Customer} from '@handsin/api-node';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import type {FC} from 'react';
import React from 'react';
import {useFormContext} from 'react-hook-form';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {GroupPaymentsScope} from '@local/backend/@types/scopes/GroupPaymentsScopes';
import {CustomersScope} from '@local/backend/@types/scopes/CustomersScopes';
import useCanAccess from '@local/frontend/hooks/useCanAccess';
import CustomTooltip from '../../atoms/CustomToolTip';

interface UpdateCustomerActionsProps {
  openRemoveConfirmation: () => void;
  handleOnDiscard: () => void;
  handleEditButtonClick: () => void;
  groupPayment: GroupPaymentRecord;
  customer: Customer;
  isEditing: boolean;
}

const UpdateCustomerActions: FC<
  React.PropsWithChildren<UpdateCustomerActionsProps>
> = ({
  openRemoveConfirmation,
  handleOnDiscard,
  handleEditButtonClick,
  groupPayment,
  customer,
  isEditing,
}) => {
  const {
    formState: {isSubmitting, isValid, isDirty},
  } = useFormContext();

  const canRemoveFromGroup = useCanAccess([GroupPaymentsScope.UPDATE]);

  const canUpdateCustomer = useCanAccess([CustomersScope.UPDATE]);

  return (
    <Grid container justifyContent="flex-end" spacing={2}>
      <Grid item xs="auto">
        <CustomTooltip
          title="You do not have permission to remove customers"
          enabled={!canRemoveFromGroup}
        >
          <Button
            variant="contained"
            color="error"
            onClick={openRemoveConfirmation}
            disabled={
              !canRemoveFromGroup ||
              (groupPayment.memberIds.includes(customer.id) &&
                groupPayment.memberIds.length === 1)
            } // if the user we selected is the last group member then we can't kick them
          >
            Remove
          </Button>
        </CustomTooltip>
      </Grid>
      <Grid container item xs="auto" spacing={2}>
        {!isEditing ? (
          <Grid item xs="auto">
            <CustomTooltip
              title="You do not have permission to update customers"
              enabled={!canRemoveFromGroup}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleEditButtonClick}
                disabled={!canUpdateCustomer}
              >
                Edit
              </Button>
            </CustomTooltip>
          </Grid>
        ) : (
          <>
            <Grid item xs="auto">
              <Button
                variant="outlined"
                color="primary"
                onClick={handleOnDiscard}
              >
                Discard
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting || !isDirty || !isValid}
              >
                {isSubmitting ? <CircularProgress size={18} /> : 'Save'}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default UpdateCustomerActions;
