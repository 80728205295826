import type {FC} from 'react';
import React from 'react';
import {Box, Drawer, Stack, useMediaQuery, useTheme} from '@mui/material';
import FooterContent from './FooterContent';
import {FOOTER_DESKTOP, FOOTER_MOBILE, NAV_WIDTH} from '../dashboard-config';

const Footer: FC<React.PropsWithChildren<unknown>> = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  if (isTablet) {
    return (
      <Box marginTop="auto" sx={{backgroundColor: theme.palette.grey[400]}}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          height={FOOTER_MOBILE}
          overflow="hidden"
        >
          <FooterContent />
        </Stack>
      </Box>
    );
  }

  return (
    <Drawer
      open
      anchor="bottom"
      variant="permanent"
      PaperProps={{
        sx: {
          left: NAV_WIDTH,
          height: FOOTER_DESKTOP,
          border: 'none',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        },
      }}
    >
      <FooterContent />
    </Drawer>
  );
};

export default Footer;
