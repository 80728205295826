import {EventType} from '@local/backend/@types/updated-api-types/webhooks/EventType';

export interface WebhookOption {
  name: string;
  code: EventType | 'all';
  description?: string;
}

export const webhookOptions: WebhookOption[] = [
  {
    code: 'all',
    name: 'Select All',
  },
  // checkouts
  {
    code: EventType.CHECKOUT_CREATED,
    name: 'checkout created',
    description: 'Event triggered if a checkout session is created',
  },
  {
    code: EventType.CHECKOUT_UPDATED,
    name: 'checkout updated',
    description:
      'Event triggered if a checkout session is updated without a change of status',
  },
  {
    code: EventType.CHECKOUT_COMPLETED,
    name: 'checkout completed',
    description: 'Event triggered if a checkout session is completed',
  },
  {
    code: EventType.CHECKOUT_CANCELLED,
    name: 'checkout cancelled',
    description: 'Event triggered if a checkout session is cancelled',
  },
  {
    code: EventType.CHECKOUT_EXPIRED,
    name: 'checkout expired',
    description: 'Event triggered if a checkout session expires',
  },
  // gateway
  {
    code: EventType.GATEWAY_CONNECTION_OBTAINED,
    name: 'gateway connected',
    description: 'Event triggered if a connection to a gateway is created',
  },
  {
    code: EventType.GATEWAY_CONNECTION_REVOKED,
    name: 'gateway disconnected',
    description: 'Event triggered if a connection to a gateway is revoked',
  },
  // customers
  {
    code: EventType.CUSTOMER_CREATED,
    name: 'customer created',
    description: 'Event triggered if a customer is created',
  },
  {
    code: EventType.CUSTOMER_UPDATED,
    name: 'customer updated',
    description: 'Event triggered if a customer is updated',
  },
  {
    code: EventType.CUSTOMER_DELETED,
    name: 'customer deleted',
    description: 'Event triggered if a customer is deleted',
  },
  // group payments
  {
    code: EventType.GROUP_PAYMENT_CREATED,
    name: 'group payment created',
    description: 'Event triggered if a group payment is created',
  },
  {
    code: EventType.GROUP_PAYMENT_UPDATED,
    name: 'group payment updated',
    description:
      'Event triggered if a group payment is updated without a change of status',
  },
  {
    code: EventType.GROUP_PAYMENT_APPROVED,
    name: 'group payment approved',
    description: 'Event triggered if a group payment is approved',
  },
  {
    code: EventType.GROUP_PAYMENT_COMPLETED,
    name: 'group payment completed',
    description: 'Event triggered if a group payment is completed',
  },
  {
    code: EventType.GROUP_PAYMENT_CANCELLED,
    name: 'group payment cancelled',
    description: 'Event triggered if a group payment is cancelled',
  },
  {
    code: EventType.GROUP_PAYMENT_EXPIRED,
    name: 'group payment expired',
    description: 'Event triggered if a group payment is expired',
  },
  // multi card payments
  {
    code: EventType.MULTI_CARD_CREATED,
    name: 'multi-card created',
    description: 'Event triggered if a multi-card is created',
  },
  {
    code: EventType.MULTI_CARD_UPDATED,
    name: 'multi-card updated',
    description:
      'Event triggered if a multi-card is updated without a change of status',
  },
  {
    code: EventType.MULTI_CARD_APPROVED,
    name: 'multi-card approved',
    description: 'Event triggered if a multi-card is approved',
  },
  {
    code: EventType.MULTI_CARD_COMPLETED,
    name: 'multi-card completed',
    description: 'Event triggered if a multi-card is completed',
  },
  {
    code: EventType.MULTI_CARD_CANCELLED,
    name: 'multi-card cancelled',
    description: 'Event triggered if a multi-card is cancelled',
  },
  {
    code: EventType.MULTI_CARD_EXPIRED,
    name: 'multi-card expired',
    description: 'Event triggered if a multi-card is expired',
  },
  // items
  {
    code: EventType.ITEM_CREATED,
    name: 'item created',
    description: 'Event triggered if an item is created',
  },
  {
    code: EventType.ITEM_UPDATED,
    name: 'item updated',
    description: 'Event triggered if an item is updated',
  },
  // merchants
  {
    code: EventType.MERCHANT_TEAM_INVITE,
    name: 'merchant team invite',
    description:
      'Event triggered if a hands in user is invited to join the merchant team',
  },
  {
    code: EventType.MERCHANT_TEAM_JOIN,
    name: 'merchant team join',
    description:
      'Event triggered if a hands in user joins the merchant team (giving them at least the base level of access)',
  },
  {
    code: EventType.MERCHANT_TEAM_UPDATE,
    name: 'merchant team update',
    description:
      'Event triggered if the merchant team is updated (e.g. a member was given a different scope of permissions)',
  },
  {
    code: EventType.MERCHANT_API_KEY_CREATED,
    name: 'merchant API key created',
    description: 'Event triggered if a merchant API key is created',
  },
  {
    code: EventType.MERCHANT_API_KEY_DELETED,
    name: 'merchant API key deleted',
    description: 'Event triggered if a merchant API key is deleted',
  },
  {
    code: EventType.MERCHANT_UPDATED,
    name: 'merchant update',
    description: 'Event triggered if the merchant profile is updated',
  },
  // payments
  {
    code: EventType.PAYMENT_CREATED,
    name: 'payment created',
    description: 'Event triggered if a payment is created',
  },
  {
    code: EventType.PAYMENT_UPDATED,
    name: 'payment updated',
    description:
      'Event triggered if a payment is updated without a change of status',
  },
  {
    code: EventType.PAYMENT_APPROVED,
    name: 'payment approved',
    description: 'Event triggered if a payment is approved',
  },
  {
    code: EventType.PAYMENT_COMPLETED,
    name: 'payment completed',
    description: 'Event triggered if a payment is completed',
  },
  {
    code: EventType.PAYMENT_CANCELLED,
    name: 'payment cancelled',
    description: 'Event triggered if a payment is cancelled',
  },
  {
    code: EventType.PAYMENT_FAILED,
    name: 'payment failed',
    description: 'Event triggered if a payment fails',
  },
  // refunds
  {
    code: EventType.REFUND_CREATED,
    name: 'refund created',
    description: 'Event triggered if a refund is created',
  },
  {
    code: EventType.REFUND_UPDATED,
    name: 'refund updated',
    description:
      'Event triggered if a refund is updated without a change of status',
  },
  {
    code: EventType.REFUND_COMPLETED,
    name: 'refund completed',
    description: 'Event triggered if a refund is completed',
  },
  {
    code: EventType.REFUND_REJECTED,
    name: 'refund rejected',
    description: 'Event triggered if a refund is rejected',
  },
  {
    code: EventType.REFUND_FAILED,
    name: 'refund failed',
    description: 'Event triggered if a refund fails',
  },
];
