import {Button, Grid, Skeleton} from '@mui/material';
import type {FC} from 'react';
import React, {useState} from 'react';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useQueryClient} from '@tanstack/react-query';
import {getQueryKey} from '@trpc/react-query';
import {useNotification} from '@local/frontend/hooks/useNotification';
import PricingPlanSelector from '@local/frontend/pages/user/onboarding/plan/components/PricingPlanSelector';
import type {MerchantDTO} from '@local/backend/@types/updated-api-types/merchants/Merchant';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import {useCustomModals} from '@local/frontend/libs/modals/useModals';
import BillingPlansPaymentForm from './BillingPlansPaymentForm';
import {billingPlanSchema} from './upgrade-billing-plan-schema';
import type {UpgradeBillingPlanFormDataType} from './@types';
import {ChangeBillingPlanSteps} from './@types';
import {trpc} from '../../../libs/trpc/trpc';
import LoadingButton from '../../atoms/buttons/LoadingButton';

const useUpdateBillingPlanMutation = trpc.billing.updateBillingPlan.useMutation;
const useCreateBillingPlanMutation = trpc.billing.createBillingPlan.useMutation;
const usePaymentMethodList = trpc.billing.getPaymentMethods.useQuery;

export interface BillingPlansModalProps {
  merchant: MerchantDTO;
}

const BillingPlansModal: FC<
  React.PropsWithChildren<BillingPlansModalProps>
> = ({merchant}) => {
  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();
  const {closeModal} = useCustomModals();

  const [currentStep, setCurrentStep] = useState<ChangeBillingPlanSteps>(
    ChangeBillingPlanSteps.BILLING_PLAN_SELECT
  );

  const {
    formState: {isDirty, isValid, isSubmitting, ...formState},
    ...formMethods
  } = useForm<UpgradeBillingPlanFormDataType>({
    mode: 'all',
    resolver: yupResolver(billingPlanSchema),
    defaultValues: {
      billingPlanId: undefined,
      paymentMethod: undefined,
    },
  });

  const updateBillingPlanMutation = useUpdateBillingPlanMutation({
    onSuccess: async () => {
      openNotification({
        message: 'Payment plan changed successfully',
        severity: 'success',
      });
      await queryClient.invalidateQueries(getQueryKey(trpc.merchants));
      // lastly, close the modal (important to close last so invalidate queries executes before modal demounts)
      closeModal(ModalName.BILLING_PLANS);
    },
    onError: () => {
      openNotification({
        message:
          'Failed to change Payment plan. Please contact support, if the problem persists',
        severity: 'error',
      });
    },
  });

  const createBillingPlanMutation = useCreateBillingPlanMutation({
    onSuccess: async () => {
      openNotification({
        message: 'Payment plan upgraded successfully',
        severity: 'success',
      });
      await queryClient.invalidateQueries(getQueryKey(trpc.merchants));
      closeModal(ModalName.BILLING_PLANS);
    },
    onError: () => {
      openNotification({
        message:
          'Failed to upgrade Payment plan. Please contact support, if the problem persists',
        severity: 'error',
      });
    },
  });

  const {isInitialLoading: isPaymentMethodsLoading, data: paymentMethods} =
    usePaymentMethodList(undefined, {
      onError: () => {
        openNotification({
          message: 'Failed to get payment methods',
          severity: 'error',
        });
      },
    });

  const handleUpdatePlan = () => {
    const {billingPlanId} = formMethods.getValues();
    // check if the merchant has a payment plan
    if (merchant.billingPlanId) {
      // update their payment plan if so
      updateBillingPlanMutation.mutate({billingPlanId});
    } else {
      // otherwise start a new payment plan
      createBillingPlanMutation.mutate({billingPlanId});
    }
  };

  const handleBackNavigation = () => {
    // navigate back to select a billing plan
    setCurrentStep(step => step - 1);
  };

  const handleBillingPlanSelection = () => {
    // check if merchant has a payment method already
    if (paymentMethods && paymentMethods.length > 0) {
      // if so update the billing plan
      handleUpdatePlan();
    } else {
      // else navigate forward to payment form
      setCurrentStep(ChangeBillingPlanSteps.BILLING_PLAN_PAYMENT_FORM);
    }
  };

  const isLoading =
    isSubmitting ||
    createBillingPlanMutation.isLoading ||
    updateBillingPlanMutation.isLoading;

  if (isPaymentMethodsLoading) {
    return <Skeleton variant="rectangular" height="40px" width="100%" />;
  }

  const renderCurrentStep = (activeStep: ChangeBillingPlanSteps) => {
    switch (activeStep) {
      case ChangeBillingPlanSteps.BILLING_PLAN_PAYMENT_FORM: {
        return <BillingPlansPaymentForm />;
      }
      case ChangeBillingPlanSteps.BILLING_PLAN_SELECT:
      default: {
        return (
          <Controller
            control={formMethods.control}
            name="billingPlanId"
            render={({field}) => (
              <PricingPlanSelector
                value={field.value}
                variant="upgrading"
                onChange={value => {
                  field.onChange(value);
                  handleBillingPlanSelection();
                }}
                loading={isLoading}
                currentPlan={merchant.billingPlanId}
              />
            )}
          />
        );
      }
    }
  };

  const formProviderProps = {
    ...formMethods,
    formState: {isDirty, isValid, isSubmitting, ...formState},
  };

  return (
    <FormProvider {...formProviderProps}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          {renderCurrentStep(currentStep)}
        </Grid>

        <Grid container item xs={12} justifyContent="space-between" spacing={2}>
          {currentStep === ChangeBillingPlanSteps.BILLING_PLAN_PAYMENT_FORM && (
            <Grid item xs={6} sm="auto">
              <Button
                fullWidth
                onClick={handleBackNavigation}
                variant="outlined"
              >
                Back to plans
              </Button>
            </Grid>
          )}

          {currentStep === ChangeBillingPlanSteps.BILLING_PLAN_PAYMENT_FORM && (
            <Grid item xs={6} sm="auto">
              <LoadingButton
                fullWidth
                onClick={formMethods.handleSubmit(handleUpdatePlan)}
                variant="contained"
                loading={isLoading}
                disabled={isLoading || !isValid || !isDirty}
              >
                Upgrade Plan
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default BillingPlansModal;
