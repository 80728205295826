import React, {FC} from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {InputAdornment, TextField} from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import dayjs from 'dayjs';

interface Props {
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  startDate?: Date;
  endDate?: Date;
  onRangeChange: (startDate: Date | null, endDate: Date | null) => void;
}

const DateTimeRangePicker: FC<Props> = ({
  disabled,
  startDate,
  minDate,
  maxDate = new Date(),
  onRangeChange,
  endDate,
}) => {
  const onChange = ([start, end]: [Date | null, Date | null]) => {
    onRangeChange(start, end ? dayjs(end).endOf('day').toDate() : null);
  };

  return (
    <ReactDatePicker
      swapRange
      selectsRange
      startDate={startDate}
      minDate={minDate}
      maxDate={maxDate}
      endDate={endDate}
      onChange={onChange}
      timeFormat="p"
      dateFormat="Pp"
      customInput={
        <TextField
          disabled={disabled}
          color="primary"
          fullWidth
          size="small"
          variant="outlined"
          hiddenLabel
          InputProps={{
            style: {fontSize: 12, width: 310, color: 'primary.main'},
            startAdornment: (
              <InputAdornment position="start">
                <DateRangeIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      }
    />
  );
};

export default DateTimeRangePicker;
