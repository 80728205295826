import type {FC} from 'react';
import React, {useEffect} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {Country} from '@handsin/api-node';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {Button, DialogActions} from '@mui/material';
import {getQueryKey} from '@trpc/react-query';
import {useNotification} from '@local/frontend/hooks/useNotification';
import type {BillingDetails} from '@local/backend/@types/updated-api-types/billing/BillingDetails';
import {useCustomModals} from '@local/frontend/libs/modals/useModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import {trpc} from '../../../../libs/trpc/trpc';
import CountrySelect from '../../../../components/atoms/input/CountrySelect';
import FormFieldError from '../../../../components/forms/FormFieldError';
import LoadingButton from '../../../../components/atoms/buttons/LoadingButton';

const useUpdateBillingDetailsMutation =
  trpc.billing.updateBillingDetails.useMutation;

const updateBillingDetailsFormSchema = Yup.object({
  email: Yup.string().email().required('Email is required'),
  address: Yup.object({
    country: Yup.mixed<Country>()
      .oneOf(Object.values(Country))
      .required('Country is required'),
    city: Yup.string().optional(),
    addressLines: Yup.object({
      addressLine1: Yup.string().required('First address line is required'),
      addressLine2: Yup.string().optional(),
      addressLine3: Yup.string().optional(),
    }),
    postalCode: Yup.string()
      .max(20, 'Zip code/Postal code can be a maximum of 20 characters')
      .optional(),
  }).optional(),
});

export interface UpdateBillingDetailsProps {
  billingDetails?: BillingDetails;
}

const UpdateBillingDetailsModal: FC<
  React.PropsWithChildren<UpdateBillingDetailsProps>
> = ({billingDetails}) => {
  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();
  const {closeModal} = useCustomModals();

  const methods = useForm<Yup.InferType<typeof updateBillingDetailsFormSchema>>(
    {
      mode: 'all',
      resolver: yupResolver(updateBillingDetailsFormSchema),
      defaultValues: billingDetails,
    }
  );

  useEffect(() => methods.reset(billingDetails), [billingDetails]);

  const updateBillingDetailsMutation = useUpdateBillingDetailsMutation({
    onSuccess: async () => {
      openNotification({
        message: 'Billing details updated',
        severity: 'success',
      });
      closeModal(ModalName.UPDATE_BILLING_DETAILS);
      await queryClient.invalidateQueries(
        getQueryKey(trpc.billing.getBillingDetails)
      );
    },
    onError: () => {
      openNotification({
        message: 'Failed to update Billing details',
        severity: 'error',
      });
    },
  });

  const onSubmit = (
    formValues: Yup.InferType<typeof updateBillingDetailsFormSchema>
  ) => {
    updateBillingDetailsMutation.mutate(formValues);
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="email"
            control={methods.control}
            render={({field}) => (
              <TextField
                {...field}
                fullWidth
                required
                label="Email"
                helperText="Invoices and billing notifications will be sent to this email"
              />
            )}
          />
          <FormFieldError name="email" formState={methods.formState} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="address.country"
            control={methods.control}
            render={({field}) => (
              <CountrySelect {...field} fullWidth required label="Country" />
            )}
          />
          <FormFieldError
            name="address.country"
            formState={methods.formState}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <Controller
            name="address.addressLines.addressLine1"
            control={methods.control}
            render={({field}) => (
              <TextField {...field} fullWidth required label="Address" />
            )}
          />
          <FormFieldError
            name="address.addressLines.addressLine1"
            formState={methods.formState}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="address.city"
            control={methods.control}
            render={({field}) => (
              <TextField {...field} fullWidth label="City" />
            )}
          />
          <FormFieldError name="address.city" formState={methods.formState} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="address.postalCode"
            control={methods.control}
            render={({field}) => (
              <TextField {...field} fullWidth label="Postal code" />
            )}
          />
          <FormFieldError
            name="address.postalCode"
            formState={methods.formState}
          />
        </Grid>
      </Grid>
      <DialogActions>
        <Button
          type="reset"
          variant="outlined"
          color="primary"
          disabled={
            methods.formState.isSubmitting ||
            updateBillingDetailsMutation.isLoading ||
            !methods.formState.isDirty
          }
          onClick={() => methods.reset()}
        >
          discard
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          disabled={
            methods.formState.isSubmitting ||
            updateBillingDetailsMutation.isLoading ||
            !methods.formState.isValid
          }
          loading={updateBillingDetailsMutation.isLoading}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

export default UpdateBillingDetailsModal;
