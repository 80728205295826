import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';

const CancelBillingPlanInfo = () => (
  <List dense>
    <ListItem>
      <ListItemText primary="Your current plan will be cancelled immediately" />
    </ListItem>
    <ListItem>
      <ListItemText primary="You will be automatically charged for your current usage" />
    </ListItem>
    <ListItem>
      <ListItemText primary="You will return back to a free plan " />
    </ListItem>
    <ListItem>
      <ListItemText primary="You will no longer be able to create live group payments unless you upgrade to a paid plan. (You can still create test payments in sandbox mode)" />
    </ListItem>
  </List>
);

export default CancelBillingPlanInfo;
