import type {Currency} from '@handsin/money';
import type {Country} from '@handsin/api-node';
import {PlutoEnv} from './PlutoEnv';

// @TODO: extend or remove when Hands In Gateway enum is exported from sdk
export enum Gateway {
  SQUARE = 'square',
  STRIPE = 'stripe',
  SHUTTLE = 'shuttle',
  PLUTO = 'pluto',
  WORLDPAY = 'worldpay',
}

export interface PciProxy3DSData {
  code: string;
  acquirer: {
    acquirerBin: string;
    acquirerMerchantId: string;
  };
  merchant: {
    mcc: string;
    merchantName: string;
  };
}

export type PlutoData =
  | {
      googlePay?: {
        merchantId: string;
      };
      '3dsData'?: PciProxy3DSData[];
      env: PlutoEnv;
    }
  | undefined;
export interface SquareData {
  squareMerchantId: string;
  country?: Country;
  currency?: Currency;
  businessName?: string;
}

export interface StripeData {
  stripeUserId: string;
  businessName?: string;
  country?: Country;
}

export interface ShuttleData {
  country?: Country;
  shuttleConnectedGateways: string[];
}

export type GatewayDataTypes = {
  [Gateway.PLUTO]: PlutoData;
  [Gateway.SQUARE]: SquareData;
  [Gateway.STRIPE]: StripeData;
  [Gateway.SHUTTLE]: ShuttleData;
};

export interface PublicConnectDTO {
  name?: string;
  merchantId: string;
  gatewayId: string;
  gatewayName: Gateway;
  status: 'ACTIVE' | 'ARCHIVED' | 'DISABLED';
  data: Partial<GatewayDataTypes>;
  createdAt: Date;
  archivedAt?: Date;
  disabledAt?: Date;
  deleteAt?: Date;
  priority?: number;
  supportedCurrencies?: Currency[];
  supportedCountries?: Country[];
  paymentReady?: boolean;
}
