import type {FC} from 'react';
import React from 'react';
import type {MenuProps} from '@mui/material/Menu/Menu';
import {alpha, Menu, styled} from '@mui/material';

const StyledMenu: FC<React.PropsWithChildren<MenuProps>> = styled(
  (props: MenuProps) => (
    <Menu
      PaperProps={{
        elevation: 0,
        sx: {
          minWidth: '180px',
          maxWidth: '100%',
          overflow: 'visible',
          boxShadow: '0px 1px 10px 5px rgba(0,0,0,0.05)',
          mt: 1.5,
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 15,
            width: 10,
            height: 10,
            backgroundColor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{horizontal: 'right', vertical: 'top'}}
      anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      {...props}
    />
  )
)(({theme}) => ({
  '& .MuiPaper-root': {
    '& .MuiMenu-list': {
      padding: '0',
    },
    '& .MuiMenuItem-root': {
      padding: '10px',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        marginLeft: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default StyledMenu;
