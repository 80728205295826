import type {FC} from 'react';
import React from 'react';
import type {ButtonProps, CircularProgressProps} from '@mui/material';
import {Button} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
  progressProps?: CircularProgressProps;
}

const LoadingButton: FC<React.PropsWithChildren<LoadingButtonProps>> = ({
  loading = false,
  children,
  progressProps,
  ...props
}) => (
  <Button {...props} onClick={loading ? undefined : props.onClick}>
    {loading ? (
      <CircularProgress color="inherit" size={21} {...progressProps} />
    ) : (
      children
    )}
  </Button>
);

export default LoadingButton;
