import type {FC} from 'react';
import React from 'react';
import type {IconButtonProps} from '@mui/material';
import {styled, Tooltip} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';

const StyledIconButton = styled(IconButton)({
  width: '40px',
  height: '40px',
  border: '1px solid',
  borderColor: '#cacbd3',
  borderRadius: '10px',
  color: 'grey.700',
});

export const NavbarButton: FC<
  React.PropsWithChildren<IconButtonProps>
> = props => (
  <Tooltip title="menu" arrow>
    <StyledIconButton {...props}>
      <MenuIcon />
    </StyledIconButton>
  </Tooltip>
);
