import {MoneyUtils} from '@handsin/money';
import type {LineItem} from '@local/backend/@types/updated-api-types/payments/LineItem';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type {Theme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import type {FC} from 'react';
import React from 'react';

interface LineItemCardProps {
  lineItem: LineItem;
}

const LineItemCard: FC<React.PropsWithChildren<LineItemCardProps>> = ({
  lineItem,
}) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const lineItemImage = lineItem.item.imageUrls?.at(0);

  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: 1,
        p: 1,
        maxWidth: 400,
        minHeight: '100px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Stack direction="row" spacing={2} sx={{pt: 1}}>
        {lineItemImage && (
          <Avatar
            sx={{
              borderRadius: 2,
              width: '85px',
              height: '85px',
              position: 'relative',
            }}
          >
            <img
              src={lineItemImage}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                zIndex: 1,
              }}
              alt={lineItem.item.name}
            />
          </Avatar>
        )}
        <Stack>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 600,
              fontSize: 16,
              wordBreak: 'break-word',
              color: 'primary.main',
            }}
          >
            {lineItem.item.name}
          </Typography>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            spacing={isMobile ? 1 : 2}
            sx={{mt: 1}}
          >
            <Typography
              variant="caption"
              sx={{fontWeight: 600, color: 'primary.main', fontSize: 14}}
            >
              <Typography
                variant="caption"
                sx={{color: 'black.main', fontWeight: 500, fontSize: 14}}
                component="span"
              >
                Total:{' '}
              </Typography>
              {MoneyUtils.formatMoney(lineItem.totalMoney)}{' '}
              {lineItem.quantity > 1 && (
                <Typography variant="caption" sx={{fontWeight: 300}}>
                  ({MoneyUtils.formatMoney(lineItem.item.amountMoney)} each)
                </Typography>
              )}
            </Typography>
            <Typography
              variant="caption"
              sx={{color: 'primary.main', fontWeight: 600, fontSize: 14}}
            >
              <Typography
                variant="caption"
                sx={{color: 'black.main', fontWeight: 500, fontSize: 14}}
                component="span"
              >
                Qty:{' '}
              </Typography>{' '}
              X{lineItem.quantity}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

export default LineItemCard;
