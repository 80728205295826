import type {AlertColor} from '@mui/material/Alert';
import Alert from '@mui/material/Alert';
import type {TypographyProps} from '@mui/material/Typography';
import Typography from '@mui/material/Typography';
import type {FC} from 'react';
import React from 'react';

interface NoticeAlertProps extends TypographyProps {
  severity?: AlertColor;
  message?: string;
  fullWidth?: boolean;
}

const NoticeAlert: FC<React.PropsWithChildren<NoticeAlertProps>> = ({
  severity = 'info',
  message,
  fullWidth,
  children,
  ...props
}) => (
  <Alert
    severity={severity}
    sx={{width: fullWidth ? '100%' : 'auto', paperProps: {component: 'span'}}}
  >
    {message && (
      <Typography
        variant="body2"
        sx={{...props.sx, whiteSpace: 'pre-line'}}
        {...props}
      >
        {message}
      </Typography>
    )}
    {children}
  </Alert>
);

export default NoticeAlert;
