import type {FC} from 'react';
import React from 'react';
import {ToggleButton, ToggleButtonGroup, Tooltip} from '@mui/material';
import {
  useEnvironmentSession,
  useMerchant,
} from '@local/frontend/libs/trpc/trpc';
import {useChangeSessionEnvironment} from '@local/frontend/libs/api/session';
import {Env} from '../../../@types/env';
import config from '../../../config';
import {hasPaidBillingPlan} from '../../../utils/merchant-utils';

export const EnvironmentToggle: FC<React.PropsWithChildren<unknown>> = () => {
  const {data: merchant, isInitialLoading: isMerchantLoading} = useMerchant();
  const {data: environment, isInitialLoading: isEnvironmentInitialLoading} =
    useEnvironmentSession();

  const environmentMutation = useChangeSessionEnvironment();

  const handleToggle = (
    _event: React.MouseEvent<HTMLElement>,
    newEnvironment: Env | null
  ) => {
    if (newEnvironment) {
      environmentMutation.mutate(newEnvironment);
    }
  };

  const isDisabled =
    isMerchantLoading ||
    isEnvironmentInitialLoading ||
    environmentMutation.isLoading ||
    config.ENV === Env.DEVELOPMENT;

  return (
    <Tooltip
      arrow
      placement="top"
      title={(() => {
        if (config.ENV === Env.DEVELOPMENT) {
          return 'Feature disabled in developer mode';
        }
        if (merchant && !hasPaidBillingPlan(merchant)) {
          return 'Please upgrade to start taking live payments';
        }
        return '';
      })()}
    >
      <ToggleButtonGroup
        sx={{height: '20px'}}
        value={environment ?? Env.SANDBOX}
        exclusive
        onChange={handleToggle}
        disabled={isDisabled}
        aria-label="environment"
      >
        <ToggleButton
          disabled={isDisabled}
          key="toggle-sandbox-button"
          value={Env.SANDBOX}
          aria-label="sandbox"
          color="warning"
        >
          Sandbox
        </ToggleButton>
        <ToggleButton
          disabled={isDisabled || (merchant && !hasPaidBillingPlan(merchant))}
          key="toggle-production-button"
          value={Env.PRODUCTION}
          aria-label="live"
          color="primary"
        >
          Live
        </ToggleButton>
      </ToggleButtonGroup>
    </Tooltip>
  );
};
