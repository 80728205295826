import type {Currency, Money} from '@handsin/money';
import {LocalMoneyCalculator} from '@handsin/money';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import type {LineItem} from '@local/backend/@types/updated-api-types/payments/LineItem';
import type {FormCustomer} from '@local/frontend/components/forms/schemas';

interface CreateGroupPaymentPreviewParams {
  customers: FormCustomer[];
  splitType: string;
  expectedGroupSize?: number;
  currency: Currency;
  lineItems?: LineItem[];
  amountMoney?: Money;
}

const calculateGroupTotal = (
  currency: Currency,
  expectedGroupSize: number,
  splitType: string | undefined,
  lineItems?: LineItem[],
  amountMoney: Money = lineItems
    ?.reduce(
      (calc, lineItem) => calc.add(lineItem.totalMoney),
      new LocalMoneyCalculator({
        amount: 0,
        currency,
      })
    )
    .calculate() ?? {amount: 0, currency}
): Money => {
  if (splitType === 'FIXED_PRICE' && expectedGroupSize) {
    // in fixed price, the group total will be the total of all products * how many people are in the group
    return new LocalMoneyCalculator(amountMoney)
      .multiply(expectedGroupSize)
      .calculate();
  }

  // else we assume it's a equal split flow.
  return amountMoney;
};

export const createGroupPaymentPreview = (
  createGroupPaymentPreviewParams: CreateGroupPaymentPreviewParams
): Pick<GroupPaymentRecord, 'splitAllocation' | 'totalMoney'> => ({
  splitAllocation:
    createGroupPaymentPreviewParams.expectedGroupSize ??
    createGroupPaymentPreviewParams.customers.length,
  totalMoney: calculateGroupTotal(
    createGroupPaymentPreviewParams.currency,
    createGroupPaymentPreviewParams.expectedGroupSize ??
      createGroupPaymentPreviewParams.customers.length,
    createGroupPaymentPreviewParams.splitType,
    createGroupPaymentPreviewParams.lineItems,
    createGroupPaymentPreviewParams.amountMoney
  ),
});
