import type {FC} from 'react';
import React from 'react';
import type {AvatarProps} from '@mui/material';
import {Avatar} from '@mui/material';
import type {MerchantDTO} from '@local/backend/@types/updated-api-types/merchants/Merchant';

interface MerchantAvatarProps extends AvatarProps {
  merchant?: MerchantDTO;
}

export const MerchantAvatar: FC<
  React.PropsWithChildren<MerchantAvatarProps>
> = ({merchant, sx, ...props}) => (
  <Avatar
    variant="rounded"
    src={merchant?.logoUrl}
    alt={merchant?.name}
    imgProps={{
      sx: {
        objectFit: 'cover',
        bgcolor: 'white',
      },
    }}
    sx={{
      width: 40,
      height: 40,
      fontSize: 16,
      color: 'primary.dark',
      aspectRatio: '1/1',
      ...sx,
    }}
    {...props}
  >
    {merchant?.name
      ?.split(' ')
      .filter(name => name.length > 0)
      .slice(0, 3)
      .map(names => names[0].toUpperCase())
      .join('')}
  </Avatar>
);
