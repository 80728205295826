import type {FC} from 'react';
import React, {useState} from 'react';
import {Stack, styled} from '@mui/material';
import {Nav} from './nav/Nav';
import AppBar from './app-bar/AppBar';
import Footer from './footer/Footer';
import {Header} from './header/Header';
import {
  APP_BAR_DESKTOP,
  APP_BAR_MOBILE,
  FOOTER_DESKTOP,
  BANNER_DESKTOP,
  BANNER_MOBILE,
} from './dashboard-config';

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled(Stack)(({theme}) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + BANNER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + BANNER_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: FOOTER_DESKTOP,
  },
}));

const DashboardLayout: FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const [isNavOpen, setNavOpen] = useState(false);

  return (
    <StyledRoot>
      <Header />

      <Nav open={isNavOpen} onClose={() => setNavOpen(false)} />

      <AppBar onNavOpen={() => setNavOpen(true)} />

      <Main>
        {children}
        <Footer />
      </Main>
    </StyledRoot>
  );
};

export default DashboardLayout;
