import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import type {FC} from 'react';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {MuiTelInput, MuiTelInputCountry} from 'mui-tel-input';
import {Country} from '@handsin/api-node';
import FormFieldError from './FormFieldError';

interface UpdateCustomerFormProps {
  isEditing: boolean;
}
const UpdateCustomerForm: FC<
  React.PropsWithChildren<UpdateCustomerFormProps>
> = ({isEditing}) => {
  const methods = useFormContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Controller
          name="firstName"
          control={methods.control}
          render={({field}) => (
            <TextField
              {...field}
              variant="outlined"
              id="firstName"
              label="First Name"
              disabled={!isEditing}
              required
              placeholder="First Name"
              fullWidth
            />
          )}
        />
        <FormFieldError name="firstName" formState={methods.formState} />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="lastName"
          control={methods.control}
          render={({field}) => (
            <TextField
              {...field}
              variant="outlined"
              id="lastName"
              label="Last Name"
              disabled={!isEditing}
              placeholder="Last Name"
              fullWidth
            />
          )}
        />
        <FormFieldError name="lastName" formState={methods.formState} />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="email"
          control={methods.control}
          render={({field}) => (
            <TextField
              {...field}
              variant="outlined"
              id="email"
              label="Email"
              disabled={!isEditing}
              required
              placeholder="Email"
              fullWidth
            />
          )}
        />
        <FormFieldError name="email" formState={methods.formState} />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="phoneNumber"
          control={methods.control}
          render={({field}) => (
            <MuiTelInput
              {...field}
              disabled={!isEditing}
              onChange={(value, info) => {
                field.onChange(info.numberValue ?? value);
                if (info.countryCode) {
                  methods.setValue(
                    'phoneNumberCountry',
                    info.countryCode as Country
                  );
                }
              }}
              defaultCountry={
                methods.watch('phoneNumberCountry') as MuiTelInputCountry
              }
              onlyCountries={Object.values(Country) as MuiTelInputCountry[]}
              variant="outlined"
              fullWidth
              label="Phone Number"
              placeholder="+44 7924 357268"
              error={Boolean(methods.formState.errors[field.name])}
            />
          )}
        />
        <FormFieldError name="phoneNumber" formState={methods.formState} />
      </Grid>
    </Grid>
  );
};

export default UpdateCustomerForm;
