import type {FC} from 'react';
import React from 'react';
import {IconButton, Tooltip} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

export const Helper: FC<React.PropsWithChildren<{text: string}>> = ({text}) => (
  <Tooltip title={text} arrow placement="top" enterDelay={0}>
    <IconButton disableRipple>
      <HelpIcon fontSize="small" />
    </IconButton>
  </Tooltip>
);
