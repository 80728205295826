import type {FC} from 'react';
import React, {useEffect, useState} from 'react';
import {IconButton, Stack, Tooltip} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import type {TypographyProps} from '@mui/material/Typography';
import Typography from '@mui/material/Typography';
import DoneIcon from '@mui/icons-material/Done';
import {MAXIMUM_TIMEOUT_DELAY} from '@local/frontend/options';

async function copyTextToClipboard(text: string): Promise<void> {
  if ('clipboard' in navigator) {
    await navigator.clipboard.writeText(text);
  } else {
    document.execCommand('copy', true, text);
  }
}

export type CopyTextProps = TypographyProps & {
  timeout?: number;
  onCopy?: () => void;
  value: string;
};

const CopyText: FC<React.PropsWithChildren<CopyTextProps>> = ({
  timeout = 2000,
  onCopy,
  children,
  value,
  ...props
}) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const handleCopyClick = (e: Pick<MouseEvent, 'stopPropagation'>) => {
    e.stopPropagation();
    copyTextToClipboard(value)
      .then(() => {
        if (onCopy) {
          onCopy();
        }
        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        setIsCopied(true);
        const newTimeoutId = setTimeout(
          () => {
            setIsCopied(false);
          },
          Math.min(timeout, MAXIMUM_TIMEOUT_DELAY)
        );
        setTimeoutId(newTimeoutId);
      })
      .catch(err => console.error(err));
  };

  useEffect(
    () => () => {
      if (timeoutId) {
        // clear the timeout on unmount
        clearTimeout(timeoutId);
      }
    },
    [timeoutId]
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      maxWidth="100%"
      ref={ref => {
        if (!ref) return;

        // eslint-disable-next-line no-param-reassign
        ref.onclick = e => {
          handleCopyClick(e);
        };
      }}
      onClick={handleCopyClick}
    >
      <Typography {...props} component="span" variant="caption" noWrap>
        {children}
      </Typography>
      <Tooltip title={isCopied ? 'copied!' : 'copy'} enterTouchDelay={0}>
        <IconButton
          disableRipple
          disableTouchRipple
          disableFocusRipple
          sx={{p: 0}}
          size="small"
          color={isCopied ? 'primary' : 'inherit'}
        >
          {isCopied ? (
            <DoneIcon fontSize="inherit" />
          ) : (
            <FileCopyIcon fontSize="inherit" />
          )}
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default CopyText;
