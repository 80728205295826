import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import type {FC} from 'react';
import React from 'react';
import {Controller, useFormContext, useFormState} from 'react-hook-form';
import {MuiTelInput, MuiTelInputCountry} from 'mui-tel-input';
import {Country} from '@handsin/api-node';
import LoadingButton from '../atoms/buttons/LoadingButton';
import FormFieldError from './FormFieldError';

interface EditCustomerFormProps {
  loading: boolean;
  disabled?: boolean;
}

const EditCustomerForm: FC<React.PropsWithChildren<EditCustomerFormProps>> = ({
  loading,
  disabled = false,
}) => {
  const methods = useFormContext();
  const {isDirty, isValid, isSubmitting} = useFormState();

  return (
    <Grid container spacing={3} justifyContent="flex-end">
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="firstName"
              control={methods.control}
              render={({field}) => (
                <TextField
                  {...field}
                  variant="outlined"
                  id="firstName"
                  label="First Name"
                  required
                  placeholder="First Name"
                  fullWidth
                />
              )}
            />
            <FormFieldError name="firstName" formState={methods.formState} />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="lastName"
              control={methods.control}
              render={({field}) => (
                <TextField
                  {...field}
                  variant="outlined"
                  id="lastName"
                  label="Last Name"
                  placeholder="Last Name"
                  fullWidth
                />
              )}
            />
            <FormFieldError name="lastName" formState={methods.formState} />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="email"
              control={methods.control}
              render={({field}) => (
                <TextField
                  {...field}
                  variant="outlined"
                  id="email"
                  label="Email"
                  required
                  placeholder="Email"
                  fullWidth
                />
              )}
            />
            <FormFieldError name="email" formState={methods.formState} />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="phoneNumber"
              control={methods.control}
              render={({field}) => (
                <MuiTelInput
                  {...field}
                  onChange={(value, info) => {
                    field.onChange(info.numberValue ?? value);
                    if (info.countryCode) {
                      methods.setValue(
                        'phoneNumberCountry',
                        info.countryCode as Country
                      );
                    }
                  }}
                  defaultCountry={
                    methods.watch('phoneNumberCountry') as MuiTelInputCountry
                  }
                  onlyCountries={Object.values(Country) as MuiTelInputCountry[]}
                  variant="outlined"
                  fullWidth
                  label="Phone Number"
                  placeholder="+44 7924 357268"
                  error={Boolean(methods.formState.errors[field.name])}
                />
              )}
            />
            <FormFieldError name="phoneNumber" formState={methods.formState} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs="auto">
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          disabled={disabled || isSubmitting || !isDirty || !isValid}
          loading={isSubmitting || loading}
        >
          Update customer
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default EditCustomerForm;
