import type {AvatarProps} from '@mui/material';
import {Avatar} from '@mui/material';
import type {AccountDTO} from '@handsin/api-node';
import type {FC} from 'react';
import React from 'react';
import {stringToHexColor} from '../utils/custom-hash';
import {formatFullName} from '../utils/formatters';

interface UserAvatarProps extends AvatarProps {
  user: AccountDTO;
}

export const UserAvatar: FC<React.PropsWithChildren<UserAvatarProps>> = ({
  user,
  ...props
}) => {
  const fullName = formatFullName(user.firstName, user.lastName);
  return (
    <Avatar
      sx={{
        backgroundColor: stringToHexColor(fullName),
      }}
      {...props}
    >
      {user.firstName && user.firstName.length > 0
        ? user.firstName[0]
        : undefined}
      {user.lastName && user.lastName.length > 0 ? user.lastName[0] : undefined}
    </Avatar>
  );
};
