import {Env} from '../@types/env';

export default {
  ENV: (() => {
    switch (process.env.REACT_APP_ENV) {
      case 'production':
        return Env.PRODUCTION;
      case 'sandbox':
        return Env.SANDBOX;
      case 'development':
      default:
        return Env.DEVELOPMENT;
    }
  })(),
  AUTH_URL:
    process.env.REACT_APP_AUTH_URL ?? 'https://staging.auth.handsin.com',
};
