import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import type {FC} from 'react';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {MuiTelInput, type MuiTelInputCountry} from 'mui-tel-input';
import {Country} from '@handsin/api-node';
import LoadingButton from '../atoms/buttons/LoadingButton';
import FormFieldError from './FormFieldError';

interface AddCustomerFormProps {
  loading: boolean;
  disabled?: boolean;
}

const AddCustomerForm: FC<React.PropsWithChildren<AddCustomerFormProps>> = ({
  loading,
  disabled = false,
}) => {
  const methods = useFormContext();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="firstName"
              control={methods.control}
              render={({field}) => (
                <TextField
                  {...field}
                  variant="outlined"
                  id="firstName"
                  label="First Name"
                  required
                  placeholder="First Name"
                  fullWidth
                />
              )}
            />
            <FormFieldError name="firstName" formState={methods.formState} />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="lastName"
              control={methods.control}
              render={({field}) => (
                <TextField
                  {...field}
                  variant="outlined"
                  id="lastName"
                  label="Last Name"
                  placeholder="Last Name"
                  fullWidth
                />
              )}
            />
            <FormFieldError name="lastName" formState={methods.formState} />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="email"
              control={methods.control}
              render={({field}) => (
                <TextField
                  {...field}
                  variant="outlined"
                  id="email"
                  label="Email"
                  placeholder="Email"
                  fullWidth
                />
              )}
            />
            <FormFieldError name="email" formState={methods.formState} />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="phoneNumber"
              control={methods.control}
              render={({field}) => (
                <MuiTelInput
                  {...field}
                  onChange={(value, info) => {
                    field.onChange(info.numberValue ?? value);
                    if (info.countryCode) {
                      methods.setValue(
                        'phoneNumberCountry',
                        info.countryCode as Country
                      );
                    }
                  }}
                  defaultCountry={
                    methods.watch('phoneNumberCountry') as MuiTelInputCountry
                  }
                  onlyCountries={Object.values(Country) as MuiTelInputCountry[]}
                  variant="outlined"
                  fullWidth
                  label="Phone Number"
                  placeholder="+44 7924 357268"
                  error={Boolean(methods.formState.errors[field.name])}
                />
              )}
            />
            <FormFieldError name="phoneNumber" formState={methods.formState} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm="auto" sx={{ml: 'auto'}}>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          disabled={
            disabled ||
            methods.formState.isSubmitting ||
            !methods.formState.isDirty ||
            !methods.formState.isValid
          }
          loading={methods.formState.isSubmitting || loading}
        >
          Add customer
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default AddCustomerForm;
