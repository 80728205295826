import type {FC} from 'react';
import React from 'react';
import List from '@mui/material/List';
import {MerchantScope} from '@local/backend/@types/scopes/MerchantScopes';
import useCanAccess from '@local/frontend/hooks/useCanAccess';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {formatFullName} from '@local/frontend/utils/formatters';
import sidebarPages from './config';
import {EnvironmentToggle} from './components/EnvironmentToggle';
import MerchantSelect from './components/MerchantSelect/MerchantSelect';
import NavItem from './components/NavItem';
import {useGetTeamMember, useMerchant} from '../../libs/trpc/trpc';
import {hasPaidBillingPlan} from '../../utils/merchant-utils';

const NavContent: FC<React.PropsWithChildren<unknown>> = () => {
  const {data: merchant} = useMerchant();
  const {data: merchantAccountOwner} = useGetTeamMember(
    merchant?.ownerId
      ? {
          accountId: merchant.ownerId,
        }
      : undefined
  );

  const canAccessBilling = useCanAccess([MerchantScope.ALL]);

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        p: {lg: 2},
      }}
    >
      <Paper
        sx={{
          height: '100%',
          width: '100%',
          backgroundColor: 'primary.lighter',
          display: 'flex',
          borderRadius: {md: 0, lg: '2rem'},
          paddingY: '2rem',
        }}
      >
        <Stack width="100%" alignItems="center" spacing={2}>
          {/* top */}
          <Stack direction="column" alignItems="center" spacing={3}>
            <EnvironmentToggle />
            <MerchantSelect />
          </Stack>

          {/* middle */}
          <Stack
            height="100%"
            width="100%"
            sx={{overflowX: 'hidden', overflowY: 'auto'}}
            justifyContent="space-between"
          >
            <List sx={{width: '100%'}}>
              {sidebarPages.map(page => (
                <NavItem key={page.id} route={page} />
              ))}
            </List>

            {/*  bottom  */}
            {merchant && !hasPaidBillingPlan(merchant) && (
              <Box sx={{px: 2.5, pb: 3}}>
                <Stack
                  alignItems="center"
                  sx={{borderRadius: 2, position: 'relative'}}
                >
                  <Typography variant="h6" gutterBottom>
                    Want to go live now?
                  </Typography>

                  {canAccessBilling ? (
                    <Button href="/dashboard/billing" variant="contained">
                      Upgrade your plan
                    </Button>
                  ) : (
                    <Typography variant="body2" textAlign="center">
                      Contact{' '}
                      <Typography
                        variant="body2"
                        sx={{fontWeight: 600}}
                        component="span"
                      >
                        {merchantAccountOwner
                          ? formatFullName(
                              merchantAccountOwner.firstName,
                              merchantAccountOwner.lastName
                            )
                          : 'the merchant team owner'}{' '}
                      </Typography>
                      to go live
                    </Typography>
                  )}
                </Stack>
              </Box>
            )}
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
};

export default NavContent;
