import Grid from '@mui/material/Grid';
import type {FC} from 'react';
import React from 'react';
import NoticeAlert from './NoticeAlert';

interface ValidationErrorProps {
  message: string;
}

const ValidationError: FC<React.PropsWithChildren<ValidationErrorProps>> = ({
  message,
}) => (
  <Grid
    container
    maxHeight={100}
    alignItems="center"
    xs={12}
    item
    justifyContent="center"
  >
    <NoticeAlert message={message} severity="error" />
  </Grid>
);

export default ValidationError;
