import type {FC} from 'react';
import React from 'react';
import {Navigate} from 'react-router-dom';
import {MerchantScope} from '@local/backend/@types/scopes/MerchantScopes';
import Loading from '../components/atoms/Loading';
import {
  isUnauthorizedError,
  useMerchant,
  useGetUser,
  useMerchantPermissions,
  isForbiddenError,
} from '../libs/trpc/trpc';
import config from '../config';

const PrivateMerchantRoute: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const {
    data: user,
    isInitialLoading: isUserLoading,
    error: userError,
  } = useGetUser(undefined, {
    onError: err => {
      if (isUnauthorizedError(err)) {
        const url = `${window.location.origin}/callback`;
        window.location.href = `${config.AUTH_URL}/login?redirect=${url}`;
      }
    },
  });

  const {
    data: merchantPermissions,
    isInitialLoading: isMerchantPermissionsLoading,
    error: merchantPermissionsError,
  } = useMerchantPermissions();

  const {
    data: merchant,
    isInitialLoading: isMerchantLoading,
    error: merchantError,
  } = useMerchant();

  if (isUserLoading || isMerchantLoading || isMerchantPermissionsLoading) {
    return <Loading />;
  }

  /** Show loading as user will be getting redirected to Auth Service */
  if (userError) {
    if (isUnauthorizedError(userError)) {
      return <Loading />;
    }
    throw new Error(userError.data?.code);
  }

  if (merchantError) {
    if (isUnauthorizedError(merchantError)) {
      return <Navigate to="/user/select" replace />;
    }
    if (isForbiddenError(merchantError)) {
      return (
        <Navigate
          to="/403"
          replace
          state={{
            message: `The account you are logged in with ${
              user ? `(${user.email})` : ''
            } is not a part of this account's team`,
          }}
        />
      );
    }
    throw new Error(merchantError.data?.code);
  }

  if (merchantPermissionsError) {
    if (isUnauthorizedError(merchantPermissionsError)) {
      return <Navigate to="/user/select" replace />;
    }

    throw new Error(merchantPermissionsError.data?.code);
  }

  if (!user) {
    throw new Error(
      'Oops... We are running into some issues fetching your user data. Please contact us if this issue continues to persist'
    );
  }

  if (!merchant) {
    throw new Error(
      'Oops... We are running into some issues fetching your account data. Please contact us if this issue continues to persist'
    );
  }

  // check if user does not have read permissions for this merchant
  if (
    !merchantPermissions ||
    !merchantPermissions.includes(MerchantScope.READ)
  ) {
    const message = 'You have insufficient permissions to view this page';

    return <Navigate to="/403" replace state={{message}} />;
  }

  return children;
};

export default PrivateMerchantRoute;
