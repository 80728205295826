import type {CSSProperties, FC} from 'react';
import React from 'react';
import type {TooltipProps} from '@mui/material/Tooltip';
import Tooltip from '@mui/material/Tooltip';

interface CustomTooltipProps extends TooltipProps {
  enabled?: boolean;
  style?: CSSProperties;
}

const CustomTooltip: FC<React.PropsWithChildren<CustomTooltipProps>> = ({
  enabled = false,
  style,
  children,
  title,
  ...props
}) => (
  <Tooltip title={enabled ? title : ''} arrow enterTouchDelay={0} {...props}>
    <span style={style}>{children}</span>
  </Tooltip>
);

export default CustomTooltip;
