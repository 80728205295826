import type {FC} from 'react';
import React from 'react';
import UpdateWebhookForm from '../forms/UpdateWebhookForm';

export interface ManageWebhookModalProps {
  webhookEndpointId: string;
}

const ManageWebhookModal: FC<
  React.PropsWithChildren<ManageWebhookModalProps>
> = ({webhookEndpointId}) => (
  <UpdateWebhookForm webhookEndpointId={webhookEndpointId} />
);

export default ManageWebhookModal;
