import {HandsInPackagePlan} from '@hands-in/billing';
import type {PaymentMethod} from '@stripe/stripe-js/types/api/payment-methods';
import * as Yup from 'yup';

export const billingPlanSchema = Yup.object({
  billingPlanId: Yup.mixed<HandsInPackagePlan>()
    .oneOf(Object.values(HandsInPackagePlan))
    .required(),
  paymentMethod: Yup.mixed<PaymentMethod>().when('billingPlanId', {
    is: (val?: string) => !!val,
    then: schema => schema.required('You must add a payment method'),
    otherwise: schema => schema.notRequired(),
  }),
});
