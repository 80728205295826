import type {AlertColor} from '@mui/material/Alert';
import type {QueryClient, UseQueryResult} from '@tanstack/react-query';
import {useQuery} from '@tanstack/react-query';
import type {
  NotifcationQueryParams,
  NotificationQueryData,
} from './notification-types';

const getDefaultTimeToShow = (severity: AlertColor): number => {
  switch (severity) {
    case 'error':
      return 8000;
    case 'success':
    case 'info':
    case 'warning':
    default:
      return 5000;
  }
};

// @TODO: a way to improve notification system would be to allow a FIFO list. To allow multiple to <stack>. (nice-to-have atm)
export const closeNotification = (queryClient: QueryClient): void => {
  queryClient.setQueryData<NotificationQueryData>(['notificationAlert'], {
    isShowing: false,
    message: '',
    severity: 'success',
    timeToShow: 5000,
  });
};

export const openNotification = (
  queryClient: QueryClient,
  {
    message,
    severity,
    timeToShow = getDefaultTimeToShow(severity),
  }: NotifcationQueryParams
): void => {
  queryClient.setQueryData<NotificationQueryData>(['notificationAlert'], {
    isShowing: true,
    message,
    severity,
    timeToShow,
  });
};

export const useInitialiseNotifications = (): UseQueryResult<
  NotificationQueryData,
  unknown
> =>
  useQuery<NotificationQueryData>(
    ['notificationAlert'],
    () => ({
      isShowing: false,
      message: '',
      severity: 'success',
      timeToShow: 5000,
    }),
    {
      refetchOnWindowFocus: false,
    }
  );
