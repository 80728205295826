import type {FC} from 'react';
import React, {useEffect, useState} from 'react';
import * as yup from 'yup';
import {Controller, useForm} from 'react-hook-form';
import {Grid, TextField, Stack} from '@mui/material';
import {yupResolver} from '@hookform/resolvers/yup';
import {useQueryClient} from '@tanstack/react-query';
import {useNotification} from '@local/frontend/hooks/useNotification';
import type {WebhookEndpointUpdateParams} from '@local/backend/routes/webhooks.controller';
import {EventType} from '@local/backend/@types/updated-api-types/webhooks/EventType';
import {getQueryKey} from '@trpc/react-query';
import {useNavigate} from 'react-router-dom';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import {useCustomModals} from '@local/frontend/libs/modals/useModals';
import WebhookSelector from '../atoms/input/WebhookSelector';
import {
  trpc,
  useGetWebhookEndpoint,
  useUpdateWebhookEndpoint,
} from '../../libs/trpc/trpc';
import CustomConfirmationModal from '../atoms/CustomConfirmationModal';
import LoadingButton from '../atoms/buttons/LoadingButton';

const schema = yup.object({
  name: yup.string().min(1).max(100).required(),
  url: yup.string().min(7).required(),
  events: yup
    .array(yup.mixed<EventType>().oneOf(Object.values(EventType)).required())
    .required(),
});

interface UpdateWebhookFormProps {
  webhookEndpointId: string;
}

const UpdateWebhookForm: FC<
  React.PropsWithChildren<UpdateWebhookFormProps>
> = ({webhookEndpointId}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();
  const {closeModal} = useCustomModals();

  const [showConfirmRemoveWebhookDialog, setShowConfirmRemoveWebhookDialog] =
    useState(false);

  const {data: existingEndpoint, isInitialLoading: isExistingEndpointLoading} =
    useGetWebhookEndpoint({webhookEndpointId});

  const {
    formState: {isValid, isSubmitting, isDirty},
    ...formMethods
  } = useForm<yup.InferType<typeof schema>>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      url: '',
      events: [],
    },
  });

  useEffect(() => {
    if (existingEndpoint) {
      formMethods.reset({
        events: existingEndpoint.events,
        name: existingEndpoint.name,
        url: existingEndpoint.url,
      });
    }
  }, [existingEndpoint]);

  const updateWebhookMutation = useUpdateWebhookEndpoint({
    onMutate: () => {
      closeModal(ModalName.UPDATE_WEBHOOK);
    },
    onSuccess: async () => {
      openNotification({
        message: 'Webhook Endpoint Updated',
        severity: 'success',
      });

      await queryClient.invalidateQueries(getQueryKey(trpc.webhooks));
    },
    onError: err => {
      openNotification({
        message:
          err.data?.axiosError?.response?.data.detail ?? 'Something went wrong',
        severity: 'error',
      });
    },
  });

  const onSubmit = (
    webhookEndpointUpdateParams: WebhookEndpointUpdateParams
  ) => {
    updateWebhookMutation.mutate({
      webhookEndpointId,
      updateParams: webhookEndpointUpdateParams,
    });
  };

  const removeWebhookMutation = trpc.webhooks.remove.useMutation({
    onMutate: () => {
      closeModal(ModalName.UPDATE_WEBHOOK);
    },
    onSuccess: async () => {
      openNotification({
        message: 'Webhook Endpoint Removed',
        severity: 'success',
      });

      await queryClient.invalidateQueries(getQueryKey(trpc.webhooks));

      navigate('/dashboard/developers/webhooks');
    },
    onError: err => {
      openNotification({
        message:
          err.data?.axiosError?.response?.data.detail ?? 'Something went wrong',
        severity: 'error',
      });
    },
  });

  const removeWebhookEndpoint = () =>
    removeWebhookMutation.mutate({
      webhookEndpointId,
    });

  return (
    <>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Grid container direction="column" rowSpacing={2}>
          <Grid item xs={12}>
            <Controller
              control={formMethods.control}
              name="name"
              defaultValue=""
              render={({field, fieldState}) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label="Webhook Name"
                  placeholder="My Webhook"
                  type="text"
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={formMethods.control}
              name="url"
              defaultValue=""
              render={({field, fieldState}) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label="URL"
                  placeholder="https://www.example.com/webhooks"
                  type="text"
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={formMethods.control}
              name="events"
              defaultValue={[]}
              render={({field, fieldState}) => (
                <WebhookSelector
                  {...field}
                  variant="outlined"
                  fullWidth
                  required
                  label="Select Events"
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            <Stack direction="row" spacing={2}>
              <LoadingButton
                loading={removeWebhookMutation.isLoading}
                disabled={
                  removeWebhookMutation.isLoading ||
                  updateWebhookMutation.isLoading ||
                  isSubmitting ||
                  !isValid
                }
                variant="outlined"
                onClick={() => setShowConfirmRemoveWebhookDialog(true)}
              >
                Remove
              </LoadingButton>
              <LoadingButton
                loading={
                  updateWebhookMutation.isLoading || isExistingEndpointLoading
                }
                disabled={
                  updateWebhookMutation.isLoading ||
                  removeWebhookMutation.isLoading ||
                  isSubmitting ||
                  !isValid ||
                  !isDirty
                }
                variant="contained"
                type="submit"
              >
                Update
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
      <CustomConfirmationModal
        open={showConfirmRemoveWebhookDialog}
        title="Remove Webhook Endpoint"
        buttonActions={{
          reject: 'Cancel',
          accept: 'Confirm',
        }}
        loading={removeWebhookMutation.isLoading}
        description="You are about to remove a webhook endpoint, this action can't be undone. Are you sure you want to continue?"
        onClose={() => setShowConfirmRemoveWebhookDialog(false)}
        onConfirm={removeWebhookEndpoint}
      />
    </>
  );
};

export default UpdateWebhookForm;
