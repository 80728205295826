import type {Customer} from '@local/backend/@types/updated-api-types/customers/customer';
import ActionIconButton from '@local/frontend/components/atoms/ActionIconButton';
import CustomerCardContent from '@local/frontend/components/atoms/CustomerCardContent';
import type {CardProps} from '@mui/material/Card';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import type {FC} from 'react';
import React from 'react';
import {EmailOutlined} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import SmsIcon from '@mui/icons-material/Sms';

interface CustomerCardProps extends CardProps {
  customer: Customer;
  onView?: () => void;
  onEdit?: (customer: Customer) => void;
  onEmailRemind?: (customer: Customer) => void;
  onSmsRemind?: (customer: Customer) => void;
  isEmailLoading?: boolean;
  isSmsLoading?: boolean;
}

const CustomerCard: FC<React.PropsWithChildren<CustomerCardProps>> = ({
  customer,
  onView,
  onEdit,
  onEmailRemind,
  onSmsRemind,
  isEmailLoading,
  isSmsLoading,
  ...props
}) => {
  const isLoading = isEmailLoading || isSmsLoading;

  return (
    <Card
      variant="outlined"
      {...props}
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        ...props.sx,
      }}
    >
      <CardHeader title="Customer Information" sx={{color: 'primary.main'}} />
      <CardContent>
        <CustomerCardContent customer={customer} />
      </CardContent>
      <CardActions sx={{display: 'flex', justifyContent: 'flex-end'}}>
        {onView && (
          <ActionIconButton
            title="View Details"
            icon={<PersonSearchIcon fontSize="inherit" />}
            onClick={onView}
            disabled={isLoading}
          />
        )}
        {onEdit && (
          <ActionIconButton
            title="Edit Details"
            icon={<EditIcon fontSize="inherit" />}
            onClick={() => onEdit(customer)}
            disabled={isLoading}
          />
        )}
        {onEmailRemind && (
          <ActionIconButton
            title="Send Email Payment Reminder"
            icon={<EmailOutlined fontSize="inherit" />}
            onClick={() => onEmailRemind(customer)}
            loading={isEmailLoading}
            disabled={isLoading}
          />
        )}
        {onSmsRemind && (
          <ActionIconButton
            title="Send SMS Payment Reminder"
            icon={<SmsIcon fontSize="inherit" />}
            onClick={() => onSmsRemind(customer)}
            loading={isSmsLoading}
            disabled={isLoading}
          />
        )}
      </CardActions>
    </Card>
  );
};

export default CustomerCard;
