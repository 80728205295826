import type {FC} from 'react';
import React from 'react';
import {
  Controller,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {useQueryClient} from '@tanstack/react-query';
import {useNotification} from '@local/frontend/hooks/useNotification';
import StripePaymentMethodForm from '../../../pages/user/onboarding/confirm/components/StripePaymentMethodForm';
import FormFieldError from '../../forms/FormFieldError';
import LoadingButton from '../../atoms/buttons/LoadingButton';
import type {UpgradeBillingPlanFormDataType} from './@types';
import PaymentMethodDetailsList from '../../../pages/user/onboarding/confirm/sections/PaymentMethodDetailsList';
import {trpc} from '../../../libs/trpc/trpc';

const useAddPaymentMethodMutation = trpc.billing.addPaymentMethod.useMutation;
const useRemovePaymentMethodMutation =
  trpc.billing.removePaymentMethod.useMutation;

const BillingPlansPaymentForm: FC<React.PropsWithChildren<unknown>> = () => {
  const queryClient = useQueryClient();
  const formState = useFormState();
  const {open: openNotification} = useNotification();
  const formMethods = useFormContext<UpgradeBillingPlanFormDataType>();
  const formPaymentMethod = useWatch({
    control: formMethods.control,
    name: 'paymentMethod',
  });

  const addCardMutation = useAddPaymentMethodMutation({
    onSuccess: async () => {
      openNotification({
        message: 'Card added successfully',
        severity: 'success',
      });
      await queryClient.invalidateQueries(
        trpc.billing.getPaymentMethods.getQueryKey()
      );
    },
    onError: err => {
      openNotification({
        message: err.message ?? 'Failed to add payment method',
        severity: 'error',
      });
    },
  });

  const removeCardMutation = useRemovePaymentMethodMutation({
    onSuccess: async () => {
      openNotification({
        message: 'Payment method removed successfully',
        severity: 'success',
      });

      await queryClient.invalidateQueries(
        trpc.billing.getPaymentMethods.getQueryKey()
      );
    },
    onError: err => {
      openNotification({
        message: err.message ?? 'Failed to remove payment method',
        severity: 'error',
      });
    },
  });

  if (formPaymentMethod) {
    return (
      <Stack direction="column">
        <PaymentMethodDetailsList {...formPaymentMethod} />
        <Button
          onClick={() => {
            removeCardMutation.mutate({
              paymentMethodId: formPaymentMethod.id,
            });

            formMethods.setValue('paymentMethod', undefined);
          }}
        >
          Change
        </Button>
      </Stack>
    );
  }

  return (
    <Controller
      control={formMethods.control}
      name="paymentMethod"
      render={({field}) => (
        <StripePaymentMethodForm
          ref={field.ref}
          name={field.name}
          onBlur={field.onBlur}
          onChange={paymentMethod => {
            if (paymentMethod) {
              addCardMutation.mutate({
                paymentMethodId: paymentMethod.id,
              });

              field.onChange(paymentMethod);
            }
          }}
          setError={message => formMethods.setError('paymentMethod', {message})}
          render={({buttonProps, CardElement}) => (
            <Stack direction="column">
              <CardElement
                style={{
                  marginBottom: 5,
                  borderColor: formState.errors.paymentMethod
                    ? 'red'
                    : undefined,
                }}
              />
              <FormFieldError formState={formState} name="paymentMethod" />
              <LoadingButton {...buttonProps}>Add card</LoadingButton>
            </Stack>
          )}
        />
      )}
    />
  );
};

export default BillingPlansPaymentForm;
