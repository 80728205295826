import type {MerchantScopes} from '@local/backend/@types/scopes/MerchantScopes';
import {useMerchantPermissions} from '../libs/trpc/trpc';

const usePermissions = (): MerchantScopes[] => {
  const {data: userPermissions} = useMerchantPermissions();

  if (!userPermissions) {
    throw new Error(
      'usePermissions requires the PrivateMerchantRoute provider to be wrapped around it'
    );
  }

  return userPermissions;
};

export default usePermissions;
