import React from 'react';
import {createRoot} from 'react-dom/client';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import {httpBatchLink} from '@trpc/client';
import {AxiosError} from 'axios';
import superjson from 'superjson';
import {HttpStatusCode} from '@local/backend/@types/updated-api-types/errors/HttpStatusCode';
import {
  init,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
  startSpan,
  captureException,
  addBreadcrumb,
} from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import {PostHogProvider} from 'posthog-js/react';
import {BACKEND_CONFIG} from './config/backend';
import {trpc} from './libs/trpc/trpc';
import App from './App';
import {SENTRY_CONFIG} from './config/sentry';
import config from './config';
import {POSTHOG_CONFIG} from './config/posthog';

dayjs.extend(LocalizedFormat);
dayjs.extend(advancedFormat);

init({
  dsn: SENTRY_CONFIG.SENTRY_DSN,
  environment: config.ENV,
  replaysSessionSampleRate: config.ENV !== 'development' ? 1 : 0, // don't record sessions
  replaysOnErrorSampleRate: config.ENV !== 'development' ? 1 : 0, // only record staging/sandbox/production sessions with errors
  integrations: [
    reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
      instrumentNavigation: true,
      instrumentPageLoad: true,
      traceFetch: true,
      traceXHR: true,
    }),
    replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error): boolean =>
        failureCount < 3 &&
        error instanceof AxiosError &&
        (error.response?.status === HttpStatusCode.TOO_MANY_REQUESTS ||
          error.response?.status === HttpStatusCode.CONFLICT),
    },
  },
});
const trpcClient = trpc.createClient({
  links: [
    httpBatchLink({
      url: `${BACKEND_CONFIG.URL}/trpc`,
      fetch(url, options) {
        return startSpan(
          {
            name: url instanceof URL ? url.href : url.toString(),
            op: 'http.client',
          },
          async span => {
            addBreadcrumb({
              level: 'info',
              message: `making request to ${url}`,
              data: options,
            });

            const res = await fetch(url, {
              ...options,
              credentials: 'include',
            });

            span?.setAttribute('http.status_code', res?.status);

            if (!res.ok) {
              captureException(
                new Error(
                  `HTTP Request Failed: ${res.status} ${res.statusText}`
                )
              );
            }

            return res;
          }
        );
      },
    }),
  ],
  transformer: superjson,
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={POSTHOG_CONFIG.POSTHOG_KEY}
      options={{
        api_host: POSTHOG_CONFIG.POSTHOG_HOST,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
          <App />
        </trpc.Provider>
      </QueryClientProvider>
    </PostHogProvider>
  </React.StrictMode>
);
