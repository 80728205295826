import type {FC} from 'react';
import React from 'react';
import {CircularProgress, Stack} from '@mui/material';

const LoadingModal: FC<React.PropsWithChildren<unknown>> = () => (
  <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
    <CircularProgress />
  </Stack>
);

export default LoadingModal;
