import {MerchantScope} from '@local/backend/@types/scopes/MerchantScopes';
import Loading from '@local/frontend/components/atoms/Loading';
import IfHasPermissions from '@local/frontend/hocs/IfHasPermissions';
import React, {Suspense, lazy} from 'react';
import type {RouteObject} from 'react-router-dom';
import {Navigate, Outlet} from 'react-router-dom';

const ConnectPageLayout = lazy(() => import('./layouts/ConnectPageLayout'));
const InstalledGatewaysPage = lazy(
  () => import('./installed/InstalledGatewaysPage')
);
const GatewaysCataloguePage = lazy(
  () => import('./catalogue/GatewaysCataloguePage')
);
const ArchivedGatewaysPage = lazy(
  () => import('./archived/ArchivedGatewaysPage')
);

export const connectRoutes: RouteObject = {
  path: 'connect',
  element: (
    <IfHasPermissions
      thenRender={() => (
        <ConnectPageLayout>
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        </ConnectPageLayout>
      )}
      permissions={[MerchantScope.UPDATE]}
    />
  ),
  children: [
    {
      index: true,
      element: <Navigate to="installed" replace />,
    },
    {
      path: 'installed',
      element: <InstalledGatewaysPage />,
    },
    {
      path: 'catalogue',
      element: <GatewaysCataloguePage />,
    },
    {
      path: 'archived',
      element: <ArchivedGatewaysPage />,
    },
  ],
};
