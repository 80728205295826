import {useQueryClient} from '@tanstack/react-query';
import {trpc, useRefundPayment} from '@local/frontend/libs/trpc/trpc';
import {getQueryKey} from '@trpc/react-query';
import {useNotification} from '@local/frontend/hooks/useNotification';
import {MoneyUtils} from '@handsin/money';
import {removeRefundIdempKey} from '../../../utils/idemp-key-handlers';

export const useRefundPaymentMutation = () => {
  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();

  const refundPaymentMutation = useRefundPayment({
    onSuccess: async refundRecord => {
      await queryClient.invalidateQueries(getQueryKey(trpc.groupPayments));

      await queryClient.invalidateQueries(getQueryKey(trpc.multiCardPayments));

      await queryClient.invalidateQueries(getQueryKey(trpc.payments));

      await queryClient.invalidateQueries(getQueryKey(trpc.refunds));

      openNotification({
        message: `Refunded ${MoneyUtils.formatMoney(refundRecord.amountMoney)}`,
        severity: 'success',
      });
    },
    onError: () => {
      openNotification({
        message: 'Failed to create refund',
        severity: 'error',
      });
    },
    onSettled: (_, __, refundPaymentMutationParams) => {
      // clear refund idemp key regardless of success/failure
      removeRefundIdempKey(refundPaymentMutationParams.paymentId);
    },
  });
  return refundPaymentMutation;
};
