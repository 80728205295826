import type {FieldValuesFromFieldErrors} from '@hookform/error-message';
import {ErrorMessage} from '@hookform/error-message';
import Typography from '@mui/material/Typography';
import React from 'react';
import type {
  FieldErrors,
  FieldName,
  FieldValues,
  FormState,
} from 'react-hook-form';

interface FormFieldErrorProps<TFieldValues extends FieldValues> {
  name: FieldName<FieldValuesFromFieldErrors<FieldErrors<TFieldValues>>>;
  formState: FormState<TFieldValues>;
}

const FormFieldError = <TFieldValues extends FieldValues>({
  name,
  formState,
}: FormFieldErrorProps<TFieldValues>) => (
  <ErrorMessage
    name={name}
    errors={formState.errors}
    render={({message}) => (
      <Typography variant="caption" color="error">
        {message}
      </Typography>
    )}
  />
);

export default FormFieldError;
