import {useQueryClient} from '@tanstack/react-query';
import type {ComponentProps} from 'react';
import type {DialogProps} from '@mui/material';
import type {ModalConfigType} from './config';
import type {ModalQueryData} from './ModalQueryData';

export const useCustomModals = (): {
  openModal: <T extends keyof ModalConfigType>(
    name: T,
    vars: ComponentProps<ModalConfigType[T]['Element']>,
    props?: Omit<DialogProps, 'open'>
  ) => void;
  closeModal: <T extends keyof ModalConfigType>(name: T) => void;
} => {
  const queryClient = useQueryClient();

  const openModal = <T extends keyof ModalConfigType>(
    name: T,
    vars: ComponentProps<ModalConfigType[T]['Element']>,
    props?: Omit<DialogProps, 'open'>
  ): void => {
    queryClient.setQueryData<ModalQueryData>(
      ['modalConfig', name],
      oldData => ({
        name,
        props: {...oldData?.props, ...props, open: true},
        vars,
      })
    );
  };

  const closeModal = <T extends keyof ModalConfigType>(name: T): void => {
    queryClient.setQueryData<ModalQueryData>(
      ['modalConfig', name],
      oldData => ({
        name,
        props: {...oldData?.props, open: false},
        vars: undefined,
      })
    );
  };

  return {
    openModal,
    closeModal,
  };
};
