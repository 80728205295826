import config from '@local/frontend/config';
import backendClient from '@local/frontend/config/backend';
import {useMutation} from '@tanstack/react-query';
import {usePostHog} from 'posthog-js/react';

export const useLogoutMutation = () => {
  const posthog = usePostHog();

  return useMutation(
    async () => {
      const res = await backendClient.post('/auth/logout');
      return res.data;
    },
    {
      onSuccess: () => {
        posthog.reset();

        const url = `${window.location.origin}/callback`;
        window.location.href = `${config.AUTH_URL}/logout?redirect=${url}`;
      },
    }
  );
};
