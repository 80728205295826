import type {Customer} from '@handsin/api-node';
import type {MutationOptions} from '@tanstack/react-query';
import {useQueryClient} from '@tanstack/react-query';
import type {
  TrpcError,
  TrpcRouterInputs,
  TrpcRouterOutputs,
} from '@local/frontend/libs/trpc/trpc';
import {
  trpc,
  useCreateCustomer,
  useUpdateCustomer,
} from '@local/frontend/libs/trpc/trpc';
import {getQueryKey} from '@trpc/react-query';
import {useNotification} from '@local/frontend/hooks/useNotification';
import {formatFullName} from '../../../utils/formatters';

export const useCreateCustomerMutation = (
  mutationOptions?: MutationOptions<
    TrpcRouterOutputs['customers']['create'],
    TrpcError,
    TrpcRouterInputs['customers']['create']
  >
) => {
  const {open: openNotification} = useNotification();

  return useCreateCustomer({
    onMutate: mutationOptions?.onMutate,
    onSuccess: (customer, variables, ctx) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(customer, variables, ctx);
      }

      openNotification({
        message: `New Customer ${formatFullName(
          customer.firstName,
          customer?.lastName
        )} has been created`,
        severity: 'success',
      });
    },
    onError: (err, variables, ctx) => {
      if (mutationOptions?.onError) {
        mutationOptions.onError(err, variables, ctx);
      }

      openNotification({
        message:
          err.data?.axiosError?.response?.data?.detail ??
          'Failed to create new customer',
        severity: 'error',
      });
    },
    onSettled: (customer, error, variables, ctx) => {
      if (mutationOptions?.onSettled) {
        mutationOptions.onSettled(customer, error, variables, ctx);
      }
    },
  });
};

export const useUpdateCustomerMutation = () => {
  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();

  const updateCustomerMutation = useUpdateCustomer({
    onSuccess: async (customer: Customer) => {
      await queryClient.invalidateQueries(getQueryKey(trpc.customers));

      openNotification({
        message: `${formatFullName(
          customer.firstName,
          customer?.lastName
        )} was updated`,
        severity: 'success',
      });
    },
    onError: err => {
      openNotification({
        message:
          err.data?.axiosError?.response?.data?.detail ??
          'Failed to update customer',
        severity: 'error',
      });
    },
  });

  return updateCustomerMutation;
};
