import {v4 as uuidv4} from 'uuid';

export const getRefundIdempKey = (paymentId: string): string | null =>
  localStorage.getItem(`refundIdempKey-${paymentId}`);

export const refreshRefundIdempKey = (paymentId: string): string => {
  const generatedIdempotencyKey = uuidv4();
  localStorage.setItem(`refundIdempKey-${paymentId}`, generatedIdempotencyKey);
  return generatedIdempotencyKey;
};

export const removeRefundIdempKey = (paymentId: string): void =>
  localStorage.removeItem(`refundIdempKey-${paymentId}`);
