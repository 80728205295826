import {Country} from '@handsin/api-node';
import type {FC} from 'react';
import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useCreateCustomerMutation} from '@local/frontend/libs/api/customers';
import {useNotification} from '@local/frontend/hooks/useNotification';
import {useCustomModals} from '@local/frontend/libs/modals/useModals';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import type {CustomerCreationParams} from '@local/backend/@types/updated-api-types/customers/CustomerCreationParams';
import {useInviteToGroupPayment} from '@local/frontend/libs/trpc/trpc';
import AddCustomerForm from '../../forms/AddCustomerForm';
import {addNewCustomerValidationSchema} from '../../forms/schemas';
import {emailNotRequiredValidation} from '../../forms/schemas/custom-validation';

export interface AddGroupPaymentCustomerModalProps {
  groupPayment: GroupPaymentRecord;
}

const groupPaymentCustomerSchema = addNewCustomerValidationSchema.shape({
  email: emailNotRequiredValidation(),
});

const AddGroupPaymentCustomerModal: FC<
  React.PropsWithChildren<AddGroupPaymentCustomerModalProps>
> = ({groupPayment}) => {
  const {open: openNotification} = useNotification();
  const {closeModal} = useCustomModals();

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(groupPaymentCustomerSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      phoneNumberCountry: Country.GB,
    },
  });

  const inviteToGroupPaymentMutation = useInviteToGroupPayment();

  const createCustomerMutation = useCreateCustomerMutation({
    onSuccess: newCustomer => {
      inviteToGroupPaymentMutation.mutate(
        {
          merchantId: groupPayment.merchantId,
          groupPaymentId: groupPayment.id,
          ownerId: groupPayment.ownerId,
          customerIds: [newCustomer.id],
        },
        {
          onSuccess: () => {
            openNotification({
              message: `Successfully added ${newCustomer.firstName} to the group`,
              severity: 'success',
            });
            closeModal(ModalName.ADD_GROUP_PAYMENT_CUSTOMER);
          },
          onError: err => {
            openNotification({
              message:
                err.data?.axiosError?.response?.data.detail ??
                'Failed to create customer',
              severity: 'error',
            });
          },
        }
      );
    },
    onError: err => {
      openNotification({
        message:
          err.data?.axiosError?.response?.data.detail ??
          'Failed to create customer',
        severity: 'error',
      });
    },
  });

  // handle form submission
  const onSubmit = (customerCreationParams: CustomerCreationParams): void => {
    createCustomerMutation.mutate({
      ...customerCreationParams,
      email: customerCreationParams.email
        ? customerCreationParams.email
        : undefined,
    });
  };

  // get total number of members in the group
  const totalNumberOfMembersInGroup = groupPayment.invited
    ? groupPayment.memberIds.concat(groupPayment.invited).length
    : groupPayment.memberIds.length;

  const isAddCustomerButtonDisabled =
    totalNumberOfMembersInGroup === groupPayment.splitAllocation;

  const loading =
    createCustomerMutation.isLoading || inviteToGroupPaymentMutation.isLoading;

  return (
    <FormProvider {...methods}>
      {/* @TODO: move form and mutations inside add customer form - so it's re-usable */}
      <form
        onSubmit={methods.handleSubmit(values => {
          const {phoneNumberCountry, ...submitValues} = values;
          onSubmit(submitValues);
        })}
        style={{width: '100%'}}
      >
        <AddCustomerForm
          loading={loading}
          disabled={isAddCustomerButtonDisabled || loading}
        />
      </form>
    </FormProvider>
  );
};

export default AddGroupPaymentCustomerModal;
