import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';

const OnboardingDetailsPage = lazy(
  () => import('./details/OnboardingDetailsPage')
);
const OnboardingPlanPage = lazy(() => import('./plan/OnboardingPlanPage'));
const OnboardingConfirmationPage = lazy(
  () => import('./confirm/OnboardingConfirmationPage')
);

export const onboardingRoutes = {
  path: 'onboarding',
  children: [
    {index: true, element: <Navigate to="details" replace />},
    {
      path: 'details',
      element: <OnboardingDetailsPage />,
    },
    {
      path: 'plan',
      element: <OnboardingPlanPage />,
    },
    {
      path: 'confirm',
      element: <OnboardingConfirmationPage />,
    },
  ],
};
