import {Outlet} from 'react-router-dom';
import React, {lazy} from 'react';
import IfHasPermissions from '@local/frontend/hocs/IfHasPermissions';
import {GroupPaymentsScope} from '@local/backend/@types/scopes/GroupPaymentsScopes';

const ManageGroupPaymentPage = lazy(
  () => import('./manage/ManageGroupPaymentPage')
);

const CreateGroupPaymentLinkPage = lazy(
  () => import('./create/CreateGroupPaymentLinkPage')
);

const CreateEqualFlowPage = lazy(() => import('./create/CreateEqualFlowPage'));

const CreateFixedFlowPage = lazy(() => import('./create/CreateFixedFlowPage'));

const ConfirmGroupPayment = lazy(
  () => import('./components/confirm/ConfirmGroupPayment')
);

const ListGroupPayments = lazy(() => import('./view/ListGroupPayments'));

const GroupPaymentValidationWrapper = lazy(
  () => import('../../../providers/GroupPaymentValidationWrapper')
);

export const groupPaymentsRoutes = {
  path: 'group-payments',
  element: <Outlet />,
  children: [
    {
      index: true,
      element: (
        <IfHasPermissions
          thenRender={() => <ListGroupPayments />}
          permissions={[GroupPaymentsScope.READ]}
        />
      ),
    },
    {
      path: 'create',
      children: [
        {
          index: true,
          element: (
            <IfHasPermissions
              thenRender={() => <CreateGroupPaymentLinkPage />}
              permissions={[GroupPaymentsScope.CREATE]}
            />
          ),
        },
        {
          path: 'equal',
          element: (
            <IfHasPermissions
              thenRender={() => <CreateEqualFlowPage />}
              permissions={[GroupPaymentsScope.CREATE]}
            />
          ),
        },
        {
          path: 'fixed',
          element: (
            <IfHasPermissions
              thenRender={() => <CreateFixedFlowPage />}
              permissions={[GroupPaymentsScope.CREATE]}
            />
          ),
        },
      ],
    },
    {
      path: ':groupPaymentId',
      element: (
        <GroupPaymentValidationWrapper>
          <Outlet />
        </GroupPaymentValidationWrapper>
      ),
      children: [
        {
          index: true,
          element: (
            <IfHasPermissions
              thenRender={() => <ManageGroupPaymentPage />}
              permissions={[GroupPaymentsScope.READ]}
            />
          ),
        },
        {
          path: 'confirm',
          element: (
            <IfHasPermissions
              thenRender={() => <ConfirmGroupPayment />}
              permissions={[GroupPaymentsScope.CREATE]}
            />
          ),
        },
      ],
    },
  ],
};
