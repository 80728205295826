import {useCreateCheckout, useMerchant} from '@local/frontend/libs/trpc/trpc';
import {useQueryClient} from '@tanstack/react-query';
import type {FC} from 'react';
import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {useNotification} from '@local/frontend/hooks/useNotification';
import Backdrop from '@mui/material/Backdrop';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {LocalMoneyCalculator, MoneyUtils} from '@handsin/money';
import type {GridColDef, GridRowsProp} from '@mui/x-data-grid';
import {DataGrid} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import type {LineItem} from '@local/backend/@types/updated-api-types/payments/LineItem';
import {useCustomModals} from '@local/frontend/libs/modals/useModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import type {CreateCheckoutFormValues} from '../../forms/schemas';
import ImagePreview from '../../atoms/ImagePreview';

interface CheckoutInfoProps {
  title: string;
  value: string;
}

const CheckoutInfo: FC<React.PropsWithChildren<CheckoutInfoProps>> = ({
  title,
  value,
}) => (
  <Stack>
    <Typography variant="subtitle1" color="primary">
      {title}:
    </Typography>
    <Typography variant="subtitle1">{value}</Typography>
  </Stack>
);

const ReviewCheckoutModal: FC<React.PropsWithChildren<unknown>> = () => {
  const {data: merchant} = useMerchant();
  const queryClient = useQueryClient();
  const {closeModal} = useCustomModals();
  const navigate = useNavigate();
  const formMethods = useForm();
  const {open: openNotification} = useNotification();

  const createCheckoutMutation = useCreateCheckout();

  const checkoutCreationParams =
    queryClient.getQueryData<CreateCheckoutFormValues>([
      'checkout-creation-data',
    ]);

  const generateLineItemRows = (lineItems: LineItem[]): GridRowsProp =>
    lineItems.map((lineItem, index) => ({
      id: index,
      imageUrl: lineItem.item.imageUrls?.at(0),
      name: lineItem.item.name,
      description: lineItem.item.description,
      price: MoneyUtils.formatMoney(lineItem.totalMoney),
      extraProductInformation: Object.entries(
        lineItem.item.attributes ?? {}
      ).map(([key, value]) => `${key} - ${value}`),
    }));

  const lineItemColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'imageUrl',
        headerName: 'Image',
        width: 150,
        renderCell: ({row}) => (
          <div style={{width: '100%', height: '100%'}}>
            <ImagePreview
              src={row.imageUrl ?? merchant?.logoUrl}
              alt={row.name}
              style={{
                width: '100%',
                height: '100%',
                padding: '10px 0px',
                objectFit: 'contain',
              }}
            />
          </div>
        ),
      },
      {field: 'name', headerName: 'Name', width: 150},
      {field: 'description', headerName: 'Description', width: 250},
      {field: 'price', headerName: 'Price', width: 150},
      {
        field: 'extraProductInformation',
        headerName: 'Extra Info',
        width: 300,
        renderCell: params => (
          <p style={{whiteSpace: 'pre'}}>{params.value?.join('\n')}</p>
        ),
      },
    ],
    []
  );

  if (!checkoutCreationParams) {
    return <p>oh no</p>;
  }

  const totalMoney = checkoutCreationParams.lineItems
    .reduce(
      (total, lineItem) => total.add(lineItem.totalMoney),
      new LocalMoneyCalculator({
        amount: 0,
        currency: checkoutCreationParams.currency,
      })
    )
    .calculate();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Summary" />
            <CardContent>
              <Stack spacing={1}>
                <CheckoutInfo
                  title="You are collecting"
                  value={MoneyUtils.formatMoney(totalMoney)}
                />
                {checkoutCreationParams.expirationDate && (
                  <CheckoutInfo
                    title="The checkout will expire on"
                    value={dayjs(checkoutCreationParams.expirationDate).format(
                      'LLLL'
                    )}
                  />
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="Products" />
            <CardContent>
              <DataGrid
                rows={generateLineItemRows(checkoutCreationParams.lineItems)}
                columns={lineItemColumns}
                autoHeight
                rowHeight={85}
                disableColumnMenu
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid container item xs={12} justifyContent="center">
          <form
            onSubmit={formMethods.handleSubmit(() => {
              createCheckoutMutation.mutate(
                {
                  lineItemParams: checkoutCreationParams.lineItems.map(
                    lineitem => ({
                      item: lineitem.item.id,
                      quantity: lineitem.quantity,
                    })
                  ),
                  expirationDate:
                    checkoutCreationParams.expirationDate?.toISOString(),
                },
                {
                  onSuccess: checkout => {
                    // close review modal
                    closeModal(ModalName.REVIEW_CHECKOUT);
                    // // lastly, navigate the user to the confirmation page
                    navigate(`./payment-links/${checkout.id}`);
                  },
                  onError: err => {
                    openNotification({
                      severity: 'error',
                      message:
                        err.data?.axiosError?.response?.data ??
                        'Could not create checkout at this time. Please try again, if the problem persists contact support',
                    });
                  },
                }
              );
            })}
          >
            <Button
              variant="contained"
              type="submit"
              fullWidth
              disabled={formMethods.formState.isSubmitting}
            >
              Confirm & Create Payment
            </Button>
          </form>
        </Grid>
      </Grid>

      {/* @TODO: refactor into component */}
      <Backdrop
        sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}}
        open={
          formMethods.formState.isSubmitting && createCheckoutMutation.isLoading
        }
      >
        <Stack spacing={2} justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" />
          <Typography variant="h4">Creating checkout...</Typography>
        </Stack>
      </Backdrop>
    </>
  );
};

export default ReviewCheckoutModal;
