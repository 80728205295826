import type {Customer} from '@local/backend/@types/updated-api-types/customers/customer';
import type {FC} from 'react';
import React from 'react';
import Typography from '@mui/material/Typography';
import {DEFAULT_CREATED_CUSTOMER_EMAIL} from '@local/frontend/options';
import {formatFullName} from '@local/frontend/utils/formatters';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CustomerAvatar from './CustomerAvatar';

interface CustomerCardContentProps {
  customer: Customer;
}

const CustomerCardContent: FC<
  React.PropsWithChildren<CustomerCardContentProps>
> = ({customer}) => (
  <Card
    variant="outlined"
    sx={{
      borderRadius: 1,
      p: 1,
      minHeight: '100px',
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <Stack direction="row" spacing={2}>
      <CustomerAvatar customer={customer} />
      <Stack>
        <Typography variant="subtitle1" sx={{wordBreak: 'break-word'}}>
          {formatFullName(customer.firstName, customer.lastName)}
        </Typography>
        {customer.email !== DEFAULT_CREATED_CUSTOMER_EMAIL && (
          <Typography variant="caption" sx={{wordBreak: 'break-word'}}>
            {customer.email}
          </Typography>
        )}
      </Stack>
    </Stack>
  </Card>
);

export default CustomerCardContent;
