import type {FC} from 'react';
import React from 'react';
import type {StackProps} from '@mui/material';
import {Stack, Tooltip} from '@mui/material';
import Typography from '@mui/material/Typography';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import WarningIcon from '@mui/icons-material/Warning';
import {Env} from '../../@types/env';
import {useEnvironmentSession} from '../../libs/trpc/trpc';

interface SimpleEnvironmentBannerProps extends StackProps {
  title: string;
  tooltipInfo: string;
  tooltipIcon: React.ReactNode;
}

const SimpleEnvironmentBanner: FC<
  React.PropsWithChildren<SimpleEnvironmentBannerProps>
> = ({tooltipInfo, tooltipIcon, title, ...props}) => (
  <Tooltip title={tooltipInfo} arrow>
    <Stack
      {...props}
      direction="row"
      alignItems="center"
      justifyContent="center"
    >
      <Typography fontSize="1rem" marginRight={1} fontWeight={550}>
        {title}
      </Typography>
      {tooltipIcon}
    </Stack>
  </Tooltip>
);

const EnvironmentBanner: FC<React.PropsWithChildren<unknown>> = () => {
  const {data: env, isInitialLoading: isEnvironmentLoading} =
    useEnvironmentSession();

  if (isEnvironmentLoading) {
    return null;
  }

  switch (env) {
    case Env.PRODUCTION:
      return null;
    case Env.SANDBOX:
      return (
        <SimpleEnvironmentBanner
          title="Sandbox Mode"
          tooltipInfo="In sandbox mode you can test our platform without making any real charges"
          tooltipIcon={<HelpOutlineIcon fontSize="inherit" />}
          bgcolor="#FADB6F"
        />
      );
    case Env.DEVELOPMENT:
    default:
      return (
        <SimpleEnvironmentBanner
          title="Developer Mode"
          tooltipInfo="This site is only used for development purposes and beta testing!"
          tooltipIcon={<WarningIcon fontSize="inherit" />}
          bgcolor="#f4b8b2"
        />
      );
  }
};

export default EnvironmentBanner;
