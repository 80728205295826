import {Currency} from '@handsin/money';

export interface CurrencyOption {
  code: Currency;
  name: string;
}

const options: CurrencyOption[] = [
  {
    code: Currency.AED,
    name: 'United Arab Emirates dirham',
  },
  {
    code: Currency.AFN,
    name: 'Afghan afghani',
  },
  {
    code: Currency.ALL,
    name: 'Albanian lek',
  },
  {
    code: Currency.AMD,
    name: 'Armenian dram',
  },
  {
    code: Currency.ANG,
    name: 'Netherlands Antillean guilder',
  },
  {
    code: Currency.AOA,
    name: 'Angolan kwanza',
  },
  {
    code: Currency.ARS,
    name: 'Argentine peso',
  },
  {
    code: Currency.AUD,
    name: 'Australian dollar',
  },
  {
    code: Currency.AWG,
    name: 'Aruban florin',
  },
  {
    code: Currency.AZN,
    name: 'Azerbaijani manat',
  },
  {
    code: Currency.BAM,
    name: 'Bosnia and Herzegovina convertible mark',
  },
  {
    code: Currency.BBD,
    name: 'Barbados dollar',
  },
  {
    code: Currency.BDT,
    name: 'Bangladeshi taka',
  },
  {
    code: Currency.BGN,
    name: 'Bulgarian lev',
  },
  {
    code: Currency.BHD,
    name: 'Bahraini dinar',
  },
  {
    code: Currency.BIF,
    name: 'Burundian franc',
  },
  {
    code: Currency.BMD,
    name: 'Bermudian dollar (customarily known as Bermuda dollar)',
  },
  {
    code: Currency.BND,
    name: 'Brunei dollar',
  },
  {
    code: Currency.BOB,
    name: 'Boliviano',
  },
  {
    code: Currency.BRL,
    name: 'Brazilian real',
  },
  {
    code: Currency.BSD,
    name: 'Bahamian dollar',
  },
  {
    code: Currency.BTN,
    name: 'Bhutanese ngultrum',
  },
  {
    code: Currency.BWP,
    name: 'Botswana pula',
  },
  {
    code: Currency.BYN,
    name: 'Belarusian ruble',
  },
  {
    code: Currency.BZD,
    name: 'Belize dollar',
  },
  {
    code: Currency.CAD,
    name: 'Canadian dollar',
  },
  {
    code: Currency.CDF,
    name: 'Congolese franc',
  },
  {
    code: Currency.CHF,
    name: 'Swiss franc',
  },
  {
    code: Currency.CLP,
    name: 'Chilean peso',
  },
  {
    code: Currency.CNY,
    name: 'Chinese yuan',
  },
  {
    code: Currency.COP,
    name: 'Colombian peso',
  },
  {
    code: Currency.CRC,
    name: 'Costa Rican colon',
  },
  {
    code: Currency.CUC,
    name: 'Cuban convertible peso',
  },
  {
    code: Currency.CUP,
    name: 'Cuban peso',
  },
  {
    code: Currency.CVE,
    name: 'Cape Verde escudo',
  },
  {
    code: Currency.CZK,
    name: 'Czech koruna',
  },
  {
    code: Currency.DJF,
    name: 'Djiboutian franc',
  },
  {
    code: Currency.DKK,
    name: 'Danish krone',
  },
  {
    code: Currency.DOP,
    name: 'Dominican peso',
  },
  {
    code: Currency.DZD,
    name: 'Algerian dinar',
  },
  {
    code: Currency.EGP,
    name: 'Egyptian pound',
  },
  {
    code: Currency.ERN,
    name: 'Eritrean nakfa',
  },
  {
    code: Currency.ETB,
    name: 'Ethiopian birr',
  },
  {
    code: Currency.EUR,
    name: 'Euro',
  },
  {
    code: Currency.FJD,
    name: 'Fiji dollar',
  },
  {
    code: Currency.FKP,
    name: 'Falkland Islands pound',
  },
  {
    code: Currency.GBP,
    name: 'Pound sterling',
  },
  {
    code: Currency.GEL,
    name: 'Georgian lari',
  },
  {
    code: Currency.GHS,
    name: 'Ghanaian cedi',
  },
  {
    code: Currency.GIP,
    name: 'Gibraltar pound',
  },
  {
    code: Currency.GMD,
    name: 'Gambian dalasi',
  },
  {
    code: Currency.GNF,
    name: 'Guinean franc',
  },
  {
    code: Currency.GTQ,
    name: 'Guatemalan quetzal',
  },
  {
    code: Currency.GYD,
    name: 'Guyanese dollar',
  },
  {
    code: Currency.HKD,
    name: 'Hong Kong dollar',
  },
  {
    code: Currency.HNL,
    name: 'Honduran lempira',
  },
  {
    code: Currency.HRK,
    name: 'Croatian kuna',
  },
  {
    code: Currency.HTG,
    name: 'Haitian gourde',
  },
  {
    code: Currency.HUF,
    name: 'Hungarian forint',
  },
  {
    code: Currency.IDR,
    name: 'Indonesian rupiah',
  },
  {
    code: Currency.ILS,
    name: 'Israeli new shekel',
  },
  {
    code: Currency.INR,
    name: 'Indian rupee',
  },
  {
    code: Currency.IQD,
    name: 'Iraqi dinar',
  },
  {
    code: Currency.IRR,
    name: 'Iranian rial',
  },
  {
    code: Currency.ISK,
    name: 'Icelandic króna',
  },
  {
    code: Currency.JMD,
    name: 'Jamaican dollar',
  },
  {
    code: Currency.JOD,
    name: 'Jordanian dinar',
  },
  {
    code: Currency.JPY,
    name: 'Japanese yen',
  },
  {
    code: Currency.KES,
    name: 'Kenyan shilling',
  },
  {
    code: Currency.KGS,
    name: 'Kyrgyzstani som',
  },
  {
    code: Currency.KHR,
    name: 'Cambodian riel',
  },
  {
    code: Currency.KMF,
    name: 'Comoro franc',
  },
  {
    code: Currency.KPW,
    name: 'North Korean won',
  },
  {
    code: Currency.KRW,
    name: 'South Korean won',
  },
  {
    code: Currency.KWD,
    name: 'Kuwaiti dinar',
  },
  {
    code: Currency.KYD,
    name: 'Cayman Islands dollar',
  },
  {
    code: Currency.KZT,
    name: 'Kazakhstani tenge',
  },
  {
    code: Currency.LAK,
    name: 'Lao kip',
  },
  {
    code: Currency.LBP,
    name: 'Lebanese pound',
  },
  {
    code: Currency.LKR,
    name: 'Sri Lankan rupee',
  },
  {
    code: Currency.LRD,
    name: 'Liberian dollar',
  },
  {
    code: Currency.LSL,
    name: 'Lesotho loti',
  },
  {
    code: Currency.LYD,
    name: 'Libyan dinar',
  },
  {
    code: Currency.MAD,
    name: 'Moroccan dirham',
  },
  {
    code: Currency.MDL,
    name: 'Moldovan leu',
  },
  {
    code: Currency.MGA,
    name: 'Malagasy ariary',
  },
  {
    code: Currency.MKD,
    name: 'Macedonian denar',
  },
  {
    code: Currency.MMK,
    name: 'Myanma kyat',
  },
  {
    code: Currency.MNT,
    name: 'Mongolian tugrik',
  },
  {
    code: Currency.MOP,
    name: 'Macanese pataca',
  },
  {
    code: Currency.MRU,
    name: 'Mauritanian ouguiya',
  },
  {
    code: Currency.MUR,
    name: 'Mauritian rupee',
  },
  {
    code: Currency.MVR,
    name: 'Maldivian rufiyaa',
  },
  {
    code: Currency.MWK,
    name: 'Malawian kwacha',
  },
  {
    code: Currency.MXN,
    name: 'Mexican peso',
  },
  {
    code: Currency.MYR,
    name: 'Malaysian ringgit',
  },
  {
    code: Currency.MZN,
    name: 'Mozambican metical',
  },
  {
    code: Currency.NAD,
    name: 'Namibian dollar',
  },
  {
    code: Currency.NGN,
    name: 'Nigerian naira',
  },
  {
    code: Currency.NIO,
    name: 'Nicaraguan córdoba',
  },
  {
    code: Currency.NOK,
    name: 'Norwegian krone',
  },
  {
    code: Currency.NPR,
    name: 'Nepalese rupee',
  },
  {
    code: Currency.NZD,
    name: 'New Zealand dollar',
  },
  {
    code: Currency.OMR,
    name: 'Omani rial',
  },
  {
    code: Currency.PAB,
    name: 'Panamanian balboa',
  },
  {
    code: Currency.PEN,
    name: 'Peruvian nuevo sol',
  },
  {
    code: Currency.PGK,
    name: 'Papua New Guinean kina',
  },
  {
    code: Currency.PHP,
    name: 'Philippine peso',
  },
  {
    code: Currency.PKR,
    name: 'Pakistani rupee',
  },
  {
    code: Currency.PLN,
    name: 'Polish złoty',
  },
  {
    code: Currency.PYG,
    name: 'Paraguayan guaraní',
  },
  {
    code: Currency.QAR,
    name: 'Qatari riyal',
  },
  {
    code: Currency.RON,
    name: 'Romanian new leu',
  },
  {
    code: Currency.RSD,
    name: 'Serbian dinar',
  },
  {
    code: Currency.RUB,
    name: 'Russian rouble',
  },
  {
    code: Currency.RWF,
    name: 'Rwandan franc',
  },
  {
    code: Currency.SAR,
    name: 'Saudi riyal',
  },
  {
    code: Currency.SBD,
    name: 'Solomon Islands dollar',
  },
  {
    code: Currency.SCR,
    name: 'Seychelles rupee',
  },
  {
    code: Currency.SDG,
    name: 'Sudanese pound',
  },
  {
    code: Currency.SEK,
    name: 'Swedish krona/kronor',
  },
  {
    code: Currency.SGD,
    name: 'Singapore dollar',
  },
  {
    code: Currency.SHP,
    name: 'Saint Helena pound',
  },
  {
    code: Currency.SLL,
    name: 'Sierra Leonean leone',
  },
  {
    code: Currency.SOS,
    name: 'Somali shilling',
  },
  {
    code: Currency.SRD,
    name: 'Surinamese dollar',
  },
  {
    code: Currency.SSP,
    name: 'South Sudanese pound',
  },
  {
    code: Currency.STN,
    name: 'São Tomé and Príncipe dobra',
  },
  {
    code: Currency.SYP,
    name: 'Syrian pound',
  },
  {
    code: Currency.SZL,
    name: 'Swazi lilangeni',
  },
  {
    code: Currency.THB,
    name: 'Thai baht',
  },
  {
    code: Currency.TJS,
    name: 'Tajikistani somoni',
  },
  {
    code: Currency.TMT,
    name: 'Turkmenistani manat',
  },
  {
    code: Currency.TND,
    name: 'Tunisian dinar',
  },
  {
    code: Currency.TOP,
    name: 'Tongan paʻanga',
  },
  {
    code: Currency.TRY,
    name: 'Turkish lira',
  },
  {
    code: Currency.TTD,
    name: 'Trinidad and Tobago dollar',
  },
  {
    code: Currency.TWD,
    name: 'New Taiwan dollar',
  },
  {
    code: Currency.TZS,
    name: 'Tanzanian shilling',
  },
  {
    code: Currency.UAH,
    name: 'Ukrainian hryvnia',
  },
  {
    code: Currency.UGX,
    name: 'Ugandan shilling',
  },
  {
    code: Currency.USD,
    name: 'United States dollar',
  },
  {
    code: Currency.UYU,
    name: 'Uruguayan peso',
  },
  {
    code: Currency.UZS,
    name: 'Uzbekistan som',
  },
  {
    code: Currency.VND,
    name: 'Vietnamese dong',
  },
  {
    code: Currency.VUV,
    name: 'Vanuatu vatu',
  },
  {
    code: Currency.WST,
    name: 'Samoan tala',
  },
  {
    code: Currency.XAF,
    name: 'CFA franc BEAC',
  },
  {
    code: Currency.XCD,
    name: 'East Caribbean dollar',
  },
  {
    code: Currency.XOF,
    name: 'CFA franc BCEAO',
  },
  {
    code: Currency.XPF,
    name: 'CFP franc',
  },
  {
    code: Currency.YER,
    name: 'Yemeni rial',
  },
  {
    code: Currency.ZAR,
    name: 'South African rand',
  },
  {
    code: Currency.ZMW,
    name: 'Zambian kwacha',
  },
];

export default options;
