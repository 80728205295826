import type {FC} from 'react';
import React from 'react';
import type {ButtonProps} from '@mui/material';
import {Button, Skeleton, Stack, Typography, styled} from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import type {MerchantDTO} from '@local/backend/@types/updated-api-types/merchants/Merchant';
import {MerchantAvatar} from './MerchantAvatar';

interface MerchantAvatarProps extends ButtonProps {
  merchant: MerchantDTO;
}

const StyledButton = styled(Button)(({theme}) => ({
  boxShadow: theme.shadows[4],
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.getContrastText(theme.palette.primary.dark),
  width: 220,
  padding: 6,
  '&:hover': {
    boxShadow: theme.shadows[4],
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
}));

export const MerchantSelectButton: FC<
  React.PropsWithChildren<MerchantAvatarProps>
> = ({merchant, ...props}) => (
  <StyledButton {...props} variant="text" disableRipple>
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      width="100%"
    >
      <MerchantAvatar merchant={merchant} />
      <Typography
        fontSize={16}
        fontWeight={500}
        textTransform="none"
        noWrap
        width="100%"
      >
        {merchant?.name}
      </Typography>
      <UnfoldMoreIcon fontSize="small" />
    </Stack>
  </StyledButton>
);

export const MerchantSelectButtonLoading = () => (
  <StyledButton variant="text" disableRipple>
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      width="100%"
    >
      <Skeleton
        variant="rounded"
        width={40}
        height={40}
        sx={{aspectRatio: '1/1', bgcolor: 'grey.200'}}
      />
      <Skeleton
        variant="text"
        sx={{fontSize: 16, bgcolor: 'grey.200'}}
        width="100%"
      />
      <UnfoldMoreIcon fontSize="small" />
    </Stack>
  </StyledButton>
);
