import type {FC} from 'react';
import React from 'react';
import {Box} from '@mui/material';
import EnvironmentBanner from '../../components/banners/EnvironmentBanner';

const HeaderContent: FC<React.PropsWithChildren<unknown>> = () => (
  <Box width="100%">
    <EnvironmentBanner />
  </Box>
);

export default HeaderContent;
