import type {FC} from 'react';
import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const NotFoundPage: FC<React.PropsWithChildren<unknown>> = () => (
  <Grid
    container
    alignItems="center"
    justifyContent="center"
    style={{minHeight: '100vh'}}
  >
    <Grid item>
      <Typography textAlign="center" variant="h1">
        404 NOT FOUND
      </Typography>
    </Grid>
  </Grid>
);

export default NotFoundPage;
