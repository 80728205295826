import {DeveloperScope} from '@local/backend/@types/scopes/DeveloperScopes';
import IfHasPermissions from '@local/frontend/hocs/IfHasPermissions';
import React, {lazy} from 'react';
import type {RouteObject} from 'react-router-dom';
import {Navigate, Outlet} from 'react-router-dom';

const WebhookManagePage = lazy(() => import('./webhooks/WebhookManagePage'));
const WebhooksPage = lazy(() => import('./webhooks/WebhooksPage'));
const EventsManagePage = lazy(() => import('./events/EventsManagePage'));
const EventsPage = lazy(() => import('./events/EventsPage'));
const ApiKeysPage = lazy(() => import('./keys/ApiKeysPage'));

export const developerRoutes: RouteObject = {
  path: 'developers',
  children: [
    {
      index: true,
      element: <Navigate to="keys" replace />,
    },
    {
      path: 'events',
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <EventsPage />,
        },
        {
          path: ':eventId',
          element: (
            <IfHasPermissions
              thenRender={() => <EventsManagePage />}
              permissions={[DeveloperScope.ALL]}
            />
          ),
        },
      ],
    },
    {
      path: 'webhooks',
      element: <Outlet />,
      children: [
        {
          index: true,
          element: (
            <IfHasPermissions
              thenRender={() => <WebhooksPage />}
              permissions={[DeveloperScope.ALL]}
            />
          ),
        },
        {
          path: ':webhookEndpointId',
          element: (
            <IfHasPermissions
              thenRender={() => <WebhookManagePage />}
              permissions={[DeveloperScope.ALL]}
            />
          ),
        },
      ],
    },
    {
      path: 'keys',
      element: (
        <IfHasPermissions
          thenRender={() => <ApiKeysPage />}
          permissions={[DeveloperScope.ALL]}
        />
      ),
    },
  ],
};
