import {MAX_FILE_SIZE_BYTES} from '@local/frontend/options';
import * as Yup from 'yup';

export const imageFileSchema = () =>
  Yup.mixed<File | string>()
    .nullable()
    .test({
      test(data) {
        if (data instanceof File) {
          if (!data.type.startsWith('image/')) {
            return this.createError({
              path: this.path,
              message: 'Invalid file type. Only images are accepted',
            });
          }
          if (data.size > MAX_FILE_SIZE_BYTES) {
            return this.createError({
              path: this.path,
              message: 'File is too large. Max size 15MB',
            });
          }
        }
        return true;
      },
    });
